import React, { Component } from 'react';
import '../asset/css/template/login.css';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

export class LogIn extends Component {
    state = {
        passcode: localStorage.getItem('passcode') ? localStorage.getItem('passcode') : '',
        errorMsg: '',
        data: this.props.data,
        dataList: this.props.dataList,
        passcode: '',
    };

    submitIsClicked = async (schoolInfo) => {
        const { dataList, passcode } = this.state;
        const studentInfo = dataList.passcodeList.find((data) => data.PASSCODE === passcode);

        if (studentInfo) {
            const schoolGradeLevelInfo = await dataList.gradeList.find((item) => {
                return (
                    parseInt(item.SCHOOLID) === schoolInfo.id &&
                    item.GRADELABEL === studentInfo.GRADE &&
                    studentInfo.SCHOOL == schoolInfo.schoolCode
                );
            });

            if (!schoolGradeLevelInfo) {
                return this.setState({ errorMsg: 'Something went wrong. Please contact system administrator.' });
            }

            localStorage.setItem('name', studentInfo.NAME);
            localStorage.setItem('grade', studentInfo.GRADE);
            localStorage.setItem('gradeId', schoolGradeLevelInfo.GRADEID);
            localStorage.setItem('school', studentInfo.SCHOOL);
            localStorage.setItem('isLogin', true);
            localStorage.setItem('passcode', studentInfo.PASSCODE);
            localStorage.setItem('accountType', studentInfo.account_type);

            this.props.history.push(`/module/${studentInfo.SCHOOL}`);
        }

        this.setState({ errorMsg: 'Error: Please enter correct passcode given to you by your school.' });
    };

    render() {
        const { data, errorMsg, passcode } = this.state;
        return (
            <div className="log-in-container">
                <Container fluid>
                    <Row>
                        <Col md={3}>
                            <img src={data.schoolLogo} alt={data.schoolCode} className="log-in-school-logo" />
                        </Col>
                        <Col className="log-in-data-container">
                            <div className="log-in-data-wrapper">
                                <span className="log-in-code-text">Please enter your passcode:</span>
                                <input
                                    type="password"
                                    className="log-in-passcode"
                                    value={passcode}
                                    onChange={(e) => {
                                        this.setState({ errorMsg: '' });
                                        const passcode = e.target.value;
                                        this.setState({ passcode });
                                    }}
                                />
                                <span
                                    className="submit-button"
                                    onClick={() => {
                                        this.submitIsClicked(data);
                                    }}
                                >
                                    SUBMIT
                                </span>

                                {errorMsg && (
                                    <div style={{ color: '#b30300', fontStyle: 'italic', fontSize: 13 }}>
                                        {errorMsg}
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(LogIn);
