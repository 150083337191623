import React from "react";
import "../asset/css/component/logoutbutton.css";
import { useHistory } from "react-router-dom";

import LogOut from "../asset/images/logout.png";
import { Button, Icon, IconButton } from "@material-ui/core";
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const setLocalStorage = async (history) => {
  await localStorage.removeItem("name");
  await localStorage.removeItem("grade");
  await localStorage.removeItem("school");
  await localStorage.removeItem("gradeId");
  await localStorage.removeItem("isLogin");

  history.push("/aded");
};

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

function LogOutButton({ setIsLogin }) {
  const history = useHistory();
  return (
    <div
      className="logout-button-wrapper"
      onClick={() => {
        setIsLogin();
        setLocalStorage(history);
      }}
    >
      <IconButton color="primary" aria-label="logout">
        <PowerSettingsNewIcon style={{ color: "#474747" }}/>
      </IconButton>
    </div>
  );
}

export default LogOutButton;
