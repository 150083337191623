import React, { Component, Fragment } from 'react';
import Tabletop from 'tabletop';
import '../asset/css/page/landing.css';
import { SCHOOL } from '../constant/schools';
import { Modal } from 'react-bootstrap';

import Header from '../template/ModuleHeader';
import LogIn from '../template/LogIn';
import { CircularProgress } from '@material-ui/core';
import { SCHOOLS } from '../constant/schoolsData';
export default class Landing extends Component {
    state = {
        schoolData: null,
        isModalOpen: false,
        dataList: { passcodeList: [], schoolList: [], gradeList: [] },
        isLoading: true,
    };

    isSchoolCardSelected = (data) => {
        this.setState({ schoolData: data });
        this.setState({ isModalOpen: true });
    };

    componentDidMount = async () => {
        localStorage.removeItem('name');
        localStorage.removeItem('grade');
        localStorage.removeItem('gradeId');
        localStorage.removeItem('school');
        localStorage.removeItem('isLogin');
        localStorage.removeItem('accountType');

        // let dataList = this.state.dataList;
        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1QqKGzZixkAlSL9ov6ztEVjGnOxZ8XEsqcR6aAba6G84/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (dataList.passcodeList = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1lfm28XgHo0LLQsI2w-jxouLR4IupDNZF5KJW1TcKnwU/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (dataList.schoolList = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1rl-9NCeRtT_oA2T5vEs_pl3FLqcVgqDes2F0UVkCnKk/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (dataList.gradeList = data))
        //     .catch((err) => console.warn(err));

        // this.setState({ dataList, isLoading: false });
        this.setState({ dataList: SCHOOLS, isLoading: false });
    };

    render() {
        return (
            <div>
                <Header />

                {this.state.isLoading ? (
                    <div className="module-loading-container">
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    <Fragment>
                        <div className="school-card-container">
                            {SCHOOL.map((data, index) => {
                                return (
                                    <div
                                        className="school-card-wrapper"
                                        key={index}
                                        onClick={() => this.isSchoolCardSelected(data)}
                                    >
                                        <img
                                            src={data.schoolLogo}
                                            className="school-card-school-logo"
                                            alt={data.schoolName}
                                        />
                                        <span className="school-card-school-code">{data.schoolCode}</span>
                                    </div>
                                );
                            })}
                        </div>

                        <Modal
                            show={this.state.isModalOpen}
                            onHide={() => this.setState({ isModalOpen: false })}
                            centered={true}
                            size="md"
                        >
                            <LogIn
                                data={this.state.schoolData}
                                dataList={this.state.dataList}
                                // isModalHide={() => this.setState({ isModalOpen: false })}
                                submitIsClicked={(passcode) => this.isSubmitClicked(passcode)}
                            />
                        </Modal>
                    </Fragment>
                )}
            </div>
        );
    }
}
