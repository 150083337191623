export const CHAPTERS = [
    {
        GRADEID: '1',
        CHAPTERID: '1',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Everything About Your Computer',
        CHAPTERLINK: 'https://drive.google.com/file/d/10mMX6eKje393xQxpncsrstun09xQForo/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '1',
        CHAPTERID: '2',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Your Computer Controls Through Pointers',
        CHAPTERLINK: 'https://drive.google.com/file/d/1FDojp95b10Iks5P5p2ANOxQQrLJo4XDm/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '1',
        CHAPTERID: '3',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Your Computer Controls Through  Keys',
        CHAPTERLINK: 'https://drive.google.com/file/d/1X5amhpIORGfQ2SeIAlbss4pJZS6-FSR4/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '1',
        CHAPTERID: '4',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Operating Your Computer',
        CHAPTERLINK: 'https://drive.google.com/file/d/1Q-DW_s0QhhNH9PgRc8vM8fJWHeqBSr0h/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '5',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Operating Your Computer with Pointers',
        CHAPTERLINK: 'https://drive.google.com/file/d/1rzVtEF0VwDGXPmq_8mnNLjy5t5Qfiowm/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '6',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Operating Your Computer with Keys',
        CHAPTERLINK: 'https://drive.google.com/file/d/1QN2GIUy4UmZyWgSw4rG66L3eEtophsdE/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '7',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Navigating Your Computer',
        CHAPTERLINK: 'https://drive.google.com/file/d/1IB5M-HwQ9P1LHuj3DEX9cOZ-VHKBmhHQ/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '8',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Application of your Knowledge in Computer Operations',
        CHAPTERLINK: 'https://drive.google.com/file/d/1jX7dLhjhOGN4x-Gu3iqUTxygbMaBOPwA/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '9',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Introduction to Computer Controls and Commands',
        CHAPTERLINK: 'https://drive.google.com/file/d/14B7AZ45CFB3eN_-ITnyLODAVJbV9C-O0/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '10',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Operating your Computer with Keys',
        CHAPTERLINK: 'https://drive.google.com/file/d/1clmeT_J46XuKfsScJNTpMKByGlPNhdQ1/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '11',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Application of Computer Operations  Via Software Usage',
        CHAPTERLINK: 'https://drive.google.com/file/d/1d4ieg5uLuvZDwR-4iqQrDrb5OM6f8YLA/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '12',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Introduction to Functional Software Tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1pKZ7woK_LUAXV_v4-ryfUlg-4niFEE8F/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '13',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Basic Tools for Learning and Documentation: Youtube Exploration and Font Styling',
        CHAPTERLINK: 'https://drive.google.com/file/d/1UeHuIOejmH9YPeQ8Bskb5l02_LJCy6O-/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '14',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Exploring Functions of Basic Documentation Tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/10urFj1eOzuUbZ5qNPf7YIFTGwWoWZYof/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '15',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Digital Functions of Basic  Documentation Tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1-Sva0MAom1FwBmLf2NGdu-DsHXmcFALe/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '16',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Introduction to Visuals via Computer Tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/15XWBjHhCQNr1bTeQjFdpZ-_alckBDImq/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '17',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Introduction to complex functions of documentations tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1w1bL3pFGj-syb-840jq90FN-mLgwnjXt/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '18',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Fundamental functions of documentation tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1qrdeNT4VQscu7hZ_kZnqdVycoVGSs193/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '19',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Digital functions of documentation tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1Umh-kjvK4mRHXicCCNbyhrYZJQ-_I7JO/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '20',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Technical introduction for computer visuals',
        CHAPTERLINK: 'https://drive.google.com/file/d/1Qyk0Xy4vt9pdYh7GTiZivp3ne-diZ6bB/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '21',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Basic Tools for Learning and Documentation: Youtube Exploration and Font Styling',
        CHAPTERLINK: 'https://drive.google.com/file/d/1oKD1rD5dzNyfo6Vx2PK7ozOb_8OnWAnS/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '22',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Mastery of presentation tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1DLZWpLkpGXgFqtPQ1HinRS0LkxAPDcQn/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '23',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Mastery of charts visualization',
        CHAPTERLINK: 'https://drive.google.com/file/d/10nPN_xB35wUGGwkT3DytX7D_9MrvN5Hm/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '24',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Introduction of multiple media collaborations',
        CHAPTERLINK: 'https://drive.google.com/file/d/1zMgtoQ6dP3dykcd5dqRczVUuYBRgzjja/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '25',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Introduction to digital design',
        CHAPTERLINK: 'https://drive.google.com/file/d/1tsei0XouFhcPTmRzOxmMLTwbZEdVVHPJ/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '26',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Applications of digital design',
        CHAPTERLINK: 'https://drive.google.com/file/d/1vXt06wDOZUQRPQfs_SmW3p7MJmFz-GJL/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '27',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Exploring audio manipulations',
        CHAPTERLINK: 'https://drive.google.com/file/d/1uyTp2A3WSe6aSqhATxMt7LazjFU_hjXX/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '28',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Development of logical thinking fundamentals',
        CHAPTERLINK: 'https://drive.google.com/file/d/1ziTrs8d1U7O2VhekZBagr_5BNbL4hqnx/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '29',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Mastering Image Editing',
        CHAPTERLINK: 'https://drive.google.com/file/d/17amtOduuZvbWeGXbEMXZMXXZnrHTlws-/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '30',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Advance Image Editing',
        CHAPTERLINK: 'https://drive.google.com/file/d/1tzSzUJOE1BavHH0fhUu4vwf7UeG_zd1T/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '31',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Introduction to Video Editing',
        CHAPTERLINK: 'https://drive.google.com/file/d/1dJInxfDNLbxjOwJK5auaBAwYhBHM-THh/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '32',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Full Movie Production',
        CHAPTERLINK: 'https://drive.google.com/file/d/1RoR2W-LARyZjdqtI91BnV_h4XqsBIV2k/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '33',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Understanding Digital Design',
        CHAPTERLINK: 'https://drive.google.com/file/d/1n4GGHL8gqBw4XauP5Gy-FTgPkRgrWlQ6/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '34',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Giving Life to your Design',
        CHAPTERLINK: 'https://drive.google.com/file/d/1en5MEL6fIzVBMR-v9V5BLmEDx6wQ4_pl/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '35',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Organizing Elememnts in a Canvas',
        CHAPTERLINK: 'https://drive.google.com/file/d/1nzw-3E3mOLSnoqV9Cn_vvCT-zxeb_1du/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '36',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'Photo Editing Tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1n-2j_TAeRh1q8BntYPN2ebIvIzV3WgYi/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '37',
        CHAPTERLABEL: 'Chapter 1',
        CHAPTERTITLE: 'Microsoft Visio and flowcharting',
        CHAPTERLINK: 'https://drive.google.com/file/d/1qmiyzISo2IbDK2TACUJmLk4rtj2pyAho/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '38',
        CHAPTERLABEL: 'Chapter 2',
        CHAPTERTITLE: 'Flowcharting to programming',
        CHAPTERLINK: 'https://drive.google.com/file/d/1NXKrkXlJuZwznAR9ms3MqNiQvoCRRCe6/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '39',
        CHAPTERLABEL: 'Chapter 3',
        CHAPTERTITLE: 'Programming startup',
        CHAPTERLINK: 'https://drive.google.com/file/d/1zknVzzvGuQXQ0mEcsRHv08E2QoAabNYN/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '40',
        CHAPTERLABEL: 'Chapter 4',
        CHAPTERTITLE: 'C++ and its tools',
        CHAPTERLINK: 'https://drive.google.com/file/d/1pA6hHrR6tSbWZullfkUlJBlWJNeTx02D/view?usp=sharing',
        module_title: '',
    },
    { GRADEID: '12', CHAPTERID: '41', CHAPTERLABEL: 'Unit 1', CHAPTERTITLE: '', CHAPTERLINK: '', module_title: '' },
    { GRADEID: '12', CHAPTERID: '42', CHAPTERLABEL: 'Unit 2', CHAPTERTITLE: '', CHAPTERLINK: '', module_title: '' },
    { GRADEID: '12', CHAPTERID: '43', CHAPTERLABEL: 'Chapter 1 - SketchUp', CHAPTERTITLE: '', CHAPTERLINK: 'https://drive.google.com/file/d/1c60q1AYQ8HR6As2sp1AxEl_VSNWSNDdF/view?usp=sharing', module_title: 'SketchUp' },
    { GRADEID: '12', CHAPTERID: '44', CHAPTERLABEL: 'Chapter 2 - SketchUp', CHAPTERTITLE: '', CHAPTERLINK: 'https://drive.google.com/file/d/1cBULgazxKbGQ1o_ioa-wpXemN8xiv0nH/view?usp=sharing', module_title: 'SketchUp' },
];
