import React, { Component } from 'react';
import Tabletop from 'tabletop';
import '../asset/css/page/module.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from '../template/ModuleHeader';
import Lesson from '../template/ModuleLesson';

import Button from '../component/Button';
import Title from '../component/Title';
import Text from '../component/Text';
import SoftwareLink from '../template/SoftwareLink';
import { Fragment } from 'react';

import { CHAPTERS } from '../constant/chapters';
import { DIGITAL_LINKS } from '../constant/digitalLinks';
import { LESSONS } from '../constant/lessons';
import { MODULES } from '../constant/modules';
import { SOFTWARES } from '../constant/softwares';
import { TOPICS } from '../constant/topics';
import { SCHOOLS } from '../constant/schoolsData';

const params = new URLSearchParams(window.location.search);

export default class Module extends Component {
    state = {
        studentInfo: {
            grade: localStorage.getItem('grade'),
            school: localStorage.getItem('school'),
        },
        chapterButtons: [],
        activeChapter: 0,
        moduleContent: {
            software: [],
            chapter: [],
            lesson: [],
            topic: [],
            digitalLink: [],
        },
        showDigitalLinksToStudentsFlag: false,
        contents: [],
        schoolInfo: {},
    };

    componentDidMount = () => {
        const chapterQuery = params.get('chapter');
        if (chapterQuery) {
            this.setState({ activeChapter: chapterQuery - 1 });
        }

        this.isUserLogin();
    };

    isUserLogin = () => {
        let isLoggedIn = localStorage.getItem('isLogin');

        if (isLoggedIn) {
            this.fetchData();
        } else {
            this.props.history.push('/aded');
        }
    };

    constructChapterButton = (contents) => {
        const { activeChapter } = this.state;
        let activeChapterIndex = 0;
        if (activeChapter < contents.length) {
            activeChapterIndex = activeChapter;
        }

        return contents.map((content, index) => {
            return (
                <a style={{ textDecoration: 'none' }} onClick={() => this.setState({ activeChapter: index })}>
                    <div className={`chapter-button ${index === activeChapter ? 'active' : ''}`}>
                        <span className="button-text">{content.CHAPTERLABEL}</span>
                    </div>
                </a>
            );
        });
    };

    fetchData = async () => {
        // let software = [];
        // let chapter = [];
        // let lesson = [];
        // let topic = [];
        // let digitalLink = [];
        // let modules = [];

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1lfm28XgHo0LLQsI2w-jxouLR4IupDNZF5KJW1TcKnwU/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => {
        //         const schoolData = data.find((school) => {
        //             return school.SCHOOLNAME.toLowerCase() == this.state.studentInfo.school.toLowerCase();
        //         });

        //         this.setState({ schoolInfo: schoolData });
        //     })
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1Rh9QAajz1yADEx3MrLjgV_qBxyYoB_Yv-Ca2QeI7IWs/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (software = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1hnXHlULLiIHKdJUEVvLTCeH-tG7TlxAnT9-nG2LQFhs/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (chapter = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1OXpW4sV_6VUp109jXilZovv6S3mNbqXlwWr-U1zQhUA/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (lesson = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1QyZ4_nbP9PpDRwVnkooUbigr9sQV4WmdwJ8pP-fcCjg/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (topic = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1tvTfmIFEWF_73rqrNvFI3EhFrvlGWPvBMe_G9Q--QDI/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (digitalLink = data))
        //     .catch((err) => console.warn(err));

        // await Tabletop.init({
        //     key: 'https://docs.google.com/spreadsheets/d/1Vh8mbuMcT9aTla9LTT1axeqztMKMhN_K4jz544TQD8k/edit#gid=0',
        //     simpleSheet: true,
        // })
        //     .then((data) => (modules = data))
        //     .catch((err) => console.warn(err));

        this.setState({ isLoading: true });

        this.constructData(SOFTWARES, CHAPTERS, LESSONS, TOPICS, DIGITAL_LINKS, MODULES);
    };

    constructData = (software, chapter, lesson, topic, digitalLink, modules) => {
        const { studentInfo } = this.state;
        const gradeId = studentInfo.grade;

        let userContents = [];

        chapter.forEach((chapterData) => {
            let contentData = {};
            if (chapterData.GRADEID == gradeId) {
                contentData = chapterData;
                modules.forEach((module) => {
                    if (module.GRADEID == chapterData.GRADEID && chapterData.CHAPTERID == module.CHAPTERID) {
                        if (!contentData.modules) {
                            contentData.modules = [];
                        }

                        contentData.modules.push(module);
                    }
                });

                if (software.length > 0) {
                    software.forEach((data) => {
                        if (chapterData.GRADEID == data.GRADEID) {
                            if (!contentData.softwares) {
                                contentData.softwares = [];
                            }

                            contentData.softwares.push(data);
                        }
                    });
                }

                if (lesson.length > 0) {
                    lesson.forEach((lessonData) => {
                        if (chapterData.CHAPTERID == lessonData.CHAPTERID) {
                            topic.forEach((topicData) => {
                                if (!lessonData.topics) {
                                    lessonData.topics = [];
                                }

                                if (lessonData.LESSONID == topicData.LESSONID) {
                                    digitalLink.forEach((data) => {
                                        if (data.TOPICID == topicData.TOPICID) {
                                            if (!topicData.digitalLinks) {
                                                topicData.digitalLinks = [];
                                            }
                                            topicData.digitalLinks.push(data);
                                        }
                                    });

                                    lessonData.topics.push(topicData);
                                }
                            });

                            if (!contentData.lessons) {
                                contentData.lessons = [];
                            }

                            contentData.lessons.push(lessonData);
                        }
                    });
                }
            }

            if (Object.keys(contentData).length > 0) {
                userContents.push(contentData);
            }
        });

        this.setState({ contents: userContents });
    };

    render() {
        const { contents, activeChapter } = this.state;
        const activeChapterContent = contents[activeChapter];
        const accountType = localStorage.getItem('accountType');
        const schoolName = localStorage.getItem('school');

        let schoolInfo = SCHOOLS.schoolList.find((school) => {
            return school.SCHOOLNAME == schoolName;
        });

        let showDigitalLinksToStudentsFlag =
            schoolInfo.showDigitalLinksToStudentsFlag && schoolInfo.showDigitalLinksToStudentsFlag == 'y'
                ? true
                : false;

        return (
            <div className="module-container">
                <Header />

                {this.state.isLoading && activeChapterContent ? (
                    <div className="module-content-container">
                        <div className="module-chapter-navigation-container">
                            {this.constructChapterButton(contents)}
                        </div>

                        <Title text={'Grade ' + this.state.studentInfo.grade} design="grade-level" />
                        <div className="chapter-details-container">
                            <Title text={activeChapterContent.CHAPTERLABEL} design="chapter-number" />
                            <Title text={activeChapterContent.CHAPTERTITLE} design="chapter-title" />
                        </div>

                        <hr className="module-content-page-break" />

                        <Text
                            text={activeChapterContent.CHAPTERLABEL + ' Digital Module'}
                            design="digital-module-text"
                        />

                        {activeChapterContent.modules &&
                            activeChapterContent.modules.map((module, index) => {
                                return (
                                    <div style={{ marginBottom: 10 }} key={index}>
                                        <Button
                                            text="DOWNLOAD"
                                            design="download-button-1"
                                            link={module.module_link}
                                            linkTo="_blank"
                                        />

                                        <Text text={module.module_title} />
                                    </div>
                                );
                            })}

                        {activeChapterContent.softwares ? (
                            <div className="software-links-container">
                                <Text text="Software Links" design="software-links-text" />
                                {activeChapterContent.softwares.length > 0
                                    ? activeChapterContent.softwares.map((software, index) => {
                                          return <SoftwareLink key={index} data={software} />;
                                      })
                                    : null}
                            </div>
                        ) : null}

                        {showDigitalLinksToStudentsFlag ||
                        (!showDigitalLinksToStudentsFlag && accountType != 'student') ? (
                            <Fragment>
                                <hr className="module-content-page-break" />

                                {activeChapterContent.lessons &&
                                    activeChapterContent.lessons.map((data, index) => {
                                        return <Lesson key={index} data={data} />;
                                    })}
                            </Fragment>
                        ) : null}
                    </div>
                ) : (
                    <div className="module-loading-container">
                        <CircularProgress color="primary" />
                    </div>
                )}
            </div>
        );
    }
}
