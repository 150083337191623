export const TOPICS = [
    { LESSONID: '1', TOPICID: '1', TOPICLABEL: '1', TOPICTITLE: 'Different Parts of Computer' },
    { LESSONID: '1', TOPICID: '2', TOPICLABEL: '2', TOPICTITLE: 'Input Devices' },
    { LESSONID: '1', TOPICID: '3', TOPICLABEL: '3', TOPICTITLE: 'Output Devices' },
    { LESSONID: '1', TOPICID: '4', TOPICLABEL: '4', TOPICTITLE: 'How to connect a Mouse' },
    { LESSONID: '1', TOPICID: '5', TOPICLABEL: '5', TOPICTITLE: 'How to connect a Keyboard' },
    { LESSONID: '1', TOPICID: '6', TOPICLABEL: '6', TOPICTITLE: 'How to connect a Speaker' },
    { LESSONID: '1', TOPICID: '7', TOPICLABEL: '7', TOPICTITLE: 'How to Plug in a Computer' },
    { LESSONID: '2', TOPICID: '8', TOPICLABEL: '1', TOPICTITLE: 'How to turn on your computer' },
    { LESSONID: '2', TOPICID: '9', TOPICLABEL: '2', TOPICTITLE: 'How to turn off your computer' },
    { LESSONID: '2', TOPICID: '10', TOPICLABEL: '3', TOPICTITLE: 'How to restart your computer' },
    { LESSONID: '2', TOPICID: '11', TOPICLABEL: '4', TOPICTITLE: 'How to put your computer in sleep mode' },
    { LESSONID: '3', TOPICID: '12', TOPICLABEL: '1', TOPICTITLE: "Let's Hover" },
    { LESSONID: '3', TOPICID: '13', TOPICLABEL: '2', TOPICTITLE: "Let's Play" },
    { LESSONID: '3', TOPICID: '14', TOPICLABEL: '3', TOPICTITLE: 'Practice Single Clicking!' },
    { LESSONID: '3', TOPICID: '15', TOPICLABEL: '4', TOPICTITLE: "Let's Play" },
    { LESSONID: '4', TOPICID: '16', TOPICLABEL: '1', TOPICTITLE: 'What are Icons?' },
    { LESSONID: '4', TOPICID: '17', TOPICLABEL: '2', TOPICTITLE: 'Software and Application for Kids' },
    { LESSONID: '4', TOPICID: '18', TOPICLABEL: '3', TOPICTITLE: 'Practice Double Clicking!' },
    { LESSONID: '4', TOPICID: '19', TOPICLABEL: '4', TOPICTITLE: "Let's Play" },
    { LESSONID: '5', TOPICID: '20', TOPICLABEL: '1', TOPICTITLE: 'Practice Drag and Drop' },
    { LESSONID: '5', TOPICID: '21', TOPICLABEL: '2', TOPICTITLE: "Let's Play" },
    { LESSONID: '6', TOPICID: '22', TOPICLABEL: '1', TOPICTITLE: "Let's see the letter keys" },
    { LESSONID: '6', TOPICID: '23', TOPICLABEL: '2', TOPICTITLE: "Let's Play" },
    { LESSONID: '7', TOPICID: '24', TOPICLABEL: '1', TOPICTITLE: "Let's see the Number Keys!" },
    { LESSONID: '7', TOPICID: '25', TOPICLABEL: '2', TOPICTITLE: "Let's Play" },
    { LESSONID: '8', TOPICID: '26', TOPICLABEL: '1', TOPICTITLE: 'Ways to open a software/application' },
    { LESSONID: '8', TOPICID: '27', TOPICLABEL: '2', TOPICTITLE: "Let's try opening some application" },
    { LESSONID: '8', TOPICID: '28', TOPICLABEL: '3', TOPICTITLE: 'How to close a software or application' },
    { LESSONID: '8', TOPICID: '29', TOPICLABEL: '4', TOPICTITLE: "Let's try closing some applications!" },
    { LESSONID: '9', TOPICID: '30', TOPICLABEL: '1', TOPICTITLE: 'What is minimized used for?' },
    { LESSONID: '9', TOPICID: '31', TOPICLABEL: '2', TOPICTITLE: "Let's minimize and reopen some applications!" },
    {
        LESSONID: '9',
        TOPICID: '32',
        TOPICLABEL: '3',
        TOPICTITLE: 'Difference between Maximize and Restore down button',
    },
    { LESSONID: '9', TOPICID: '33', TOPICLABEL: '4', TOPICTITLE: "Let's maximize and restore down some applications!" },
    { LESSONID: '10', TOPICID: '34', TOPICLABEL: '1', TOPICTITLE: 'How to Select Computer App or Icon on Desktop?' },
    { LESSONID: '10', TOPICID: '35', TOPICLABEL: '2', TOPICTITLE: 'How to Open a computer App or Folder on Desktop' },
    {
        LESSONID: '10',
        TOPICID: '36',
        TOPICLABEL: '3',
        TOPICTITLE: 'How to Move a computer App Icon or Folder on Desktop',
    },
    { LESSONID: '11', TOPICID: '37', TOPICLABEL: '1', TOPICTITLE: 'What is the right button of the mouse used for?' },
    { LESSONID: '11', TOPICID: '38', TOPICLABEL: '2', TOPICTITLE: 'How to Organize your desktop fast?' },
    { LESSONID: '11', TOPICID: '39', TOPICLABEL: '3', TOPICTITLE: 'How to create a new folder' },
    { LESSONID: '11', TOPICID: '40', TOPICLABEL: '4', TOPICTITLE: 'Rename, Delete and Restore a Folder' },
    { LESSONID: '11', TOPICID: '41', TOPICLABEL: '5', TOPICTITLE: 'Create a shortcut icon for easy access!' },
    { LESSONID: '11', TOPICID: '42', TOPICLABEL: '6', TOPICTITLE: 'What does Undo do?' },
    { LESSONID: '11', TOPICID: '43', TOPICLABEL: '7', TOPICTITLE: "Let's undo in notepad." },
    { LESSONID: '12', TOPICID: '44', TOPICLABEL: '1', TOPICTITLE: 'Where to rest your fingers when typing?' },
    { LESSONID: '12', TOPICID: '45', TOPICLABEL: '2', TOPICTITLE: 'Which keys should your left-hand press?' },
    { LESSONID: '12', TOPICID: '46', TOPICLABEL: '3', TOPICTITLE: 'Which keys should your right-hand press?' },
    { LESSONID: '12', TOPICID: '47', TOPICLABEL: '4', TOPICTITLE: 'Spacebar and Backspace.' },
    { LESSONID: '12', TOPICID: '48', TOPICLABEL: '5', TOPICTITLE: "Let's Practice Typing" },
    { LESSONID: '13', TOPICID: '49', TOPICLABEL: '1', TOPICTITLE: 'Typing with Line Spacing using enter key' },
    { LESSONID: '13', TOPICID: '50', TOPICLABEL: '2', TOPICTITLE: 'Ways to type a capital letter.' },
    { LESSONID: '13', TOPICID: '51', TOPICLABEL: '3', TOPICTITLE: "Let's Play with the Shift Key!" },
    { LESSONID: '13', TOPICID: '52', TOPICLABEL: '4', TOPICTITLE: 'Undo' },
    { LESSONID: '14', TOPICID: '53', TOPICLABEL: '1', TOPICTITLE: 'Let us navigate the desktop using arrow keys.' },
    { LESSONID: '14', TOPICID: '54', TOPICLABEL: '2', TOPICTITLE: 'Navigating with Enter Key and Backspace Key' },
    { LESSONID: '14', TOPICID: '55', TOPICLABEL: '3', TOPICTITLE: 'Let us play through key navigation!' },
    { LESSONID: '14', TOPICID: '56', TOPICLABEL: '4', TOPICTITLE: 'Using Tab inside a Folder' },
    { LESSONID: '14', TOPICID: '57', TOPICLABEL: '5', TOPICTITLE: 'What is Alt Tab for?' },
    {
        LESSONID: '15',
        TOPICID: '58',
        TOPICLABEL: '1',
        TOPICTITLE: 'What are the different internal locations in your computer?',
    },
    {
        LESSONID: '15',
        TOPICID: '59',
        TOPICLABEL: '2',
        TOPICTITLE: 'How to access different locations inside your computer?',
    },
    { LESSONID: '16', TOPICID: '60', TOPICLABEL: '1', TOPICTITLE: 'External Locations of a Computer' },
    { LESSONID: '16', TOPICID: '61', TOPICLABEL: '2', TOPICTITLE: 'What are USB Ports?' },
    { LESSONID: '16', TOPICID: '62', TOPICLABEL: '3', TOPICTITLE: 'Flashdrive or USB?' },
    { LESSONID: '16', TOPICID: '63', TOPICLABEL: '4', TOPICTITLE: 'Audio Ports!' },
    { LESSONID: '16', TOPICID: '64', TOPICLABEL: '5', TOPICTITLE: 'How to Find your Flashdrive after Plugging In?' },
    {
        LESSONID: '16',
        TOPICID: '65',
        TOPICLABEL: '6',
        TOPICTITLE: 'How to Safely Remove your Flash drive from the computer?',
    },
    { LESSONID: '17', TOPICID: '66', TOPICLABEL: '1', TOPICTITLE: 'Test yout typing speed!' },
    { LESSONID: '17', TOPICID: '67', TOPICLABEL: '2', TOPICTITLE: 'Type something about this!' },
    { LESSONID: '17', TOPICID: '68', TOPICLABEL: '3', TOPICTITLE: 'How to save your work?' },
    { LESSONID: '18', TOPICID: '69', TOPICLABEL: '1', TOPICTITLE: 'Test your typing speed!' },
    { LESSONID: '18', TOPICID: '70', TOPICLABEL: '2', TOPICTITLE: 'Paint this! Easy way to use Paint.' },
    { LESSONID: '19', TOPICID: '71', TOPICLABEL: '1', TOPICTITLE: "Let's open the File Explorer!" },
    { LESSONID: '19', TOPICID: '72', TOPICLABEL: '2', TOPICTITLE: 'What is a Title Bar?' },
    { LESSONID: '19', TOPICID: '73', TOPICLABEL: '3', TOPICTITLE: 'The Menu Bar!' },
    { LESSONID: '19', TOPICID: '74', TOPICLABEL: '4', TOPICTITLE: 'What can we find in a Toolbar?' },
    { LESSONID: '19', TOPICID: '75', TOPICLABEL: '5', TOPICTITLE: "Let's try View and Sort Options" },
    { LESSONID: '19', TOPICID: '76', TOPICLABEL: '6', TOPICTITLE: 'How to use search bar?' },
    { LESSONID: '20', TOPICID: '77', TOPICLABEL: '1', TOPICTITLE: 'What are file paths?' },
    { LESSONID: '20', TOPICID: '78', TOPICLABEL: '2', TOPICTITLE: 'Practice Following File Paths' },
    { LESSONID: '21', TOPICID: '79', TOPICLABEL: '1', TOPICTITLE: 'How to Create a Folder' },
    { LESSONID: '21', TOPICID: '80', TOPICLABEL: '2', TOPICTITLE: 'How to Delete a Folder' },
    { LESSONID: '21', TOPICID: '81', TOPICLABEL: '3', TOPICTITLE: 'How to Restore a Folder' },
    { LESSONID: '21', TOPICID: '82', TOPICLABEL: '4', TOPICTITLE: "Let's Rename our Folders" },
    { LESSONID: '21', TOPICID: '83', TOPICLABEL: '5', TOPICTITLE: 'What is Shortcuts Icon For?' },
    { LESSONID: '21', TOPICID: '84', TOPICLABEL: '6', TOPICTITLE: "Let's Create Shortcut Icon" },
    { LESSONID: '22', TOPICID: '85', TOPICLABEL: '1', TOPICTITLE: 'How to Copy and Paste Files' },
    { LESSONID: '22', TOPICID: '86', TOPICLABEL: '2', TOPICTITLE: 'The Difference between Copying and Cutting' },
    { LESSONID: '22', TOPICID: '87', TOPICLABEL: '3', TOPICTITLE: 'Undo Command' },
    { LESSONID: '22', TOPICID: '88', TOPICLABEL: '4', TOPICTITLE: 'Copying Multiple Files at Once' },
    { LESSONID: '23', TOPICID: '89', TOPICLABEL: '1', TOPICTITLE: 'What is Canvas in MS Paint' },
    { LESSONID: '23', TOPICID: '90', TOPICLABEL: '2', TOPICTITLE: 'How to Change Colors in MS Paint' },
    { LESSONID: '23', TOPICID: '91', TOPICLABEL: '3', TOPICTITLE: 'Painting Using Shapes Palette Tool' },
    { LESSONID: '23', TOPICID: '92', TOPICLABEL: '4', TOPICTITLE: 'Use Eraser Tool' },
    { LESSONID: '23', TOPICID: '93', TOPICLABEL: '5', TOPICTITLE: 'Painting Using Brush Tool' },
    { LESSONID: '23', TOPICID: '94', TOPICLABEL: '6', TOPICTITLE: 'Painting Using Pencil Tool' },
    { LESSONID: '24', TOPICID: '95', TOPICLABEL: '1', TOPICTITLE: 'Exploring Word Web' },
    { LESSONID: '24', TOPICID: '96', TOPICLABEL: '2', TOPICTITLE: 'Find the meaning of the following words' },
    { LESSONID: '24', TOPICID: '97', TOPICLABEL: '3', TOPICTITLE: 'Practice Highlighting' },
    { LESSONID: '24', TOPICID: '98', TOPICLABEL: '4', TOPICTITLE: 'Copy and Pasting Text to MS Word/WPS' },
    { LESSONID: '25', TOPICID: '99', TOPICLABEL: '1', TOPICTITLE: 'Menu Bar and Menu Tabs' },
    { LESSONID: '25', TOPICID: '100', TOPICLABEL: '2', TOPICTITLE: 'Simple Documentation Software Application' },
    { LESSONID: '25', TOPICID: '101', TOPICLABEL: '3', TOPICTITLE: "Let's Create a new document" },
    { LESSONID: '25', TOPICID: '102', TOPICLABEL: '4', TOPICTITLE: "Let's Save our document" },
    { LESSONID: '25', TOPICID: '103', TOPICLABEL: '5', TOPICTITLE: 'Opening Our Saved document' },
    { LESSONID: '26', TOPICID: '104', TOPICLABEL: '1', TOPICTITLE: 'Exploring Home Tab' },
    { LESSONID: '26', TOPICID: '105', TOPICLABEL: '2', TOPICTITLE: 'Basic and Common Text Formatting Tool' },
    { LESSONID: '26', TOPICID: '106', TOPICLABEL: '3', TOPICTITLE: 'Try Formatting Text' },
    { LESSONID: '26', TOPICID: '107', TOPICLABEL: '4', TOPICTITLE: "Let's Use the Option Save As" },
    { LESSONID: '27', TOPICID: '108', TOPICLABEL: '1', TOPICTITLE: 'What is a Youtube App' },
    { LESSONID: '27', TOPICID: '109', TOPICLABEL: '2', TOPICTITLE: 'Search for a Video' },
    { LESSONID: '27', TOPICID: '110', TOPICLABEL: '3', TOPICTITLE: 'Downloading a Video and playing it offline' },
    { LESSONID: '27', TOPICID: '111', TOPICLABEL: '4', TOPICTITLE: 'Creating my own youtube channel' },
    { LESSONID: '27', TOPICID: '112', TOPICLABEL: '5', TOPICTITLE: 'Uploading a simple video online' },
    {
        LESSONID: '28',
        TOPICID: '113',
        TOPICLABEL: '1',
        TOPICTITLE: 'How to open word documentation software applications',
    },
    { LESSONID: '28', TOPICID: '114', TOPICLABEL: '2', TOPICTITLE: 'How to create, save and create a new document' },
    {
        LESSONID: '28',
        TOPICID: '115',
        TOPICLABEL: '3',
        TOPICTITLE: 'Basic Parts of a documentation Software application',
    },
    { LESSONID: '28', TOPICID: '116', TOPICLABEL: '4', TOPICTITLE: 'Tools in the Home Tab' },
    { LESSONID: '28', TOPICID: '117', TOPICLABEL: '5', TOPICTITLE: 'Tools in the Insert Tab' },
    { LESSONID: '28', TOPICID: '118', TOPICLABEL: '6', TOPICTITLE: 'Basic Formatting Techniques for Text' },
    { LESSONID: '29', TOPICID: '119', TOPICLABEL: '1', TOPICTITLE: 'Use styles to design fonts' },
    { LESSONID: '29', TOPICID: '120', TOPICLABEL: '2', TOPICTITLE: 'Inserting through drag and drop technique' },
    {
        LESSONID: '29',
        TOPICID: '121',
        TOPICLABEL: '3',
        TOPICTITLE: 'Exploring Page Layout/Layout Tab: Changing Margin and Orientation',
    },
    { LESSONID: '29', TOPICID: '122', TOPICLABEL: '4', TOPICTITLE: 'Exploring other word documentation menu tabs' },
    { LESSONID: '30', TOPICID: '123', TOPICLABEL: '1', TOPICTITLE: 'What is a spreadsheet?' },
    { LESSONID: '30', TOPICID: '124', TOPICLABEL: '2', TOPICTITLE: 'Basic parts of a spreadsheet' },
    { LESSONID: '30', TOPICID: '125', TOPICLABEL: '3', TOPICTITLE: 'Let’s create our own worksheet' },
    {
        LESSONID: '30',
        TOPICID: '126',
        TOPICLABEL: '4',
        TOPICTITLE: 'Performing basic arithmetic sequences and techniques in a spreadsheet',
    },
    { LESSONID: '30', TOPICID: '127', TOPICLABEL: '5', TOPICTITLE: 'Using simple formulas in spreadsheet' },
    { LESSONID: '31', TOPICID: '128', TOPICLABEL: '1', TOPICTITLE: 'What is a slideshow presentation?' },
    { LESSONID: '31', TOPICID: '129', TOPICLABEL: '2', TOPICTITLE: 'How to create a simple slideshow presentation?' },
    { LESSONID: '31', TOPICID: '130', TOPICLABEL: '3', TOPICTITLE: 'Putting transitions' },
    { LESSONID: '31', TOPICID: '131', TOPICLABEL: '4', TOPICTITLE: 'Exploring animations' },
    { LESSONID: '31', TOPICID: '132', TOPICLABEL: '5', TOPICTITLE: 'Tips for creating an effective slideshow' },
    { LESSONID: '31', TOPICID: '133', TOPICLABEL: '6', TOPICTITLE: 'How to run or play your slideshow presentation?' },
    { LESSONID: '32', TOPICID: '134', TOPICLABEL: '1', TOPICTITLE: 'What is a publisher software application?' },
    { LESSONID: '32', TOPICID: '135', TOPICLABEL: '2', TOPICTITLE: 'How to create a simple publication file?' },
    { LESSONID: '32', TOPICID: '136', TOPICLABEL: '3', TOPICTITLE: 'Basic tools of a publisher software application' },
    { LESSONID: '32', TOPICID: '137', TOPICLABEL: '4', TOPICTITLE: 'Inserting footnotes' },
    { LESSONID: '32', TOPICID: '138', TOPICLABEL: '5', TOPICTITLE: 'Tips in creating a good publication file' },
    { LESSONID: '32', TOPICID: '139', TOPICLABEL: '6', TOPICTITLE: 'Saving your document as a PDF file' },
    { LESSONID: '33', TOPICID: '140', TOPICLABEL: '1', TOPICTITLE: 'What are drawing and photo editing application?' },
    {
        LESSONID: '33',
        TOPICID: '141',
        TOPICLABEL: '2',
        TOPICTITLE: 'Getting started: creating a new canvass and saving it',
    },
    { LESSONID: '33', TOPICID: '142', TOPICLABEL: '3', TOPICTITLE: 'Pencil tool vs Brush tool' },
    {
        LESSONID: '33',
        TOPICID: '143',
        TOPICLABEL: '4',
        TOPICTITLE: 'Useful tools for finishing your drawing and editing photos',
    },
    { LESSONID: '33', TOPICID: '144', TOPICLABEL: '5', TOPICTITLE: 'Creating multi-colored shapes' },
    {
        LESSONID: '33',
        TOPICID: '145',
        TOPICLABEL: '6',
        TOPICTITLE: 'How to combine pictures and create a simple collage',
    },
    { LESSONID: '34', TOPICID: '146', TOPICLABEL: '1', TOPICTITLE: 'What is a flowcharting software application?' },
    { LESSONID: '34', TOPICID: '147', TOPICLABEL: '2', TOPICTITLE: 'Flowchart symbols and connectors' },
    { LESSONID: '34', TOPICID: '148', TOPICLABEL: '3', TOPICTITLE: 'Example of simple flowcharts' },
    {
        LESSONID: '34',
        TOPICID: '149',
        TOPICLABEL: '4',
        TOPICTITLE: 'Create a simple flowchart using a flowcharting software application',
    },
    {
        LESSONID: '34',
        TOPICID: '150',
        TOPICLABEL: '5',
        TOPICTITLE: 'Tips in creating a simple and understandable flowchart',
    },
    { LESSONID: '35', TOPICID: '151', TOPICLABEL: '1', TOPICTITLE: 'Page layout tools in MS Word' },
    { LESSONID: '35', TOPICID: '152', TOPICLABEL: '2', TOPICTITLE: 'Page layout tools in Google Docs' },
    { LESSONID: '35', TOPICID: '153', TOPICLABEL: '3', TOPICTITLE: 'What are Object Formatting Tab?' },
    { LESSONID: '35', TOPICID: '154', TOPICLABEL: '4', TOPICTITLE: 'Proper Object Formatting' },
    { LESSONID: '35', TOPICID: '155', TOPICLABEL: '5', TOPICTITLE: 'Creating a Professional Looking Document' },
    { LESSONID: '36', TOPICID: '156', TOPICLABEL: '1', TOPICTITLE: 'What are Review Tab and View Tab For?' },
    { LESSONID: '36', TOPICID: '157', TOPICLABEL: '2', TOPICTITLE: "Let's Explore Review Tab!" },
    { LESSONID: '36', TOPICID: '158', TOPICLABEL: '3', TOPICTITLE: "Let's Explore the View Tab!" },
    {
        LESSONID: '37',
        TOPICID: '159',
        TOPICLABEL: '1',
        TOPICTITLE: 'Exploring Advance Tools in a Word Documentation Software Application',
    },
    { LESSONID: '37', TOPICID: '160', TOPICLABEL: '2', TOPICTITLE: 'Revisiting Basic & Advance Shortcut Commands' },
    {
        LESSONID: '37',
        TOPICID: '161',
        TOPICLABEL: '3',
        TOPICTITLE: 'Exploring Advance Shortcut Commands in a Word Documentation Software Application',
    },
    {
        LESSONID: '38',
        TOPICID: '162',
        TOPICLABEL: '1',
        TOPICTITLE: 'Meeting Formulas in Documentation Software Applications for Calculation',
    },
    {
        LESSONID: '38',
        TOPICID: '163',
        TOPICLABEL: '2',
        TOPICTITLE: 'How to create Charts in Documentation Applications for Calculation',
    },
    {
        LESSONID: '38',
        TOPICID: '164',
        TOPICLABEL: '3',
        TOPICTITLE: 'Exploring Advance Tools and Commands in Documentation Software Application for Calculation',
    },
    { LESSONID: '39', TOPICID: '165', TOPICLABEL: '1', TOPICTITLE: 'What are Presentation Software Application For?' },
    { LESSONID: '39', TOPICID: '166', TOPICLABEL: '2', TOPICTITLE: 'Basic Application Software Tools and Tabs' },
    {
        LESSONID: '39',
        TOPICID: '167',
        TOPICLABEL: '3',
        TOPICTITLE: 'Advance Tools and Commands in Presentation Software Application',
    },
    {
        LESSONID: '39',
        TOPICID: '168',
        TOPICLABEL: '4',
        TOPICTITLE: 'Trying Advance Animation in Presentation Software Application',
    },
    { LESSONID: '40', TOPICID: '169', TOPICLABEL: '1', TOPICTITLE: 'What are Publishing Software Applications For?' },
    {
        LESSONID: '40',
        TOPICID: '170',
        TOPICLABEL: '2',
        TOPICTITLE: 'Revisiting Basic Puiblishing Software Applications Tools and Tabs',
    },
    {
        LESSONID: '40',
        TOPICID: '171',
        TOPICLABEL: '3',
        TOPICTITLE: 'Exploring Advance Tools and Commands in Publishing Software Application',
    },
    {
        LESSONID: '40',
        TOPICID: '172',
        TOPICLABEL: '4',
        TOPICTITLE: 'Trying Advacne Animation in Publishing Software Applications',
    },
    { LESSONID: '41', TOPICID: '173', TOPICLABEL: '1', TOPICTITLE: 'Most Popular Photo Editing Software' },
    {
        LESSONID: '41',
        TOPICID: '174',
        TOPICLABEL: '2',
        TOPICTITLE: 'Basic Photo Editing Software Application Tools and Tabs',
    },
    {
        LESSONID: '41',
        TOPICID: '175',
        TOPICLABEL: '3',
        TOPICTITLE: 'Advanced Tools and Basic Shortcut Commands in Photo Editing Software Applications',
    },
    { LESSONID: '41', TOPICID: '176', TOPICLABEL: '4', TOPICTITLE: 'Making a Digital Photo Collage' },
    { LESSONID: '41', TOPICID: '177', TOPICLABEL: '5', TOPICTITLE: 'How to Create GIF?' },
    { LESSONID: '42', TOPICID: '178', TOPICLABEL: '1', TOPICTITLE: 'Most Popular Video Editing Software Applications' },
    {
        LESSONID: '42',
        TOPICID: '179',
        TOPICLABEL: '2',
        TOPICTITLE: 'Basic Video Editing Software Application Tools and Tabs',
    },
    { LESSONID: '42', TOPICID: '180', TOPICLABEL: '3', TOPICTITLE: 'Inserting Elements' },
    { LESSONID: '42', TOPICID: '181', TOPICLABEL: '4', TOPICTITLE: 'How to Make Simple Video?' },
    { LESSONID: '43', TOPICID: '182', TOPICLABEL: '1', TOPICTITLE: 'Font Changing' },
    { LESSONID: '43', TOPICID: '183', TOPICLABEL: '2', TOPICTITLE: 'Drag and Drop' },
    { LESSONID: '43', TOPICID: '184', TOPICLABEL: '3', TOPICTITLE: 'Cut, Copy and Paste' },
    { LESSONID: '43', TOPICID: '185', TOPICLABEL: '4', TOPICTITLE: 'Inserting Pictures' },
    {
        LESSONID: '44',
        TOPICID: '186',
        TOPICLABEL: '1',
        TOPICTITLE: 'Template of a Basic Flowchart Process of a Daily Life',
    },
    { LESSONID: '44', TOPICID: '187', TOPICLABEL: '2', TOPICTITLE: 'Video on cooking food' },
    { LESSONID: '44', TOPICID: '188', TOPICLABEL: '3', TOPICTITLE: 'Video in basic trouble shooting computer' },
    { LESSONID: '44', TOPICID: '189', TOPICLABEL: '4', TOPICTITLE: 'Video on how ATM machines work' },
    { LESSONID: '44', TOPICID: '190', TOPICLABEL: '5', TOPICTITLE: 'Video on how mobile data works' },
    { LESSONID: '44', TOPICID: '191', TOPICLABEL: '6', TOPICTITLE: 'Video on how wireless connections work' },
    { LESSONID: '45', TOPICID: '192', TOPICLABEL: '1', TOPICTITLE: 'Video of a teacher using visual aids' },
    { LESSONID: '45', TOPICID: '193', TOPICLABEL: '2', TOPICTITLE: 'Video of a teacher using PowerPoint presentation' },
    {
        LESSONID: '45',
        TOPICID: '194',
        TOPICLABEL: '3',
        TOPICTITLE: "Video on international presentation on events like Mother's Day",
    },
    { LESSONID: '45', TOPICID: '195', TOPICLABEL: '4', TOPICTITLE: 'Video of mathematical operations' },
    { LESSONID: '45', TOPICID: '196', TOPICLABEL: '5', TOPICTITLE: 'Video of English Tutorials' },
    {
        LESSONID: '46',
        TOPICID: '197',
        TOPICLABEL: '1',
        TOPICTITLE: 'Steps on how to start a presentation in PowerPoint',
    },
    { LESSONID: '46', TOPICID: '198', TOPICLABEL: '2', TOPICTITLE: 'Steps on how to start a presentation in WPS' },
    {
        LESSONID: '46',
        TOPICID: '199',
        TOPICLABEL: '3',
        TOPICTITLE: 'Video of things needed for a presentation using a projector',
    },
    { LESSONID: '46', TOPICID: '200', TOPICLABEL: '4', TOPICTITLE: 'Basic knowledge of display cable connector' },
    { LESSONID: '46', TOPICID: '201', TOPICLABEL: '5', TOPICTITLE: 'Video of business advertisements' },
    { LESSONID: '46', TOPICID: '202', TOPICLABEL: '6', TOPICTITLE: 'Video of teachers using presentation in school' },
    { LESSONID: '47', TOPICID: '203', TOPICLABEL: '1', TOPICTITLE: 'Start by providing a basic lecture on arithmetic' },
    { LESSONID: '47', TOPICID: '204', TOPICLABEL: '2', TOPICTITLE: 'Move on PEMDAS' },
    { LESSONID: '47', TOPICID: '205', TOPICLABEL: '3', TOPICTITLE: 'Video on how to enter formula into the cells' },
    { LESSONID: '48', TOPICID: '206', TOPICLABEL: '1', TOPICTITLE: 'Video on how to create a line graph' },
    { LESSONID: '48', TOPICID: '207', TOPICLABEL: '2', TOPICTITLE: 'Video on how to create bar charts' },
    { LESSONID: '48', TOPICID: '208', TOPICLABEL: '3', TOPICTITLE: 'Video on how to create pie charts' },
    {
        LESSONID: '49',
        TOPICID: '209',
        TOPICLABEL: '1',
        TOPICTITLE: 'Show a video of creating and designing a photo in PowerPoint',
    },
    {
        LESSONID: '49',
        TOPICID: '210',
        TOPICLABEL: '2',
        TOPICTITLE: 'Show a video of creating and designing a video in PowerPoint',
    },
    {
        LESSONID: '49',
        TOPICID: '211',
        TOPICLABEL: '3',
        TOPICTITLE: 'Show a video of creating and designing a video in any smart app',
    },
    { LESSONID: '50', TOPICID: '212', TOPICLABEL: '1', TOPICTITLE: 'Show a karaoke video' },
    { LESSONID: '50', TOPICID: '213', TOPICLABEL: '2', TOPICTITLE: 'Show a picture music video' },
    { LESSONID: '50', TOPICID: '214', TOPICLABEL: '3', TOPICTITLE: 'Show a tutorial video' },
    { LESSONID: '50', TOPICID: '215', TOPICLABEL: '4', TOPICTITLE: 'Show a tribute video' },
    {
        LESSONID: '51',
        TOPICID: '216',
        TOPICLABEL: '1',
        TOPICTITLE: 'Exploring the Workspace (of the Image editing Software to be used)',
    },
    {
        LESSONID: '51',
        TOPICID: '217',
        TOPICLABEL: '2',
        TOPICTITLE: 'Exploring Image Properties and How to Manipulate them',
    },
    { LESSONID: '51', TOPICID: '218', TOPICLABEL: '3', TOPICTITLE: 'Inserting Pictures and other elements.' },
    { LESSONID: '51', TOPICID: '219', TOPICLABEL: '4', TOPICTITLE: 'Creating Brand New Images from Scratch' },
    { LESSONID: '51', TOPICID: '220', TOPICLABEL: '5', TOPICTITLE: 'Image Manipulation Techniques.' },
    { LESSONID: '52', TOPICID: '221', TOPICLABEL: '1', TOPICTITLE: 'Tools and Ways on Selecting Image or Text Parts' },
    { LESSONID: '52', TOPICID: '222', TOPICLABEL: '2', TOPICTITLE: 'How to Cut, Trim and Crop Images' },
    {
        LESSONID: '52',
        TOPICID: '223',
        TOPICLABEL: '3',
        TOPICTITLE: 'Pasting Methods of Copied or Cut Parts of an Image',
    },
    { LESSONID: '53', TOPICID: '224', TOPICLABEL: '1', TOPICTITLE: 'What is Smartphones Application' },
    { LESSONID: '53', TOPICID: '225', TOPICLABEL: '2', TOPICTITLE: "Let's download smartphone application" },
    { LESSONID: '53', TOPICID: '226', TOPICLABEL: '3', TOPICTITLE: 'Editing image using smartphone application' },
    { LESSONID: '53', TOPICID: '227', TOPICLABEL: '4', TOPICTITLE: 'Downloading Images from Editing' },
    { LESSONID: '53', TOPICID: '228', TOPICLABEL: '5', TOPICTITLE: 'Combining and Enhancing Images using Smartphone' },
    { LESSONID: '54', TOPICID: '229', TOPICLABEL: '1', TOPICTITLE: 'What are pixels' },
    { LESSONID: '54', TOPICID: '230', TOPICLABEL: '2', TOPICTITLE: 'Comparing Different Image Resolutions' },
    {
        LESSONID: '54',
        TOPICID: '231',
        TOPICLABEL: '3',
        TOPICTITLE:
            'Organizing Images in the Computer (proper groupings, tips in creating folders for image combing projects)',
    },
    { LESSONID: '54', TOPICID: '232', TOPICLABEL: '4', TOPICTITLE: 'Combining Images Skillfully' },
    { LESSONID: '54', TOPICID: '233', TOPICLABEL: '5', TOPICTITLE: 'Blurring Techniques and Application' },
    {
        LESSONID: '54',
        TOPICID: '234',
        TOPICLABEL: '6',
        TOPICTITLE: 'Simple Image Editing Tricks (Pimple Removal, Fat to thin etc)',
    },
    { LESSONID: '55', TOPICID: '235', TOPICLABEL: '1', TOPICTITLE: 'Digital Sound Files, how are they created?' },
    { LESSONID: '55', TOPICID: '236', TOPICLABEL: '2', TOPICTITLE: 'Using an audio recording application' },
    { LESSONID: '55', TOPICID: '237', TOPICLABEL: '3', TOPICTITLE: 'Opening, Saving and Moving Sound Files' },
    {
        LESSONID: '55',
        TOPICID: '238',
        TOPICLABEL: '4',
        TOPICTITLE: 'Editing a Sound file (Adjusting pitch, tone, and volume)',
    },
    {
        LESSONID: '56',
        TOPICID: '239',
        TOPICLABEL: '1',
        TOPICTITLE: 'Select, Cut, Copy and Paste Sound File to another',
    },
    { LESSONID: '56', TOPICID: '240', TOPICLABEL: '2', TOPICTITLE: 'Layering vs Overlapping Sound Files' },
    { LESSONID: '56', TOPICID: '241', TOPICLABEL: '3', TOPICTITLE: 'Fade In, Fade Out' },
    {
        LESSONID: '56',
        TOPICID: '242',
        TOPICLABEL: '4',
        TOPICTITLE: 'Enhancing Sound Properties (Effect, Volume, Speed and Quality)',
    },
    { LESSONID: '57', TOPICID: '243', TOPICLABEL: '1', TOPICTITLE: 'What is flowcharting software application?' },
    { LESSONID: '57', TOPICID: '244', TOPICLABEL: '2', TOPICTITLE: 'Flowchart Symbols and Connectors' },
    { LESSONID: '57', TOPICID: '245', TOPICLABEL: '3', TOPICTITLE: 'Example of Simple Flowchart' },
    {
        LESSONID: '57',
        TOPICID: '246',
        TOPICLABEL: '4',
        TOPICTITLE: 'Creating Simple Flowchart Using Flowchart Software Application',
    },
    { LESSONID: '57', TOPICID: '247', TOPICLABEL: '5', TOPICTITLE: 'Tips in Creating and Understandable Flowchart' },
    { LESSONID: '58', TOPICID: '248', TOPICLABEL: '1', TOPICTITLE: 'What do programmers do? What is programming?' },
    { LESSONID: '58', TOPICID: '249', TOPICLABEL: '2', TOPICTITLE: 'What is Google Block?' },
    {
        LESSONID: '58',
        TOPICID: '250',
        TOPICLABEL: '3',
        TOPICTITLE: 'Creating and Converting a Program Using Google Blocks',
    },
    {
        LESSONID: '58',
        TOPICID: '251',
        TOPICLABEL: '4',
        TOPICTITLE: 'Typing Codes from Converted Google Blocks Program',
    },
    { LESSONID: '59', TOPICID: '252', TOPICLABEL: '1', TOPICTITLE: 'Basics of Photoshop' },
    { LESSONID: '60', TOPICID: '253', TOPICLABEL: '1', TOPICTITLE: 'Tools Outside Tools' },
    { LESSONID: '61', TOPICID: '254', TOPICLABEL: '1', TOPICTITLE: 'Advanced Photoshop Tools' },
    { LESSONID: '62', TOPICID: '255', TOPICLABEL: '1', TOPICTITLE: 'Image Enhancement' },
    { LESSONID: '63', TOPICID: '256', TOPICLABEL: '1', TOPICTITLE: 'Starting with Windows Live Movie Maker' },
    { LESSONID: '64', TOPICID: '257', TOPICLABEL: '1', TOPICTITLE: 'Managing Each Video Component' },
    { LESSONID: '65', TOPICID: '258', TOPICLABEL: '1', TOPICTITLE: 'Introduction to Cyberlink Power Director' },
    {
        LESSONID: '66',
        TOPICID: '259',
        TOPICLABEL: '1',
        TOPICTITLE: 'Exporting Projects with Complete Video Components',
    },
    { LESSONID: '67', TOPICID: '260', TOPICLABEL: '1', TOPICTITLE: 'Get to Know Photoshop!' },
    { LESSONID: '67', TOPICID: '261', TOPICLABEL: '2', TOPICTITLE: 'Working with Layers' },
    { LESSONID: '67', TOPICID: '262', TOPICLABEL: '3', TOPICTITLE: 'Photoshop Tutorials' },
    { LESSONID: '68', TOPICID: '263', TOPICLABEL: '1', TOPICTITLE: 'How to use Pen Tools for Drawing Lines' },
    { LESSONID: '68', TOPICID: '264', TOPICLABEL: '2', TOPICTITLE: 'Drawing Lines and Shapes' },
    { LESSONID: '68', TOPICID: '265', TOPICLABEL: '3', TOPICTITLE: 'Draw with shapes in Photoshop' },
    { LESSONID: '69', TOPICID: '266', TOPICLABEL: '1', TOPICTITLE: 'Adding text in the Canvas' },
    { LESSONID: '69', TOPICID: '267', TOPICLABEL: '2', TOPICTITLE: 'Design great typography' },
    { LESSONID: '69', TOPICID: '268', TOPICLABEL: '3', TOPICTITLE: 'Photoshop text effect tutorials' },
    { LESSONID: '69', TOPICID: '269', TOPICLABEL: '4', TOPICTITLE: 'Formatting characters with Photoshop' },
    { LESSONID: '70', TOPICID: '270', TOPICLABEL: '1', TOPICTITLE: 'Color Management with Photoshop' },
    { LESSONID: '70', TOPICID: '271', TOPICLABEL: '2', TOPICTITLE: 'Replacing colors with Photoshop' },
    { LESSONID: '70', TOPICID: '272', TOPICLABEL: '3', TOPICTITLE: 'Creating custom color swatches' },
    { LESSONID: '71', TOPICID: '273', TOPICLABEL: '1', TOPICTITLE: 'Brightness and Contrast Adjustment' },
    { LESSONID: '71', TOPICID: '274', TOPICLABEL: '2', TOPICTITLE: 'Adjusting Hue and Saturation' },
    { LESSONID: '72', TOPICID: '275', TOPICLABEL: '1', TOPICTITLE: 'Scaling element using free transform' },
    { LESSONID: '72', TOPICID: '276', TOPICLABEL: '2', TOPICTITLE: 'Aligning objects with Photoshop' },
    { LESSONID: '73', TOPICID: '277', TOPICLABEL: '1', TOPICTITLE: 'Image enhancing techniques' },
    { LESSONID: '73', TOPICID: '278', TOPICLABEL: '2', TOPICTITLE: 'Other image enhancement techniques' },
    { LESSONID: '74', TOPICID: '279', TOPICLABEL: '1', TOPICTITLE: 'Visio for different fields of expertise' },
    { LESSONID: '75', TOPICID: '280', TOPICLABEL: '1', TOPICTITLE: 'Visio as a flowcharting tool' },
    { LESSONID: '76', TOPICID: '281', TOPICLABEL: '1', TOPICTITLE: 'Understanding C++' },
    { LESSONID: '77', TOPICID: '282', TOPICLABEL: '1', TOPICTITLE: 'Code equivalent of blocks and shapes' },
    { LESSONID: '78', TOPICID: '283', TOPICLABEL: '1', TOPICTITLE: 'Coding from scratch' },
    { LESSONID: '79', TOPICID: '284', TOPICLABEL: '1', TOPICTITLE: 'Equations in programming' },
    { LESSONID: '80', TOPICID: '285', TOPICLABEL: '1', TOPICTITLE: 'Conditional statements' },
    { LESSONID: '81', TOPICID: '286', TOPICLABEL: '1', TOPICTITLE: 'Applications of C++' },
    {
        LESSONID: '82',
        TOPICID: '287',
        TOPICLABEL: '1',
        TOPICTITLE: 'Survey Monkey and other sites for creating online surveys',
    },
    { LESSONID: '83', TOPICID: '288', TOPICLABEL: '1', TOPICTITLE: 'Tips on how to create a great survbey form' },
    { LESSONID: '83', TOPICID: '289', TOPICLABEL: '2', TOPICTITLE: "Clearly define your survey's purpose" },
    { LESSONID: '83', TOPICID: '290', TOPICLABEL: '3', TOPICTITLE: 'Keep the survey short and focused' },
    { LESSONID: '83', TOPICID: '291', TOPICLABEL: '4', TOPICTITLE: 'Keep the question simple' },
    {
        LESSONID: '83',
        TOPICID: '292',
        TOPICLABEL: '5',
        TOPICTITLE: 'Keep rating scale questions consistent through the survey',
    },
    { LESSONID: '83', TOPICID: '293', TOPICLABEL: '6', TOPICTITLE: 'Logical ordering' },
    { LESSONID: '83', TOPICID: '294', TOPICLABEL: '7', TOPICTITLE: 'Pre-test your survey' },
    {
        LESSONID: '83',
        TOPICID: '295',
        TOPICLABEL: '8',
        TOPICTITLE: 'Consider your audience when sending survey invitation',
    },
    { LESSONID: '83', TOPICID: '296', TOPICLABEL: '9', TOPICTITLE: 'Consider sending several reminders' },
    { LESSONID: '84', TOPICID: '297', TOPICLABEL: '1', TOPICTITLE: 'Google Spreadsheets' },
    { LESSONID: '85', TOPICID: '298', TOPICLABEL: '1', TOPICTITLE: 'Basic Terms and Parts of the Workspace' },
    { LESSONID: '86', TOPICID: '299', TOPICLABEL: '1', TOPICTITLE: 'Basic Data Editing Commands' },
    { LESSONID: '86', TOPICID: '300', TOPICLABEL: '2', TOPICTITLE: 'An Introduction to the Advacne Excel Commands' },
    { LESSONID: '87', TOPICID: '301', TOPICLABEL: '1', TOPICTITLE: 'Advance Data Editing Commands' },
    { LESSONID: '88', TOPICID: '302', TOPICLABEL: '1', TOPICTITLE: 'Saving your File as PDF' },
    { LESSONID: '88', TOPICID: '303', TOPICLABEL: '2', TOPICTITLE: 'Making use of Excel Charts' },
    { LESSONID: '89', TOPICID: '304', TOPICLABEL: '1', TOPICTITLE: 'Cavas' },
    { LESSONID: '89', TOPICID: '305', TOPICLABEL: '2', TOPICTITLE: 'Layer' },
    { LESSONID: '89', TOPICID: '306', TOPICLABEL: '3', TOPICTITLE: 'Bucket tool for color background' },
    { LESSONID: '89', TOPICID: '307', TOPICLABEL: '4', TOPICTITLE: 'Transform tool' },
    { LESSONID: '89', TOPICID: '308', TOPICLABEL: '5', TOPICTITLE: 'Opacity' },
    { LESSONID: '89', TOPICID: '309', TOPICLABEL: '6', TOPICTITLE: 'Move tool' },
    { LESSONID: '90', TOPICID: '310', TOPICLABEL: '1', TOPICTITLE: 'Rectangle Tool' },
    { LESSONID: '90', TOPICID: '311', TOPICLABEL: '2', TOPICTITLE: 'Creating a wave' },
    { LESSONID: '90', TOPICID: '312', TOPICLABEL: '3', TOPICTITLE: 'Eraser tool and fading edges' },
    { LESSONID: '90', TOPICID: '313', TOPICLABEL: '4', TOPICTITLE: 'Retate Shaoes and Group Layers' },
    { LESSONID: '91', TOPICID: '314', TOPICLABEL: '1', TOPICTITLE: 'Color Schemes' },
    {
        LESSONID: '91',
        TOPICID: '315',
        TOPICLABEL: '2',
        TOPICTITLE: 'Color schemes of famous technology brands and others today',
    },
    { LESSONID: '91', TOPICID: '316', TOPICLABEL: '3', TOPICTITLE: 'Online application for color scheme creation' },
    { LESSONID: '92', TOPICID: '317', TOPICLABEL: '1', TOPICTITLE: 'Standards of using fonts' },
    { LESSONID: '92', TOPICID: '318', TOPICLABEL: '2', TOPICTITLE: 'Font sizes' },
    { LESSONID: '92', TOPICID: '319', TOPICLABEL: '3', TOPICTITLE: 'Font combinations' },
    { LESSONID: '92', TOPICID: '320', TOPICLABEL: '4', TOPICTITLE: 'Color suggestions' },
    { LESSONID: '92', TOPICID: '321', TOPICLABEL: '5', TOPICTITLE: 'High Contrast' },
    { LESSONID: '93', TOPICID: '322', TOPICLABEL: '1', TOPICTITLE: 'The Wix Editor' },
    { LESSONID: '94', TOPICID: '323', TOPICLABEL: '1', TOPICTITLE: 'Wix Site Manager' },
    { LESSONID: '94', TOPICID: '324', TOPICLABEL: '2', TOPICTITLE: 'Blog Manager' },
    { LESSONID: '95', TOPICID: '325', TOPICLABEL: '1', TOPICTITLE: 'First, SIgn Up!' },
    { LESSONID: '95', TOPICID: '326', TOPICLABEL: '2', TOPICTITLE: 'Its Time to Customize!' },
    { LESSONID: '95', TOPICID: '327', TOPICLABEL: '3', TOPICTITLE: 'Other Features of your Webpage' },
];
