export const SOFTWARES = [
    {
        GRADEID: '1',
        SOFTWAREID: '1',
        SOFTWARELINK: 'https://drive.google.com/file/d/1REbgb4sq3rKSv40g6jYlmSbHsCQnYAa6/view?usp=sharing',
        SOFTWARENAME: 'Gcompris',
    },
    {
        GRADEID: '2',
        SOFTWAREID: '2',
        SOFTWARELINK: 'https://drive.google.com/file/d/1REbgb4sq3rKSv40g6jYlmSbHsCQnYAa6/view?usp=sharing',
        SOFTWARENAME: 'Gcompris',
    },
    {
        GRADEID: '2',
        SOFTWAREID: '3',
        SOFTWARELINK: 'https://sourceforge.net/projects/tuxtype/',
        SOFTWARENAME: 'Tuxtype',
    },
    {
        GRADEID: '2',
        SOFTWAREID: '4',
        SOFTWARELINK: 'https://www.typingmaster.com/typing-tutor/free-download.html',
        SOFTWARENAME: 'Typing Master',
    },
    {
        GRADEID: '2',
        SOFTWAREID: '5',
        SOFTWARELINK: 'https://www.abcya.com/games/keyboarding_practice',
        SOFTWARENAME: 'Keyboarding Zoo by ABCYa',
    },
    {
        GRADEID: '2',
        SOFTWAREID: '6',
        SOFTWARELINK: 'https://play.google.com/store/apps/details?id=com.husseinelfeky.typingmaster&hl=en&gl=US',
        SOFTWARENAME: 'Typing Master Mobile App',
    },
    {
        GRADEID: '3',
        SOFTWAREID: '7',
        SOFTWARELINK: 'https://play.google.com/store/apps/details?id=com.kidspaint.kaushalmehra.drawingfun&hl=en',
        SOFTWARENAME: 'Paint Free - Drawing Fun',
    },
    { GRADEID: '3', SOFTWAREID: '8', SOFTWARELINK: 'https://wordweb.info/free/', SOFTWARENAME: 'Wordweb' },
    { GRADEID: '3', SOFTWAREID: '9', SOFTWARELINK: 'https://www.wps.com/en-PH/download/', SOFTWARENAME: 'WPS' },
    {
        GRADEID: '3',
        SOFTWAREID: '10',
        SOFTWARELINK: 'https://kidmons.com/game/paint-online/',
        SOFTWARENAME: 'Kids Mons Paint Online',
    },
    {
        GRADEID: '3',
        SOFTWAREID: '11',
        SOFTWARELINK: 'https://www.abcya.com/games/abcya_paint',
        SOFTWARENAME: 'ABCYa Paint',
    },
    {
        GRADEID: '4',
        SOFTWAREID: '12',
        SOFTWARELINK: 'https://play.google.com/store/apps/details?id=com.google.android.youtube&hl=en',
        SOFTWARENAME: 'Youtube Mobile App',
    },
    { GRADEID: '4', SOFTWAREID: '13', SOFTWARELINK: 'https://www.wps.com/en-PH/download/', SOFTWARENAME: 'WPS' },
    { GRADEID: '5', SOFTWAREID: '14', SOFTWARELINK: 'https://www.wps.com/en-PH/download/', SOFTWARENAME: 'WPS' },
    { GRADEID: '6', SOFTWAREID: '15', SOFTWARELINK: 'https://www.wps.com/en-PH/download/', SOFTWARENAME: 'WPS' },
    { GRADEID: '7', SOFTWAREID: '16', SOFTWARELINK: 'https://www.gimp.org/downloads/', SOFTWARENAME: 'GIMP' },
    {
        GRADEID: '7',
        SOFTWAREID: '17',
        SOFTWARELINK: 'https://play.google.com/store/apps/details?id=org.dandroidmobile.xgimp&hl=en',
        SOFTWARENAME: 'XGIMP Image Editor Mobile App',
    },
    {
        GRADEID: '7',
        SOFTWAREID: '18',
        SOFTWARELINK: 'https://download.cnet.com/Acoustica-MP3-Audio-Mixer/3000-2170_4-10029712.html',
        SOFTWARENAME: 'Acoustica Audio Mixer',
    },
    { GRADEID: '8', SOFTWAREID: '19', SOFTWARELINK: 'https://rawtherapee.com/', SOFTWARENAME: 'RawTherapee' },
    { GRADEID: '8', SOFTWAREID: '20', SOFTWARELINK: 'https://firealpaca.com/', SOFTWARENAME: 'Firealpaca' },
    {
        GRADEID: '8',
        SOFTWAREID: '21',
        SOFTWARELINK: 'https://drive.google.com/file/d/1Xxc27CP1K2AzZvL_UJ-j5Vlo0QExgYVu/view',
        SOFTWARENAME: 'Photoshop',
    },
    {
        GRADEID: '8',
        SOFTWAREID: '22',
        SOFTWARELINK: 'OpenShot Video Editor | Download',
        SOFTWARENAME: 'Openshot Video Editor',
    },
    { GRADEID: '9', SOFTWAREID: '23', SOFTWARELINK: 'https://rawtherapee.com/', SOFTWARENAME: 'RawTherapee' },
    { GRADEID: '9', SOFTWAREID: '24', SOFTWARELINK: 'https://firealpaca.com/', SOFTWARENAME: 'Firealpaca' },
    {
        GRADEID: '9',
        SOFTWAREID: '25',
        SOFTWARELINK: 'https://drive.google.com/file/d/1Xxc27CP1K2AzZvL_UJ-j5Vlo0QExgYVu/view',
        SOFTWARENAME: 'Photoshop',
    },
    { GRADEID: '10', SOFTWAREID: '26', SOFTWARELINK: '', SOFTWARENAME: 'Visio' },
    { GRADEID: '10', SOFTWAREID: '27', SOFTWARELINK: 'https://app.diagrams.net/', SOFTWARENAME: 'Draw.io' },
    {
        GRADEID: '10',
        SOFTWAREID: '28',
        SOFTWARELINK: 'Online C++ Compiler (programiz.com)',
        SOFTWARENAME: 'Programiz C++ online compiler',
    },
    {
        GRADEID: '12',
        SOFTWAREID: '29',
        SOFTWARELINK: 'https://app.sketchup.com/app#',
        SOFTWARENAME: 'SketchUp',
    },
];
