export const DIGITAL_LINKS = [
    { TOPICID: '1', DIGITALLINKID: '1', DIGITALLINKURL: 'https://m.youtube.com/watch?v=zh10mJEOjJ4&t=13s' },
    { TOPICID: '1', DIGITALLINKID: '2', DIGITALLINKURL: 'https://youtu.be/K00dKueUDuI' },
    { TOPICID: '1', DIGITALLINKID: '3', DIGITALLINKURL: 'https://www.idtech.com/blog/parts-of-a-computer' },
    { TOPICID: '2', DIGITALLINKID: '4', DIGITALLINKURL: 'https://youtu.be/WZmc4jHu284' },
    { TOPICID: '2', DIGITALLINKID: '5', DIGITALLINKURL: 'https://youtu.be/Kc0kJfQzo0s' },
    { TOPICID: '2', DIGITALLINKID: '6', DIGITALLINKURL: 'https://youtu.be/iiJ72-rzgsU' },
    { TOPICID: '3', DIGITALLINKID: '7', DIGITALLINKURL: 'https://youtu.be/3ks3zVG1Pho' },
    { TOPICID: '3', DIGITALLINKID: '8', DIGITALLINKURL: 'https://youtu.be/7nxnQ6x5uvE' },
    { TOPICID: '3', DIGITALLINKID: '9', DIGITALLINKURL: 'https://youtu.be/yTdRHrkudGs' },
    { TOPICID: '4', DIGITALLINKID: '10', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000448.htm' },
    { TOPICID: '4', DIGITALLINKID: '11', DIGITALLINKURL: 'https://www.youtube.com/watch?v=VrxIoU-5qFo' },
    {
        TOPICID: '4',
        DIGITALLINKID: '12',
        DIGITALLINKURL: 'https://www.dummies.com/computers/pcs/how-to-connect-your-keyboard-and-mouse-to-your-pc/',
    },
    { TOPICID: '5', DIGITALLINKID: '13', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000449.htm' },
    { TOPICID: '5', DIGITALLINKID: '14', DIGITALLINKURL: 'https://youtu.be/qMq5LaYQkPc' },
    {
        TOPICID: '5',
        DIGITALLINKID: '15',
        DIGITALLINKURL: 'https://www.dummies.com/computers/pcs/how-to-connect-your-keyboard-and-mouse-to-your-pc/',
    },
    { TOPICID: '6', DIGITALLINKID: '16', DIGITALLINKURL: 'https://youtu.be/WsPIhKK5tCI' },
    { TOPICID: '6', DIGITALLINKID: '17', DIGITALLINKURL: 'https://youtu.be/V-kE091cizg' },
    {
        TOPICID: '6',
        DIGITALLINKID: '18',
        DIGITALLINKURL:
            'https://www.digitalunite.com/technology-guides/tv-video/getting-set-online-viewing/how-connect-speakers-computer',
    },
    { TOPICID: '7', DIGITALLINKID: '19', DIGITALLINKURL: 'https://youtu.be/KdQeU5QTfYE' },
    {
        TOPICID: '7',
        DIGITALLINKID: '20',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/computerbasics/setting-up-a-computer/1/',
    },
    {
        TOPICID: '7',
        DIGITALLINKID: '21',
        DIGITALLINKURL: 'https://www.dummies.com/computers/pcs/how-to-plug-in-your-pc-with-a-ups/',
    },
    { TOPICID: '8', DIGITALLINKID: '22', DIGITALLINKURL: 'https://youtu.bHeUIoUe/VI1YR' },
    {
        TOPICID: '8',
        DIGITALLINKID: '23',
        DIGITALLINKURL: 'https://www.google.com/amp/s/www.wikihow.com/Turn-on-a-Windows-PC%3famp=1',
    },
    {
        TOPICID: '8',
        DIGITALLINKID: '24',
        DIGITALLINKURL:
            'https://www.computerhope.com/issues/ch001690.htm#:~:text=How%20to%20turn%20on%20a%20desktop%20computer,corner%20of%20the%20computer%20case',
    },
    {
        TOPICID: '9',
        DIGITALLINKID: '25',
        DIGITALLINKURL:
            'https://www.digitalunite.com/technology-guides/computer-basics/using-computer/how-turn-computer-0',
    },
    { TOPICID: '9', DIGITALLINKID: '26', DIGITALLINKURL: 'https://www.wikihow.com/Turn-Off-a-Personal-Computer' },
    { TOPICID: '9', DIGITALLINKID: '27', DIGITALLINKURL: 'https://www.youtube.com/watch?v=RnkaGwFJp3o' },
    {
        TOPICID: '10',
        DIGITALLINKID: '28',
        DIGITALLINKURL: 'https://www.google.com/amp/s/www.wikihow.tech/Restart-a-Computer%3famp=1',
    },
    { TOPICID: '10', DIGITALLINKID: '29', DIGITALLINKURL: 'https://youtu.be/wQRViLVs8Vo' },
    { TOPICID: '10', DIGITALLINKID: '30', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000825.htm' },
    { TOPICID: '11', DIGITALLINKID: '31', DIGITALLINKURL: 'https://youtu.be/LL-M81ZxZIo' },
    {
        TOPICID: '11',
        DIGITALLINKID: '32',
        DIGITALLINKURL: 'https://www.dummies.com/computers/pcs/how-to-put-your-laptop-into-sleep-mode/',
    },
    {
        TOPICID: '11',
        DIGITALLINKID: '33',
        DIGITALLINKURL: 'https://support.microsoft.com/en-ph/help/13770/windows-shut-down-sleep-hibernate-your-pc',
    },
    { TOPICID: '12', DIGITALLINKID: '34', DIGITALLINKURL: 'https://en.m.wikipedia.org/wiki/Point_and_click' },
    { TOPICID: '12', DIGITALLINKID: '35', DIGITALLINKURL: 'https://www.webopedia.com/TERM/M/mouse_hover.html' },
    { TOPICID: '12', DIGITALLINKID: '36', DIGITALLINKURL: 'https://www.computerhope.com/jargon/h/hover.htm' },
    { TOPICID: '13', DIGITALLINKID: '37', DIGITALLINKURL: '' },
    { TOPICID: '14', DIGITALLINKID: '38', DIGITALLINKURL: 'https://www.computerhope.com/jargon/c/click.htm' },
    {
        TOPICID: '14',
        DIGITALLINKID: '39',
        DIGITALLINKURL: 'https://www.andyrathbone.com/2010/09/06/how-do-i-open-desktop-icons-with-a-single-click/',
    },
    {
        TOPICID: '14',
        DIGITALLINKID: '40',
        DIGITALLINKURL: 'https://www.lib.uom.gr/accelerate/ttu_en/devices_mouse.html#click',
    },
    { TOPICID: '15', DIGITALLINKID: '41', DIGITALLINKURL: '' },
    { TOPICID: '16', DIGITALLINKID: '42', DIGITALLINKURL: 'https://www.computerhope.com/jargon/i/icon.htm' },
    { TOPICID: '16', DIGITALLINKID: '43', DIGITALLINKURL: 'https://whatis.techtarget.com/definition/icon' },
    { TOPICID: '16', DIGITALLINKID: '44', DIGITALLINKURL: 'https://websitebuilders.com/how-to/glossary/icon/' },
    { TOPICID: '17', DIGITALLINKID: '45', DIGITALLINKURL: 'https://youtu.be/c5vdAc5C_4Y' },
    { TOPICID: '17', DIGITALLINKID: '46', DIGITALLINKURL: 'https://www.kidwaresoftware.com/childrens/' },
    {
        TOPICID: '17',
        DIGITALLINKID: '47',
        DIGITALLINKURL: 'https://elearningindustry.com/10-top-educational-apps-for-kids',
    },
    { TOPICID: '18', DIGITALLINKID: '48', DIGITALLINKURL: 'https://www.computerhope.com/jargon/c/click.htm' },
    { TOPICID: '18', DIGITALLINKID: '49', DIGITALLINKURL: 'http://www.mouseprogram.com/double-click-game.html' },
    { TOPICID: '18', DIGITALLINKID: '50', DIGITALLINKURL: 'https://techterms.com/definition/doubleclick' },
    { TOPICID: '19', DIGITALLINKID: '51', DIGITALLINKURL: 'https://youtu.be/bUPJaqA1h9U' },
    { TOPICID: '19', DIGITALLINKID: '52', DIGITALLINKURL: 'https://youtu.be/5209qxJTgFM' },
    { TOPICID: '19', DIGITALLINKID: '53', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001598.htm' },
    { TOPICID: '20', DIGITALLINKID: '54', DIGITALLINKURL: '' },
    { TOPICID: '21', DIGITALLINKID: '55', DIGITALLINKURL: '' },
    { TOPICID: '22', DIGITALLINKID: '56', DIGITALLINKURL: 'https://www.computerhope.com/jargon/n/numekeyp.htm' },
    {
        TOPICID: '22',
        DIGITALLINKID: '57',
        DIGITALLINKURL: 'http://members.tripod.com/bcis_central_austin/numeric_keypad.htm',
    },
    { TOPICID: '22', DIGITALLINKID: '58', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Numeric_keypad' },
    { TOPICID: '23', DIGITALLINKID: '59', DIGITALLINKURL: '' },
    { TOPICID: '24', DIGITALLINKID: '60', DIGITALLINKURL: '' },
    { TOPICID: '25', DIGITALLINKID: '61', DIGITALLINKURL: '' },
    {
        TOPICID: '26',
        DIGITALLINKID: '62',
        DIGITALLINKURL: 'https://www.dummies.com/software/for-seniors-how-to-open-a-computer-program/',
    },
    {
        TOPICID: '26',
        DIGITALLINKID: '63',
        DIGITALLINKURL: 'https://guides.co/g/microsoft-word-how-to-start-a-document/11895',
    },
    {
        TOPICID: '26',
        DIGITALLINKID: '64',
        DIGITALLINKURL: 'http://www.wisecleaner.com/how-to/69-3-methods-to-quick-launch-applications-in-windows.html',
    },
    { TOPICID: '27', DIGITALLINKID: '65', DIGITALLINKURL: '' },
    { TOPICID: '28', DIGITALLINKID: '66', DIGITALLINKURL: 'https://www.wikihow.com/Close-Windows' },
    {
        TOPICID: '28',
        DIGITALLINKID: '67',
        DIGITALLINKURL: 'https://industrydev.com/3-ways-to-close-applications-in-windows-10/',
    },
    { TOPICID: '28', DIGITALLINKID: '68', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001372.htm' },
    { TOPICID: '29', DIGITALLINKID: '69', DIGITALLINKURL: '' },
    { TOPICID: '30', DIGITALLINKID: '70', DIGITALLINKURL: 'https://www.computerhope.com/jargon/m/minimize.htm' },
    {
        TOPICID: '30',
        DIGITALLINKID: '71',
        DIGITALLINKURL:
            'https://techterms.com/definition/minimize#:~:text=When%20you%20minimize%20a%20window,for%20it%20in%20the%20taskbar',
    },
    { TOPICID: '30', DIGITALLINKID: '72', DIGITALLINKURL: 'https://www.youtube.com/watch?v=VAzeTQrWPjI' },
    { TOPICID: '31', DIGITALLINKID: '73', DIGITALLINKURL: '' },
    {
        TOPICID: '32',
        DIGITALLINKID: '74',
        DIGITALLINKURL:
            'https://flylib.com/books/en/2.406.1/objective_3_maximize_restore_minimize_and_close_a_window.html',
    },
    { TOPICID: '32', DIGITALLINKID: '75', DIGITALLINKURL: 'https://www.youtube.com/watch?v=aoFofvHDjZE' },
    {
        TOPICID: '32',
        DIGITALLINKID: '76',
        DIGITALLINKURL:
            'https://help.gnome.org/users/gnome-help/stable/shell-windows-maximize.html.en#:~:text=To%20maximize%20a%20window%20using,the%20titlebar%20to%20restore%20it',
    },
    { TOPICID: '33', DIGITALLINKID: '77', DIGITALLINKURL: '' },
    {
        TOPICID: '34',
        DIGITALLINKID: '78',
        DIGITALLINKURL:
            'http://www.nuance.com/naturallyspeaking/customer-portal/documentation/userguide/chapter4/ug_chapter4_icons_on_desktop.pdf',
    },
    { TOPICID: '35', DIGITALLINKID: '79', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001694.htm' },
    {
        TOPICID: '35',
        DIGITALLINKID: '80',
        DIGITALLINKURL: 'https://www.digitalcitizen.life/open-files-folders-apps-windows',
    },
    {
        TOPICID: '35',
        DIGITALLINKID: '81',
        DIGITALLINKURL:
            'https://nusit.nus.edu.sg/support/eguides/nbox-for-windows/opening-your-windows-desktop-application-folder/',
    },
    {
        TOPICID: '36',
        DIGITALLINKID: '82',
        DIGITALLINKURL: 'https://support.microsoft.com/en-ph/help/289587/how-to-arrange-or-move-icons-on-the-desktop3',
    },
    { TOPICID: '36', DIGITALLINKID: '83', DIGITALLINKURL: 'https://www.youtube.com/watch?v=VWXXQTwcaVE' },
    { TOPICID: '36', DIGITALLINKID: '84', DIGITALLINKURL: 'https://www.youtube.com/watch?v=O8APlRUkP2s' },
    {
        TOPICID: '37',
        DIGITALLINKID: '85',
        DIGITALLINKURL:
            'https://www.uow.edu.au/student/learning-co-op/technology-and-software/mouse-buttons/#:~:text=The%20right%20button%20on%20a,options',
    },
    { TOPICID: '37', DIGITALLINKID: '86', DIGITALLINKURL: 'https://techterms.com/definition/rightclick' },
    { TOPICID: '37', DIGITALLINKID: '87', DIGITALLINKURL: 'https://www.computerhope.com/jargon/r/righclic.htm' },
    {
        TOPICID: '38',
        DIGITALLINKID: '88',
        DIGITALLINKURL: 'https://www.inc.com/larry-alton/7-ways-you-need-to-organize-your-desktop-and-your-life.html',
    },
    {
        TOPICID: '38',
        DIGITALLINKID: '89',
        DIGITALLINKURL:
            'https://www.howtogeek.com/362241/how-to-organize-your-messy-windows-desktop-and-keep-it-that-way/#:~:text=For%20a%20quick%20organization%2C%20you,your%20desktop%20is%20very%20messy',
    },
    {
        TOPICID: '38',
        DIGITALLINKID: '90',
        DIGITALLINKURL: 'https://blog.hubspot.com/marketing/organize-computer-desktop-tips',
    },
    { TOPICID: '39', DIGITALLINKID: '91', DIGITALLINKURL: 'https://www.youtube.com/watch?v=XP3FDSlCjrE' },
    {
        TOPICID: '39',
        DIGITALLINKID: '92',
        DIGITALLINKURL: 'https://www.techwalla.com/articles/how-to-create-new-folders-on-your-desktop',
    },
    {
        TOPICID: '39',
        DIGITALLINKID: '93',
        DIGITALLINKURL: 'https://www.dummies.com/software/for-seniors-how-to-create-a-new-folder-on-your-computer/',
    },
    { TOPICID: '40', DIGITALLINKID: '94', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Z5iE0Hc9qOc' },
    { TOPICID: '40', DIGITALLINKID: '95', DIGITALLINKURL: 'https://www.youtube.com/watch?v=JuWAOcu389o' },
    { TOPICID: '40', DIGITALLINKID: '96', DIGITALLINKURL: 'https://www.youtube.com/watch?v=JuWAOcu389o' },
    { TOPICID: '41', DIGITALLINKID: '97', DIGITALLINKURL: 'https://www.youtube.com/watch?v=eySVk1Sr6Yg' },
    {
        TOPICID: '41',
        DIGITALLINKID: '98',
        DIGITALLINKURL: 'https://www.lifewire.com/how-to-create-desktop-shortcut-windows-4584822',
    },
    {
        TOPICID: '41',
        DIGITALLINKID: '99',
        DIGITALLINKURL: 'https://helpx.adobe.com/x-productkb/global/create-desktop-icon-or-shortcut.html',
    },
    { TOPICID: '42', DIGITALLINKID: '100', DIGITALLINKURL: 'https://www.computerhope.com/jargon/u/undo.htm' },
    { TOPICID: '42', DIGITALLINKID: '101', DIGITALLINKURL: 'https://www.youtube.com/watch?v=gmOfXQIiT54' },
    { TOPICID: '42', DIGITALLINKID: '102', DIGITALLINKURL: 'https://techterms.com/definition/undo' },
    { TOPICID: '43', DIGITALLINKID: '103', DIGITALLINKURL: '' },
    { TOPICID: '44', DIGITALLINKID: '104', DIGITALLINKURL: 'https://www.typing-lessons.org/preliminaries_4.html' },
    {
        TOPICID: '44',
        DIGITALLINKID: '105',
        DIGITALLINKURL:
            'https://www.typingpal.com/en/news/improving-your-typing-method-our-tips#:~:text=The%20Basic%20Position,is%20called%20the%20basic%20position',
    },
    { TOPICID: '44', DIGITALLINKID: '106', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001346.htm' },
    { TOPICID: '45', DIGITALLINKID: '107', DIGITALLINKURL: 'https://www.youtube.com/watch?v=2S3lhm8LaZo' },
    { TOPICID: '45', DIGITALLINKID: '108', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001346.htm' },
    {
        TOPICID: '45',
        DIGITALLINKID: '109',
        DIGITALLINKURL: 'https://www.learn2type.com/typingtest/lessons/typingkeyboards.cfm',
    },
    { TOPICID: '46', DIGITALLINKID: '110', DIGITALLINKURL: 'https://www.youtube.com/watch?v=2S3lhm8LaZo' },
    { TOPICID: '46', DIGITALLINKID: '111', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001346.htm' },
    { TOPICID: '46', DIGITALLINKID: '112', DIGITALLINKURL: 'https://www.youtube.com/watch?v=HG5aNPH0eKs' },
    { TOPICID: '47', DIGITALLINKID: '113', DIGITALLINKURL: 'https://www.computerhope.com/jargon/s/spacebar.htm' },
    { TOPICID: '47', DIGITALLINKID: '114', DIGITALLINKURL: 'https://www.computerhope.com/jargon/b/backspac.htm' },
    { TOPICID: '47', DIGITALLINKID: '115', DIGITALLINKURL: 'https://www.youtube.com/watch?v=n0UAqR3LpAA' },
    { TOPICID: '48', DIGITALLINKID: '116', DIGITALLINKURL: '' },
    {
        TOPICID: '49',
        DIGITALLINKID: '117',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2013/line-and-paragraph-spacing/1/',
    },
    { TOPICID: '50', DIGITALLINKID: '118', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001741.htm' },
    {
        TOPICID: '50',
        DIGITALLINKID: '119',
        DIGITALLINKURL: 'https://www.youtube.com/results?search_query=Ways+to+type+a+capital+letter',
    },
    {
        TOPICID: '50',
        DIGITALLINKID: '120',
        DIGITALLINKURL:
            'https://www.how-to-type.com/touch-typing-lessons/how-to-type-capitals/#:~:text=To%20type%20the%20capital%20case,are%20typing%20the%20letter%20with',
    },
    { TOPICID: '51', DIGITALLINKID: '121', DIGITALLINKURL: '' },
    {
        TOPICID: '52',
        DIGITALLINKID: '122',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/undo-redo-or-repeat-an-action-84bdb9bc-4e23-4f06-ba78-f7b893eb2d28',
    },
    {
        TOPICID: '52',
        DIGITALLINKID: '123',
        DIGITALLINKURL:
            'https://www.dummies.com/software/microsoft-office/word/how-to-use-the-undo-command-in-word-2016/',
    },
    {
        TOPICID: '52',
        DIGITALLINKID: '124',
        DIGITALLINKURL:
            'https://en.wikipedia.org/wiki/Undo#:~:text=In%20most%20Microsoft%20Windows%20applications,or%20Ctrl%2BShift%2BZ',
    },
    { TOPICID: '53', DIGITALLINKID: '125', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000791.htm' },
    { TOPICID: '54', DIGITALLINKID: '126', DIGITALLINKURL: 'https://www.computerhope.com/jargon/e/enterkey.htm' },
    { TOPICID: '55', DIGITALLINKID: '127', DIGITALLINKURL: '' },
    { TOPICID: '56', DIGITALLINKID: '128', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Tab_key' },
    { TOPICID: '57', DIGITALLINKID: '129', DIGITALLINKURL: 'https://www.computerhope.com/jargon/a/alt-tab.htm' },
    { TOPICID: '57', DIGITALLINKID: '130', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Alt-Tab' },
    { TOPICID: '57', DIGITALLINKID: '131', DIGITALLINKURL: 'https://www.youtube.com/watch?v=13MahGNs84Y' },
    {
        TOPICID: '58',
        DIGITALLINKID: '132',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/computerbasics/inside-a-computer/1/',
    },
    {
        TOPICID: '59',
        DIGITALLINKID: '133',
        DIGITALLINKURL:
            'https://www.dummies.com/software/for-seniors-how-to-locate-files-and-folders-in-your-computer/',
    },
    {
        TOPICID: '59',
        DIGITALLINKID: '134',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/windowsbasics/finding-files-on-your-computer/1/',
    },
    { TOPICID: '59', DIGITALLINKID: '135', DIGITALLINKURL: 'https://www.youtube.com/watch?v=GtGVItw89NM' },
    {
        TOPICID: '60',
        DIGITALLINKID: '136',
        DIGITALLINKURL:
            'https://courses.lumenlearning.com/informationliteracy/chapter/computer-devices/#:~:text=Devices%20that%20exist%20outside%20the,internal%20hard%20drives%20or%20CD%2D',
    },
    { TOPICID: '60', DIGITALLINKID: '137', DIGITALLINKURL: 'https://www.computerhope.com/jargon/e/external.htm' },
    { TOPICID: '61', DIGITALLINKID: '138', DIGITALLINKURL: 'https://www.computerhope.com/jargon/u/usb.htm' },
    { TOPICID: '61', DIGITALLINKID: '139', DIGITALLINKURL: 'https://www.lifewire.com/what-is-a-usb-port-818166' },
    { TOPICID: '61', DIGITALLINKID: '140', DIGITALLINKURL: 'https://www.firefold.com/blogs/news/all-about-usb-ports' },
    { TOPICID: '62', DIGITALLINKID: '141', DIGITALLINKURL: '' },
    { TOPICID: '63', DIGITALLINKID: '142', DIGITALLINKURL: 'https://itstillworks.com/audio-port-computer-18204.html' },
    {
        TOPICID: '63',
        DIGITALLINKID: '143',
        DIGITALLINKURL: 'https://www.techwalla.com/articles/what-is-an-audio-port-on-a-computer',
    },
    {
        TOPICID: '63',
        DIGITALLINKID: '144',
        DIGITALLINKURL: 'https://monopricesupport.kayako.com/article/221-what-are-the-common-audio-cable-connectors',
    },
    {
        TOPICID: '64',
        DIGITALLINKID: '145',
        DIGITALLINKURL: 'https://itstillworks.com/flash-drive-computer-5098607.html',
    },
    {
        TOPICID: '64',
        DIGITALLINKID: '146',
        DIGITALLINKURL:
            'https://www.howtogeek.com/howto/22251/find-your-missing-usb-drive-in-windows-7-vista/#:~:text=the%20Device%20Manager.-,On%20Windows%208%20or%2010%2C%20right%2Dclick%20the%20Start%20button,Run%20dialog%2C%20and%20press%20Enter.&text=Expand%20the%20%E2%80%9CDisk%20Drives%E2%80%9D%20and,exclamation%20mark%20on%20their%20icon.l',
    },
    { TOPICID: '64', DIGITALLINKID: '147', DIGITALLINKURL: 'https://www.youtube.com/watch?v=a5NuYT8_BlQ' },
    {
        TOPICID: '65',
        DIGITALLINKID: '148',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/windowsbasics/working-with-flash-drives/1/',
    },
    { TOPICID: '65', DIGITALLINKID: '149', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001943.htm' },
    {
        TOPICID: '65',
        DIGITALLINKID: '150',
        DIGITALLINKURL:
            'https://etc.usf.edu/techease/win/hardware/how-do-i-safely-remove-a-usb-device-from-my-computer/',
    },
    { TOPICID: '66', DIGITALLINKID: '151', DIGITALLINKURL: '' },
    { TOPICID: '67', DIGITALLINKID: '152', DIGITALLINKURL: '' },
    { TOPICID: '68', DIGITALLINKID: '153', DIGITALLINKURL: 'https://infopeople.org/resources/tutorials/html/saving' },
    { TOPICID: '68', DIGITALLINKID: '154', DIGITALLINKURL: 'https://www.youtube.com/watch?v=X_2qt_zKWpE' },
    { TOPICID: '68', DIGITALLINKID: '155', DIGITALLINKURL: 'https://www.noobie.com/save-document-notepad/' },
    { TOPICID: '69', DIGITALLINKID: '156', DIGITALLINKURL: '' },
    { TOPICID: '70', DIGITALLINKID: '157', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Microsoft_Paint' },
    { TOPICID: '70', DIGITALLINKID: '158', DIGITALLINKURL: 'https://www.wikihow.com/Use-Microsoft-Paint-in-Windows' },
    { TOPICID: '70', DIGITALLINKID: '159', DIGITALLINKURL: 'https://www.ba-bamail.com/content.aspx?emailid=16693' },
    { TOPICID: '71', DIGITALLINKID: '160', DIGITALLINKURL: 'https://www.computerhope.com/jargon/e/explorer.htm' },
    { TOPICID: '71', DIGITALLINKID: '161', DIGITALLINKURL: 'https://www.wikihow.com/Open-File-Explorer' },
    {
        TOPICID: '71',
        DIGITALLINKID: '162',
        DIGITALLINKURL: 'https://simplyfixit.co.uk/open-file-explorer-windows-10/r',
    },
    { TOPICID: '72', DIGITALLINKID: '163', DIGITALLINKURL: 'https://www.computerhope.com/jargon/t/titlebar.htm' },
    { TOPICID: '72', DIGITALLINKID: '164', DIGITALLINKURL: 'https://www.techopedia.com/definition/5481/title-bar' },
    {
        TOPICID: '72',
        DIGITALLINKID: '165',
        DIGITALLINKURL: 'https://theictbook.com/what-is-a-title-bar-and-its-features/',
    },
    { TOPICID: '73', DIGITALLINKID: '166', DIGITALLINKURL: 'https://www.computerhope.com/jargon/m/menubar.htm' },
    { TOPICID: '73', DIGITALLINKID: '167', DIGITALLINKURL: 'https://www.techopedia.com/definition/5449/menu-bar' },
    { TOPICID: '73', DIGITALLINKID: '168', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Menu_bar' },
    { TOPICID: '74', DIGITALLINKID: '169', DIGITALLINKURL: 'https://www.computerhope.com/jargon/t/toolbar.htm' },
    { TOPICID: '74', DIGITALLINKID: '170', DIGITALLINKURL: 'https://techterms.com/definition/toolbar' },
    { TOPICID: '74', DIGITALLINKID: '171', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Toolbar' },
    {
        TOPICID: '75',
        DIGITALLINKID: '172',
        DIGITALLINKURL:
            'https://www.informit.com/articles/article.aspx?p=2163343&seqNum=26#:~:text=In%20the%20desktop%2C%20click%20or,window%20or%20its%20own%20window',
    },
    {
        TOPICID: '75',
        DIGITALLINKID: '173',
        DIGITALLINKURL: 'https://www.informit.com/articles/article.aspx?p=1963993&seqNum=15',
    },
    { TOPICID: '75', DIGITALLINKID: '174', DIGITALLINKURL: 'https://www.youtube.com/watch?v=3uvj4RlZUY8' },
    {
        TOPICID: '76',
        DIGITALLINKID: '175',
        DIGITALLINKURL:
            'http://hs.windows.microsoft.com/hhweb/content/m-en-us/p-6.2/id-8d6963c6-f737-4009-a061-22cbb976bdc3/#:~:text=To%20search%20for%20files%20in,the%20Search%20Tools%20tab%20appears',
    },
    {
        TOPICID: '76',
        DIGITALLINKID: '176',
        DIGITALLINKURL: 'https://www.techrepublic.com/article/how-to-use-the-search-tool-in-windows-10-file-explorer/',
    },
    { TOPICID: '76', DIGITALLINKID: '177', DIGITALLINKURL: 'https://www.youtube.com/watch?v=TPkLNxsaNbE' },
    { TOPICID: '77', DIGITALLINKID: '178', DIGITALLINKURL: 'https://www.computerhope.com/jargon/p/path.htm' },
    { TOPICID: '77', DIGITALLINKID: '179', DIGITALLINKURL: 'https://www.youtube.com/watch?v=BMT3JUWmqYY' },
    { TOPICID: '77', DIGITALLINKID: '180', DIGITALLINKURL: 'https://www.youtube.com/watch?v=th23yuZ9fOw' },
    { TOPICID: '78', DIGITALLINKID: '181', DIGITALLINKURL: '' },
    { TOPICID: '79', DIGITALLINKID: '182', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000742.htm' },
    { TOPICID: '79', DIGITALLINKID: '183', DIGITALLINKURL: 'https://www.youtube.com/watch?v=baGp474z7QM' },
    {
        TOPICID: '79',
        DIGITALLINKID: '184',
        DIGITALLINKURL: 'https://www.youtube.com/watch?v=xIFPb5yb1hM&list=TLPQMTgwODIwMjAMe79elYmJlg&index=1',
    },
    {
        TOPICID: '80',
        DIGITALLINKID: '185',
        DIGITALLINKURL: 'https://www.apowersoft.com/recover-files-from-recycle-bin.html',
    },
    {
        TOPICID: '80',
        DIGITALLINKID: '186',
        DIGITALLINKURL: 'https://www.dummies.com/software/for-seniors-how-to-delete-a-computer-file-or-folder/',
    },
    { TOPICID: '80', DIGITALLINKID: '187', DIGITALLINKURL: 'https://youtu.be/UzbU-U-su8A' },
    { TOPICID: '81', DIGITALLINKID: '188', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000743.htm' },
    {
        TOPICID: '81',
        DIGITALLINKID: '189',
        DIGITALLINKURL: 'https://recoverit.wondershare.com/deleted-file-recovery/recover-deleted-folders.html',
    },
    { TOPICID: '81', DIGITALLINKID: '190', DIGITALLINKURL: 'https://youtu.be/iJM13FkwRkA' },
    { TOPICID: '82', DIGITALLINKID: '191', DIGITALLINKURL: 'https://www.youtube.com/watch?v=lAc8_2g2g2U' },
    {
        TOPICID: '82',
        DIGITALLINKID: '192',
        DIGITALLINKURL: 'https://help.gnome.org/users/gnome-help/stable/files-rename.html.en',
    },
    {
        TOPICID: '82',
        DIGITALLINKID: '193',
        DIGITALLINKURL: 'https://www.howtogeek.com/665514/6-ways-to-rename-files-and-folders-in-windows-10/',
    },
    { TOPICID: '83', DIGITALLINKID: '194', DIGITALLINKURL: 'https://www.youtube.com/watch?v=1y0qNrxj4_c' },
    { TOPICID: '83', DIGITALLINKID: '195', DIGITALLINKURL: 'https://whatis.techtarget.com/definition/shortcut' },
    { TOPICID: '83', DIGITALLINKID: '196', DIGITALLINKURL: 'https://www.pcmag.com/encyclopedia/term/shortcut' },
    {
        TOPICID: '84',
        DIGITALLINKID: '197',
        DIGITALLINKURL: 'https://helpx.adobe.com/x-productkb/global/create-desktop-icon-or-shortcut.html',
    },
    { TOPICID: '84', DIGITALLINKID: '198', DIGITALLINKURL: 'https://www.youtube.com/watch?v=-UoDn7sSJP0' },
    { TOPICID: '84', DIGITALLINKID: '199', DIGITALLINKURL: 'https://www.youtube.com/watch?v=QmGvXOGYTGc' },
    {
        TOPICID: '85',
        DIGITALLINKID: '200',
        DIGITALLINKURL: 'https://help.gnome.org/users/gnome-help/stable/files-copy.html.en',
    },
    { TOPICID: '85', DIGITALLINKID: '201', DIGITALLINKURL: 'https://www.youtube.com/watch?v=yrfX7aStQYk' },
    {
        TOPICID: '85',
        DIGITALLINKID: '202',
        DIGITALLINKURL: 'https://www.hellotech.com/guide/for/how-to-copy-and-paste-on-windows-10',
    },
    { TOPICID: '86', DIGITALLINKID: '203', DIGITALLINKURL: 'https://pediaa.com/difference-between-cut-and-copy/' },
    { TOPICID: '86', DIGITALLINKID: '204', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ix2CaAIxYww' },
    {
        TOPICID: '86',
        DIGITALLINKID: '205',
        DIGITALLINKURL:
            'https://en.wikipedia.org/wiki/Cut,_copy,_and_paste#:~:text=The%20cut%20command%20removes%20the,a%20paste%20command%20is%20issued',
    },
    { TOPICID: '87', DIGITALLINKID: '206', DIGITALLINKURL: 'https://www.computerhope.com/jargon/u/undo.htm' },
    { TOPICID: '87', DIGITALLINKID: '207', DIGITALLINKURL: 'https://help.wps.com/articles/use-undo-redo-command' },
    {
        TOPICID: '87',
        DIGITALLINKID: '208',
        DIGITALLINKURL:
            'https://en.wikipedia.org/wiki/Undo#:~:text=Undo%20is%20an%20interaction%20technique,to%20the%20file%20being%20edited',
    },
    { TOPICID: '88', DIGITALLINKID: '209', DIGITALLINKURL: 'https://www.youtube.com/watch?v=4LG00oESJ74' },
    { TOPICID: '88', DIGITALLINKID: '210', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000771.htm' },
    {
        TOPICID: '88',
        DIGITALLINKID: '211',
        DIGITALLINKURL:
            'https://www.pcworld.com/article/2086566/the-many-ways-to-copy-move-or-delete-multiple-files.html',
    },
    {
        TOPICID: '89',
        DIGITALLINKID: '212',
        DIGITALLINKURL:
            'https://www.sofolympiadtrainer.com/forum/questions/37842/pwhat-is-canvas-in-ms-paint-brbrspan-classfirstaaspanthe-area-where-you-draw-pictures--brspan-classf',
    },
    {
        TOPICID: '89',
        DIGITALLINKID: '213',
        DIGITALLINKURL:
            'http://www.testingeducation.org/k04/examples/dom07s.html#:~:text=Paint%20uses%20a%20canvas%2C%20a,creates%20an%20extremely%20large%20canvas',
    },
    {
        TOPICID: '90',
        DIGITALLINKID: '214',
        DIGITALLINKURL: 'https://www.wikihow.com/Use-Color-Replacement-in-MS-Paint',
    },
    {
        TOPICID: '90',
        DIGITALLINKID: '215',
        DIGITALLINKURL: 'http://in5stepstutorials.com/ms-paint/change-color-in-ms-paint.php',
    },
    {
        TOPICID: '90',
        DIGITALLINKID: '216',
        DIGITALLINKURL: 'https://www.wikihow.com/Use-Color-Replacement-in-MS-Paint',
    },
    { TOPICID: '91', DIGITALLINKID: '217', DIGITALLINKURL: 'https://www.youtube.com/watch?v=kJUOGvJVlNE' },
    {
        TOPICID: '92',
        DIGITALLINKID: '218',
        DIGITALLINKURL:
            "https://www.mediacollege.com/adobe/photoshop/tool/eraser.html#:~:text=Photoshop's%20eraser%20tool%20can%20be,if%20the%20layer%20is%20locked",
    },
    { TOPICID: '92', DIGITALLINKID: '219', DIGITALLINKURL: 'https://www.youtube.com/watch?v=BgjxHJp_XYc' },
    { TOPICID: '93', DIGITALLINKID: '220', DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/painting-tool' },
    {
        TOPICID: '93',
        DIGITALLINKID: '221',
        DIGITALLINKURL:
            'https://www.dummies.com/software/adobe/photoshop/how-to-paint-with-the-brush-tool-in-photoshop-cs6/',
    },
    { TOPICID: '94', DIGITALLINKID: '222', DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/painting-tool' },
    {
        TOPICID: '94',
        DIGITALLINKID: '223',
        DIGITALLINKURL:
            'https://www.dummies.com/software/adobe/photoshop/what-you-can-do-with-the-pencil-tool-in-photoshop-cs6/',
    },
    {
        TOPICID: '95',
        DIGITALLINKID: '224',
        DIGITALLINKURL: 'https://www.microsoft.com/en-us/microsoft-365/blog/2013/11/06/whats-new-in-the-word-web-app/',
    },
    { TOPICID: '95', DIGITALLINKID: '225', DIGITALLINKURL: 'https://wordweb.info/more.html' },
    { TOPICID: '95', DIGITALLINKID: '226', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/WordWeb' },
    { TOPICID: '96', DIGITALLINKID: '227', DIGITALLINKURL: '' },
    {
        TOPICID: '97',
        DIGITALLINKID: '228',
        DIGITALLINKURL:
            'https://www.techrepublic.com/blog/microsoft-office/use-words-find-feature-to-highlight-all-occurrences-of-a-word-or-phrase/',
    },
    {
        TOPICID: '97',
        DIGITALLINKID: '229',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/apply-or-remove-highlighting-1747d808-6db7-4d49-86ac-1f0c3cc87e2e',
    },
    {
        TOPICID: '97',
        DIGITALLINKID: '230',
        DIGITALLINKURL: 'https://www.techrepublic.com/article/2-ways-to-quickly-highlight-recurring-text-in-word/',
    },
    {
        TOPICID: '98',
        DIGITALLINKID: '231',
        DIGITALLINKURL: 'https://kb.benchmarkemail.com/how-do-i-copy-and-paste-text-from-microsoft-word/',
    },
    {
        TOPICID: '98',
        DIGITALLINKID: '232',
        DIGITALLINKURL:
            'http://help.wps.com/articles/how-to-paste-text-without-formatting#:~:text=Step%201%3A%20Select%20the%20text,will%20be%20shown%20as%20below.&text=beside%20your%20pasted%20text',
    },
    {
        TOPICID: '98',
        DIGITALLINKID: '233',
        DIGITALLINKURL:
            'https://help.wps.com/articles/use-clipboard#:~:text=Step%201%3A%20Select%20the%20cell,%3A%20%5BCtrl%20%2BC%5D',
    },
    { TOPICID: '99', DIGITALLINKID: '234', DIGITALLINKURL: 'https://www.computerhope.com/jargon/m/menubar.htm' },
    {
        TOPICID: '99',
        DIGITALLINKID: '235',
        DIGITALLINKURL: 'https://www.urbaninsight.com/article/views-menu-tabs-quick-guide',
    },
    {
        TOPICID: '99',
        DIGITALLINKID: '236',
        DIGITALLINKURL:
            'https://olympus.sandhills.edu/english/wordguide/mswordtoolbars.html#:~:text=When%20you%20open%20MS%20Word%2C%20you%20will%20see%20a%20blank,to%20display%20the%20particular%20menu',
    },
    { TOPICID: '100', DIGITALLINKID: '237', DIGITALLINKURL: 'https://tallyfy.com/software-documentation-tools/' },
    {
        TOPICID: '100',
        DIGITALLINKID: '238',
        DIGITALLINKURL:
            'https://en.wikipedia.org/wiki/Software_documentation#:~:text=Software%20documentation%20is%20written%20text,to%20people%20in%20different%20roles',
    },
    {
        TOPICID: '100',
        DIGITALLINKID: '239',
        DIGITALLINKURL:
            'https://stepshot.net/21-software-documentation-tools-for-every-stage-of-project-implementation/',
    },
    {
        TOPICID: '101',
        DIGITALLINKID: '240',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2007/creating-a-new-document/1/',
    },
    { TOPICID: '101', DIGITALLINKID: '241', DIGITALLINKURL: 'https://www.youtube.com/watch?v=rjbUiqVxZzM' },
    {
        TOPICID: '101',
        DIGITALLINKID: '242',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/create-a-document-28508ada-9a3c-4333-a17b-cb29723eb64c',
    },
    {
        TOPICID: '102',
        DIGITALLINKID: '243',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/video-save-a-word-document-fb0f9081-7c62-4fbf-954d-81b9707c0678#:~:text=Click%20FILE%20%3E%20Save%2C%20pick%20or,tab%2C%20and%20then%20click%20Print',
    },
    { TOPICID: '102', DIGITALLINKID: '244', DIGITALLINKURL: 'https://www.youtube.com/watch?v=uSMqYj4if10' },
    { TOPICID: '102', DIGITALLINKID: '245', DIGITALLINKURL: 'https://www.wikihow.com/Save-a-Microsoft-Word-Document' },
    {
        TOPICID: '103',
        DIGITALLINKID: '246',
        DIGITALLINKURL: 'http://infobitt.blogspot.com/2010/06/open-save-save-document-automatically.html',
    },
    { TOPICID: '103', DIGITALLINKID: '247', DIGITALLINKURL: 'https://www.youtube.com/watch?v=g1L-WSm94lE' },
    { TOPICID: '103', DIGITALLINKID: '248', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001833.htm' },
    { TOPICID: '104', DIGITALLINKID: '249', DIGITALLINKURL: 'https://www.youtube.com/watch?v=8cZIRm3HWqg' },
    {
        TOPICID: '104',
        DIGITALLINKID: '250',
        DIGITALLINKURL:
            'https://www.acapturer.com/index.php/2018/04/28/exploring-the-home-tab-excel-for-absolute-beginners/',
    },
    { TOPICID: '104', DIGITALLINKID: '251', DIGITALLINKURL: 'http://www.addbalance.com/word/ribbonsHome.htm' },
    {
        TOPICID: '105',
        DIGITALLINKID: '252',
        DIGITALLINKURL: 'https://www.oreilly.com/library/view/word-2007-the/059652739X/ch04.html',
    },
    {
        TOPICID: '105',
        DIGITALLINKID: '253',
        DIGITALLINKURL: 'https://help.blocsapp.com/knowledge-base/basic-text-formatting/',
    },
    {
        TOPICID: '105',
        DIGITALLINKID: '254',
        DIGITALLINKURL: 'https://www.computerhope.com/jargon/f/formatting-toolbar.htm',
    },
    { TOPICID: '106', DIGITALLINKID: '255', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ViGf0RKbCyA' },
    {
        TOPICID: '106',
        DIGITALLINKID: '256',
        DIGITALLINKURL:
            'https://business.tutsplus.com/tutorials/quickly-format-basic-text-styles-microsoft-word--cms-34133',
    },
    {
        TOPICID: '106',
        DIGITALLINKID: '257',
        DIGITALLINKURL: 'https://www.oreilly.com/library/view/word-2007-the/059652739X/ch04.html',
    },
    { TOPICID: '107', DIGITALLINKID: '258', DIGITALLINKURL: 'https://www.youtube.com/watch?v=JtYfjYcl9i0' },
    {
        TOPICID: '107',
        DIGITALLINKID: '259',
        DIGITALLINKURL: 'https://rutgers.instructure.com/courses/35/pages/saving-assignment-documents',
    },
    { TOPICID: '107', DIGITALLINKID: '260', DIGITALLINKURL: 'https://www.computerhope.com/jargon/s/saveas.htm' },
    { TOPICID: '108', DIGITALLINKID: '261', DIGITALLINKURL: 'https://www.youtube.com/about/' },
    { TOPICID: '108', DIGITALLINKID: '262', DIGITALLINKURL: 'https://www.youtube.com/watch?v=mTerLFDGjnM' },
    { TOPICID: '108', DIGITALLINKID: '263', DIGITALLINKURL: 'https://techterms.com/definition/youtube' },
    { TOPICID: '109', DIGITALLINKID: '264', DIGITALLINKURL: '' },
    {
        TOPICID: '110',
        DIGITALLINKID: '265',
        DIGITALLINKURL: 'https://thenextweb.com/basics/2019/08/10/how-to-download-youtube-videos-to-watch-offline/',
    },
    { TOPICID: '110', DIGITALLINKID: '266', DIGITALLINKURL: 'https://www.youtube.com/watch?v=jQwrQmsxsk' },
    {
        TOPICID: '110',
        DIGITALLINKID: '267',
        DIGITALLINKURL: 'https://support.google.com/googleplay/answer/2851696?co=GENIE.Platform%3DAndroid&hl=en-GB',
    },
    { TOPICID: '111', DIGITALLINKID: '268', DIGITALLINKURL: 'https://www.youtube.com/watch?v=6o7qODwjEz8' },
    { TOPICID: '111', DIGITALLINKID: '269', DIGITALLINKURL: 'https://www.youtube.com/watch?v=W5X9XLkdntc' },
    { TOPICID: '111', DIGITALLINKID: '270', DIGITALLINKURL: 'https://buffer.com/library/create-a-youtube-channel/' },
    { TOPICID: '112', DIGITALLINKID: '271', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Hlxqk0iHp5w' },
    { TOPICID: '112', DIGITALLINKID: '272', DIGITALLINKURL: 'https://www.youtube.com/watch?v=VtF2AgFSLAw' },
    {
        TOPICID: '112',
        DIGITALLINKID: '273',
        DIGITALLINKURL:
            'https://www.howtogeek.com/397470/best-sites-for-sharing-videos/#:~:text=YouTube%3A%20The%20Best%20Option%20for%20Most%20People&text=On%20the%20top%20right%20of,files%20in%20the%20upload%20area',
    },
    { TOPICID: '113', DIGITALLINKID: '274', DIGITALLINKURL: 'https://www.youtube.com/watch?v=PafCMUVH_OA' },
    { TOPICID: '113', DIGITALLINKID: '275', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch001833.htm' },
    { TOPICID: '113', DIGITALLINKID: '276', DIGITALLINKURL: 'https://www.youtube.com/watch?v=uAWWYIttq_E' },
    { TOPICID: '114', DIGITALLINKID: '277', DIGITALLINKURL: 'https://www.youtube.com/watch?v=PafCMUVH_OA' },
    { TOPICID: '114', DIGITALLINKID: '278', DIGITALLINKURL: 'https://www.youtube.com/watch?v=uAWWYIttq_E' },
    {
        TOPICID: '114',
        DIGITALLINKID: '279',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/save-back-up-and-recover-a-file-in-microsoft-office-a7f0a209-ad22-4212-bb53-6cd8e801a6fb',
    },
    { TOPICID: '115', DIGITALLINKID: '280', DIGITALLINKURL: '' },
    { TOPICID: '116', DIGITALLINKID: '281', DIGITALLINKURL: 'http://www.addbalance.com/word/ribbonsHome.htm' },
    { TOPICID: '116', DIGITALLINKID: '282', DIGITALLINKURL: 'https://www.computerhope.com/jargon/h/home-tab.htm' },
    {
        TOPICID: '116',
        DIGITALLINKID: '283',
        DIGITALLINKURL: 'http://web.douglas.k12.ga.us/web/intech/training/Office2007/Word2007GettingStarted.pdf',
    },
    { TOPICID: '117', DIGITALLINKID: '284', DIGITALLINKURL: 'https://www.learningcomputer.com/ms_word_insert_tab/' },
    { TOPICID: '117', DIGITALLINKID: '285', DIGITALLINKURL: 'https://www.youtube.com/watch?v=oDEGiwhC_nU' },
    {
        TOPICID: '117',
        DIGITALLINKID: '286',
        DIGITALLINKURL: 'http://informationtechnology.sienaheights.edu/uploads/6/5/6/7/6567343/inserttab.pdf',
    },
    {
        TOPICID: '118',
        DIGITALLINKID: '287',
        DIGITALLINKURL:
            'https://www.oreilly.com/library/view/word-2007-the/059652739X/ch04.html#:~:text=Character%20formatting%20includes%20selecting%20a,described%20in%20the%20previous%20chapter',
    },
    {
        TOPICID: '118',
        DIGITALLINKID: '288',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2016/formatting-text/1/',
    },
    {
        TOPICID: '118',
        DIGITALLINKID: '289',
        DIGITALLINKURL: 'https://www.computerhope.com/jargon/f/formatted-text.htm',
    },
    {
        TOPICID: '119',
        DIGITALLINKID: '290',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2016/applying-and-modifying-styles/1/',
    },
    {
        TOPICID: '119',
        DIGITALLINKID: '291',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2010/styles-and-themes/1/',
    },
    { TOPICID: '119', DIGITALLINKID: '292', DIGITALLINKURL: 'https://www.youtube.com/watch?v=w2lES-5Ynbk' },
    {
        TOPICID: '119',
        DIGITALLINKID: '293',
        DIGITALLINKURL:
            'https://www.uis.edu/informationtechnologyservices/wp-content/uploads/sites/106/2013/04/Word2007Styles.pdf',
    },
    { TOPICID: '120', DIGITALLINKID: '294', DIGITALLINKURL: 'https://www.youtube.com/watch?v=zh6y3sGwImU' },
    { TOPICID: '120', DIGITALLINKID: '295', DIGITALLINKURL: 'https://www.youtube.com/watch?v=thTfOZSQf48' },
    { TOPICID: '120', DIGITALLINKID: '296', DIGITALLINKURL: 'https://www.youtube.com/watch?v=OK8gDb4D1gM' },
    { TOPICID: '121', DIGITALLINKID: '297', DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2016/page-layout/1/' },
    {
        TOPICID: '121',
        DIGITALLINKID: '298',
        DIGITALLINKURL: 'https://www.youtube.com/watch?v=jgNpoksYOLE&feature=youtu.be',
    },
    {
        TOPICID: '121',
        DIGITALLINKID: '299',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/change-margins-da21a474-99d8-4e54-b12d-a8a14ea7ce02#ID0EAABAAA=Office_2010',
    },
    {
        TOPICID: '121',
        DIGITALLINKID: '300',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/change-page-orientation-to-landscape-or-portrait-9b5ac1af-9998-4a37-962b-a82b689572a9',
    },
    {
        TOPICID: '122',
        DIGITALLINKID: '301',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/word2016/getting-started-with-word/1/',
    },
    { TOPICID: '122', DIGITALLINKID: '302', DIGITALLINKURL: 'http://www.addbalance.com/word/ribbonsHome.htm' },
    {
        TOPICID: '122',
        DIGITALLINKID: '303',
        DIGITALLINKURL: 'https://www.youtube.com/watch?v=j-ZAVHk5SaU&feature=youtu.be',
    },
    { TOPICID: '123', DIGITALLINKID: '304', DIGITALLINKURL: 'https://whatis.techtarget.com/definition/spreadshee' },
    { TOPICID: '123', DIGITALLINKID: '305', DIGITALLINKURL: 'https://www.techopedia.com/definition/4426/spreadsheet' },
    {
        TOPICID: '123',
        DIGITALLINKID: '306',
        DIGITALLINKURL: 'https://www.perfectxl.com/about-spreadsheets/excel-glossary/what-is-spreadsheet/',
    },
    {
        TOPICID: '124',
        DIGITALLINKID: '307',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/excelxp/identifying-basic-parts-of-the-excel-window/1/',
    },
    { TOPICID: '124', DIGITALLINKID: '308', DIGITALLINKURL: 'https://www.youtube.com/watch?v=xLTh0qABJ2c' },
    {
        TOPICID: '124',
        DIGITALLINKID: '309',
        DIGITALLINKURL: 'http://mantemelody.weebly.com/basic-parts-of-microsoft-excelsummary-outline.html#/',
    },
    { TOPICID: '125', DIGITALLINKID: '310', DIGITALLINKURL: 'https://www.youtube.com/watch?v=M6yGaPvJcuA' },
    {
        TOPICID: '125',
        DIGITALLINKID: '311',
        DIGITALLINKURL: 'https://www.oreilly.com/library/view/excel-2013-the/9781449359492/ch01.html',
    },
    { TOPICID: '125', DIGITALLINKID: '312', DIGITALLINKURL: 'https://www.youtube.com/watch?v=NzwQ_BbD2G4' },
    { TOPICID: '126', DIGITALLINKID: '313', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ZNoORxP7VzA&t=88s' },
    { TOPICID: '126', DIGITALLINKID: '314', DIGITALLINKURL: 'https://www.youtube.com/watch?v=QN8KJmRLilo' },
    { TOPICID: '126', DIGITALLINKID: '315', DIGITALLINKURL: 'https://www.youtube.com/watch?v=S3KUzfCWl78&t=60s' },
    { TOPICID: '127', DIGITALLINKID: '316', DIGITALLINKURL: 'https://www.youtube.com/watch?v=llkP9DxRAPI' },
    { TOPICID: '127', DIGITALLINKID: '317', DIGITALLINKURL: 'https://www.youtube.com/watch?v=DsZ7lsTaAYo' },
    { TOPICID: '127', DIGITALLINKID: '318', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Jl0Qk63z2ZY' },
    { TOPICID: '128', DIGITALLINKID: '319', DIGITALLINKURL: 'https://www.computerhope.com/jargon/s/slidesho.htm' },
    {
        TOPICID: '128',
        DIGITALLINKID: '320',
        DIGITALLINKURL: 'https://www.techopedia.com/definition/10809/powerpoint-slideshow-ppt',
    },
    { TOPICID: '128', DIGITALLINKID: '321', DIGITALLINKURL: 'https://www.pcmag.com/encyclopedia/term/slide-show' },
    { TOPICID: '129', DIGITALLINKID: '322', DIGITALLINKURL: 'https://www.youtube.com/watch?v=sbeyPahs-ng' },
    { TOPICID: '129', DIGITALLINKID: '323', DIGITALLINKURL: 'https://www.youtube.com/watch?v=bE42HTMObtA' },
    { TOPICID: '129', DIGITALLINKID: '324', DIGITALLINKURL: 'https://www.youtube.com/watch?v=bqX8xska08Y' },
    { TOPICID: '130', DIGITALLINKID: '325', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Ey1atEavZ-M' },
    {
        TOPICID: '130',
        DIGITALLINKID: '326',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/googleslides/adding-transitions-and-animations/1/',
    },
    {
        TOPICID: '130',
        DIGITALLINKID: '327',
        DIGITALLINKURL:
            'https://slidesgo.com/slidesgo-school/powerpoint-tutorials/how-to-add-animations-and-transitions-in-powerpoint',
    },
    {
        TOPICID: '131',
        DIGITALLINKID: '328',
        DIGITALLINKURL: 'https://www.windowscentral.com/how-create-animations-powerpoint-2016',
    },
    {
        TOPICID: '131',
        DIGITALLINKID: '329',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/powerpoint2016/animating-text-and-objects/1/',
    },
    { TOPICID: '131', DIGITALLINKID: '330', DIGITALLINKURL: 'https://www.youtube.com/watch?v=KTP5rsILggM' },
    {
        TOPICID: '132',
        DIGITALLINKID: '331',
        DIGITALLINKURL:
            'https://www.ncsl.org/legislators-staff/legislative-staff/legislative-staff-coordinating-committee/tips-for-making-effective-powerpoint-presentations.aspx',
    },
    {
        TOPICID: '132',
        DIGITALLINKID: '332',
        DIGITALLINKURL: 'https://elearningbrothers.com/blog/6-tips-creating-effective-powerpoint-presentation/',
    },
    { TOPICID: '132', DIGITALLINKID: '333', DIGITALLINKURL: 'http://www.garrreynolds.com/preso-tips/design/' },
    {
        TOPICID: '133',
        DIGITALLINKID: '334',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/create-a-self-running-presentation-57fc41ae-f36a-4fb5-94a3-52d5bc466037',
    },
    { TOPICID: '133', DIGITALLINKID: '335', DIGITALLINKURL: 'https://www.youtube.com/watch?v=7-2oM3AGHQM&t=72s' },
    {
        TOPICID: '133',
        DIGITALLINKID: '336',
        DIGITALLINKURL: 'https://www.dummies.com/software/for-seniors-how-to-run-a-powerpoint-presentation/',
    },
    {
        TOPICID: '134',
        DIGITALLINKID: '337',
        DIGITALLINKURL: 'https://www.lifewire.com/what-is-desktop-publishing-software-1078923',
    },
    {
        TOPICID: '134',
        DIGITALLINKID: '338',
        DIGITALLINKURL: 'https://www.techopedia.com/definition/13140/microsoft-publisher',
    },
    {
        TOPICID: '134',
        DIGITALLINKID: '339',
        DIGITALLINKURL: 'https://www.cbronline.com/what-is/what-is-microsoft-publisher-4969080/',
    },
    {
        TOPICID: '135',
        DIGITALLINKID: '340',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/publisher2010/creating-a-new-publication/1/',
    },
    { TOPICID: '135', DIGITALLINKID: '341', DIGITALLINKURL: 'https://youtu.be/OXMz4yJm1e4' },
    { TOPICID: '135', DIGITALLINKID: '342', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Cqo0PVhBFYI' },
    {
        TOPICID: '136',
        DIGITALLINKID: '343',
        DIGITALLINKURL: 'https://campusguides.lib.utah.edu/c.php?g=160396&p=1456238',
    },
    {
        TOPICID: '136',
        DIGITALLINKID: '344',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/publisher2010/getting-to-know-publisher-2010/1/',
    },
    {
        TOPICID: '136',
        DIGITALLINKID: '345',
        DIGITALLINKURL: 'https://www.swiftpublisher.com/useful-articles/desktop-publishing-basics',
    },
    {
        TOPICID: '137',
        DIGITALLINKID: '346',
        DIGITALLINKURL: 'https://smallbusiness.chron.com/insert-footnote-publisher-72913.html',
    },
    {
        TOPICID: '137',
        DIGITALLINKID: '347',
        DIGITALLINKURL: 'https://www.shutterstock.com/blog/tips-creating-ebooks-epubs-indesign',
    },
    {
        TOPICID: '137',
        DIGITALLINKID: '348',
        DIGITALLINKURL: 'https://campusguides.lib.utah.edu/c.php?g=160396&p=1049644',
    },
    {
        TOPICID: '138',
        DIGITALLINKID: '349',
        DIGITALLINKURL: 'https://www.printingforless.com/Microsoft-Publisher-Tips.html',
    },
    {
        TOPICID: '138',
        DIGITALLINKID: '350',
        DIGITALLINKURL: 'https://www.shutterstock.com/blog/tips-creating-ebooks-epubs-indesign',
    },
    {
        TOPICID: '138',
        DIGITALLINKID: '351',
        DIGITALLINKURL: 'https://campusguides.lib.utah.edu/c.php?g=160396&p=1049644',
    },
    { TOPICID: '139', DIGITALLINKID: '352', DIGITALLINKURL: 'https://nwtc.libanswers.com/faq/212721' },
    { TOPICID: '139', DIGITALLINKID: '353', DIGITALLINKURL: 'https://www.youtube.com/watch?v=lTEnBOE17yg' },
    { TOPICID: '139', DIGITALLINKID: '354', DIGITALLINKURL: 'https://www.youtube.com/watch?v=l19C6PhAw7U' },
    {
        TOPICID: '140',
        DIGITALLINKID: '355',
        DIGITALLINKURL:
            'https://www.webopedia.com/TERM/P/photo-editing-software.html#:~:text=Photo%20editing%20software%20is%20used%20to%20manipulate%20or%20enhance%20digital%20images.&text=Most%20often%20the%20software%20is,program%20to%20create%20new%20images',
    },
    { TOPICID: '140', DIGITALLINKID: '356', DIGITALLINKURL: 'https://www.pixpa.com/blog/drawing-apps' },
    { TOPICID: '140', DIGITALLINKID: '357', DIGITALLINKURL: 'https://www.pcmag.com/encyclopedia/term/photo-editor' },
    {
        TOPICID: '141',
        DIGITALLINKID: '358',
        DIGITALLINKURL: 'https://learn.corel.com/tutorials/creating-a-new-canvas/',
    },
    { TOPICID: '141', DIGITALLINKID: '359', DIGITALLINKURL: 'https://www.youtube.com/watch?v=wlU3M0nVKxc' },
    {
        TOPICID: '141',
        DIGITALLINKID: '360',
        DIGITALLINKURL: 'https://lib2.colostate.edu/images/cat/Photoshop_CS6_Basics.pdf',
    },
    {
        TOPICID: '142',
        DIGITALLINKID: '361',
        DIGITALLINKURL: 'https://www.mediacollege.com/adobe/photoshop/tool/painting.html',
    },
    {
        TOPICID: '142',
        DIGITALLINKID: '362',
        DIGITALLINKURL:
            'https://community.adobe.com/t5/illustrator/pencil-tool-vs-paint-brush-tool-illustrator-cc-2018/td-p/10060513?page=1',
    },
    { TOPICID: '142', DIGITALLINKID: '363', DIGITALLINKURL: 'https://www.youtube.com/watch?v=SmPUgigTMJY' },
    { TOPICID: '143', DIGITALLINKID: '364', DIGITALLINKURL: 'https://neilpatel.com/blog/image-editing-tools/' },
    { TOPICID: '143', DIGITALLINKID: '365', DIGITALLINKURL: 'https://www.youtube.com/watch?v=8533md5_Nzg' },
    { TOPICID: '144', DIGITALLINKID: '366', DIGITALLINKURL: 'https://www.youtube.com/watch?v=u3t009ULCY0' },
    {
        TOPICID: '144',
        DIGITALLINKID: '367',
        DIGITALLINKURL: 'https://helpx.adobe.com/illustrator/using/gradients.html',
    },
    { TOPICID: '144', DIGITALLINKID: '368', DIGITALLINKURL: 'https://www.youtube.com/watch?v=XRi0B4vb2nY' },
    {
        TOPICID: '145',
        DIGITALLINKID: '369',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/kb/create-collages-montages-photoshop-photoshop.html',
    },
    { TOPICID: '145', DIGITALLINKID: '370', DIGITALLINKURL: 'https://www.youtube.com/watch?v=bfUz7JjZkV0' },
    { TOPICID: '145', DIGITALLINKID: '371', DIGITALLINKURL: 'https://www.youtube.com/watch?v=cHMt9w27tn4' },
    {
        TOPICID: '146',
        DIGITALLINKID: '372',
        DIGITALLINKURL:
            'https://www.softwaretestinghelp.com/flowchart-software/#:~:text=Flowchart%20maker%20software%20is%20an,to%20collaborate%20on%20the%20drawings',
    },
    {
        TOPICID: '147',
        DIGITALLINKID: '373',
        DIGITALLINKURL:
            'https://www.tutorialspoint.com/programming_methodologies/programming_methodologies_flowchart_elements.htm',
    },
    {
        TOPICID: '147',
        DIGITALLINKID: '374',
        DIGITALLINKURL: 'https://www.breezetree.com/articles/excel-flowchart-shapes/',
    },
    { TOPICID: '147', DIGITALLINKID: '375', DIGITALLINKURL: 'https://www.gliffy.com/blog/guide-to-flowchart-symbols' },
    { TOPICID: '148', DIGITALLINKID: '376', DIGITALLINKURL: 'https://www.programiz.com/article/flowchart-programming' },
    { TOPICID: '148', DIGITALLINKID: '377', DIGITALLINKURL: 'https://youtu.be/8r0tTbbThuk' },
    {
        TOPICID: '148',
        DIGITALLINKID: '378',
        DIGITALLINKURL:
            'https://medium.com/@warren2lynch/a-comprehensive-guide-for-flowchart-over-50-examples-785d6dfdc380',
    },
    { TOPICID: '149', DIGITALLINKID: '379', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ruRcHcTITuI' },
    { TOPICID: '149', DIGITALLINKID: '380', DIGITALLINKURL: 'https://www.youtube.com/watch?v=64MaQYyAN2w' },
    { TOPICID: '149', DIGITALLINKID: '381', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ppUHtCCxPfc' },
    { TOPICID: '150', DIGITALLINKID: '382', DIGITALLINKURL: 'https://www.smartdraw.com/flowchart/flowchart-tips.htm' },
    {
        TOPICID: '150',
        DIGITALLINKID: '383',
        DIGITALLINKURL: 'https://creately.com/diagram-type/article/6-useful-tips-drawing-flowcharts',
    },
    { TOPICID: '151', DIGITALLINKID: '384', DIGITALLINKURL: 'https://www.youtube.com/watch?v=jgNpoksYOLE' },
    {
        TOPICID: '151',
        DIGITALLINKID: '385',
        DIGITALLINKURL: 'https://www.youtube.com/watch?time_continue=16&v=viOR45_Sdk4&feature=emb_logo',
    },
    { TOPICID: '151', DIGITALLINKID: '386', DIGITALLINKURL: 'https://www.youtube.com/watch?v=wIxUv7AcdjI' },
    { TOPICID: '152', DIGITALLINKID: '387', DIGITALLINKURL: 'https://www.youtube.com/watch?v=TuxxRlhU1Os' },
    { TOPICID: '152', DIGITALLINKID: '388', DIGITALLINKURL: 'https://www.youtube.com/watch?v=avFNSAaQEVQ' },
    {
        TOPICID: '153',
        DIGITALLINKID: '389',
        DIGITALLINKURL:
            'https://www.ibm.com/support/knowledgecenter/en/SSZJPZ_11.3.0/com.ibm.swg.im.iis.ds.parjob.dev.doc/topics/r_deeref_Format_Tab_stage_editors_outputs.html',
    },
    {
        TOPICID: '153',
        DIGITALLINKID: '390',
        DIGITALLINKURL:
            'https://fmhelp.filemaker.com/help/12/fmp/en/html/create_layout.9.42.html#:~:text=Double%2Dclick%20a%20tab%20control,Tab%20Justification%20drop%2Ddown%20list.&text=Select%20the%20tab%20control%20or%20a%20tab%20panel.,-%E2%80%A2&text=in%20the%20layout%20bar%2C%20then%20click%20Appearance.,-Choose%20options%20in',
    },
    {
        TOPICID: '154',
        DIGITALLINKID: '391',
        DIGITALLINKURL: 'http://www.doe.virginia.gov/home/a11y/word/obj-format.shtml',
    },
    { TOPICID: '154', DIGITALLINKID: '392', DIGITALLINKURL: 'https://www.w3.org/TR/2001/REC-xsl-20011015/slice6.html' },
    {
        TOPICID: '155',
        DIGITALLINKID: '393',
        DIGITALLINKURL:
            'https://thevisualcommunicationguy.com/2016/03/23/make-your-boring-documents-look-professional-in-5-easy-steps/',
    },
    { TOPICID: '155', DIGITALLINKID: '394', DIGITALLINKURL: 'https://www.tasprint.com.au/blog/wordtips.html' },
    { TOPICID: '155', DIGITALLINKID: '395', DIGITALLINKURL: 'https://www.youtube.com/watch?v=mqbU9KjgYIY' },
    { TOPICID: '156', DIGITALLINKID: '396', DIGITALLINKURL: 'https://www.learningcomputer.com/ms_word_review_tab/' },
    {
        TOPICID: '156',
        DIGITALLINKID: '397',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-gb/office/view-tab-08da4abb-5359-4579-97e9-6014f86dd7b7#:~:text=The%20View%20tab%20enables%20you,Publisher%20windows%20you%20have%20open./',
    },
    { TOPICID: '157', DIGITALLINKID: '398', DIGITALLINKURL: 'https://www.youtube.com/watch?v=quXzr_CtsYs' },
    { TOPICID: '157', DIGITALLINKID: '399', DIGITALLINKURL: 'https://www.learningcomputer.com/ms_word_review_tab/' },
    { TOPICID: '158', DIGITALLINKID: '400', DIGITALLINKURL: 'https://www.youtube.com/watch?v=FGh8225SMRs' },
    {
        TOPICID: '158',
        DIGITALLINKID: '401',
        DIGITALLINKURL:
            'https://www.keystonelearning.com/resources/articles/word_2007_using_the_view_tab#:~:text=The%20first%20group%20on%20the,Outline%20and%20Full%20Screen%20views',
    },
    { TOPICID: '158', DIGITALLINKID: '402', DIGITALLINKURL: 'https://www.learningcomputer.com/ms_word_review_tab/' },
    { TOPICID: '159', DIGITALLINKID: '403', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Fvrtt0h84Mg' },
    {
        TOPICID: '159',
        DIGITALLINKID: '404',
        DIGITALLINKURL: 'https://mocdstudio.com/5-advanced-microsoft-word-features-every-word-user-needs/',
    },
    {
        TOPICID: '159',
        DIGITALLINKID: '405',
        DIGITALLINKURL: 'https://www.makeuseof.com/tag/10-hidden-microsoft-word-features-will-make-life-easier/',
    },
    {
        TOPICID: '160',
        DIGITALLINKID: '406',
        DIGITALLINKURL:
            'https://www.howtogeek.com/357623/microsoft-word-keyboard-shortcuts-that-make-your-life-easier/',
    },
    {
        TOPICID: '160',
        DIGITALLINKID: '407',
        DIGITALLINKURL:
            'https://support.microsoft.com/en-us/office/keyboard-shortcuts-in-word-95ef89dd-7142-4b50-afb2-f762f663ceb2',
    },
    { TOPICID: '161', DIGITALLINKID: '408', DIGITALLINKURL: '' },
    {
        TOPICID: '162',
        DIGITALLINKID: '409',
        DIGITALLINKURL:
            'https://business.tutsplus.com/tutorials/how-to-use-excel-math-formulas--cms-27554#:~:text=Each%20Formula%20in%20Excel%20Starts%20with%20%22%3D%22&text=This%20starts%20a%20formula.,enter%20to%20return%20the%20result',
    },
    {
        TOPICID: '162',
        DIGITALLINKID: '410',
        DIGITALLINKURL: 'https://www.w3resource.com/excel/excel-mathematical-function.php',
    },
    {
        TOPICID: '163',
        DIGITALLINKID: '411',
        DIGITALLINKURL: 'https://www.coursera.org/lecture/excel-data-analysis/introduction-to-charts-in-excel-cGcB0',
    },
    {
        TOPICID: '163',
        DIGITALLINKID: '412',
        DIGITALLINKURL: 'https://www.tutorialspoint.com/excel_charts/excel_charts_introduction.htm',
    },
    {
        TOPICID: '164',
        DIGITALLINKID: '413',
        DIGITALLINKURL:
            'https://corporatefinanceinstitute.com/resources/excel/study/advanced-excel-formulas-must-know/',
    },
    {
        TOPICID: '164',
        DIGITALLINKID: '414',
        DIGITALLINKURL: 'https://bytescout.com/blog/learn-excel-features-functions.html',
    },
    { TOPICID: '165', DIGITALLINKID: '415', DIGITALLINKURL: 'https://www.yourdictionary.com/presentation-software' },
    {
        TOPICID: '165',
        DIGITALLINKID: '416',
        DIGITALLINKURL:
            'https://simple.wikipedia.org/wiki/Presentation_software#:~:text=A%20presentation%20software%20is%20a,system%20to%20display%20the%20content',
    },
    {
        TOPICID: '166',
        DIGITALLINKID: '417',
        DIGITALLINKURL:
            'http://csc.columbusstate.edu/yang/Teaching/Spring%202014/1105%20PPT/Chapter%203-Basic%20Application%20Software.pdf',
    },
    {
        TOPICID: '167',
        DIGITALLINKID: '418',
        DIGITALLINKURL: 'https://graphicmama.com/blog/7-popular-software-presentations/',
    },
    {
        TOPICID: '168',
        DIGITALLINKID: '419',
        DIGITALLINKURL: 'https://www.lifewire.com/what-is-desktop-publishing-software-1078923',
    },
    {
        TOPICID: '168',
        DIGITALLINKID: '420',
        DIGITALLINKURL:
            'https://medium.com/@james01/what-is-desktop-publishing-software-4b17f582872f#:~:text=Designers%20and%20editors%20work%20together,with%20other%20printers%20and%20desktops',
    },
    {
        TOPICID: '168',
        DIGITALLINKID: '421',
        DIGITALLINKURL: 'https://asianabsolute.co.uk/blog/2017/09/12/what-is-desktop-publishing-software-used-for/',
    },
    { TOPICID: '169', DIGITALLINKID: '422', DIGITALLINKURL: '' },
    {
        TOPICID: '170',
        DIGITALLINKID: '423',
        DIGITALLINKURL: 'https://www.pcworld.com/article/3200754/word-2016-desktop-publishing-tools.html',
    },
    { TOPICID: '171', DIGITALLINKID: '424', DIGITALLINKURL: '' },
    { TOPICID: '172', DIGITALLINKID: '425', DIGITALLINKURL: '' },
    {
        TOPICID: '173',
        DIGITALLINKID: '426',
        DIGITALLINKURL: 'https://sea.pcmag.com/photo-editing/13168/the-best-photo-editing-software-for-2020',
    },
    {
        TOPICID: '173',
        DIGITALLINKID: '427',
        DIGITALLINKURL: 'https://www.digitalcameraworld.com/buying-guides/the-best-photo-editing-software',
    },
    { TOPICID: '173', DIGITALLINKID: '428', DIGITALLINKURL: 'https://www.techradar.com/news/best-photo-editor' },
    { TOPICID: '174', DIGITALLINKID: '429', DIGITALLINKURL: 'https://neilpatel.com/blog/image-editing-tools/' },
    {
        TOPICID: '175',
        DIGITALLINKID: '430',
        DIGITALLINKURL: 'https://blog.hubspot.com/marketing/photoshop-keyboard-shortcuts-list',
    },
    { TOPICID: '176', DIGITALLINKID: '431', DIGITALLINKURL: 'https://www.befunky.com/features/collage-maker/' },
    { TOPICID: '177', DIGITALLINKID: '432', DIGITALLINKURL: 'https://blog.hootsuite.com/how-to-make-gif/' },
    {
        TOPICID: '177',
        DIGITALLINKID: '433',
        DIGITALLINKURL: 'https://www.digitaltrends.com/social-media/how-to-make-a-gif/',
    },
    {
        TOPICID: '178',
        DIGITALLINKID: '434',
        DIGITALLINKURL: 'https://www.wordstream.com/blog/ws/2017/12/15/best-video-editing-software-for-beginners',
    },
    {
        TOPICID: '178',
        DIGITALLINKID: '435',
        DIGITALLINKURL: 'https://www.creativebloq.com/features/best-video-editing-software-for-designers',
    },
    {
        TOPICID: '178',
        DIGITALLINKID: '436',
        DIGITALLINKURL: 'https://www.tomsguide.com/us/best-video-editing-software,review-2167.html',
    },
    { TOPICID: '179', DIGITALLINKID: '437', DIGITALLINKURL: '' },
    {
        TOPICID: '180',
        DIGITALLINKID: '438',
        DIGITALLINKURL: 'https://superuser.com/questions/351344/how-to-add-elements-over-video',
    },
    { TOPICID: '181', DIGITALLINKID: '439', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Dak0bMKEbYw' },
    { TOPICID: '181', DIGITALLINKID: '440', DIGITALLINKURL: 'https://www.youtube.com/watch?v=9_3lJ2QhDf' },
    { TOPICID: '182', DIGITALLINKID: '441', DIGITALLINKURL: 'https://www.youtube.com/watch?v=U643hxryzDs&t=46s' },
    { TOPICID: '182', DIGITALLINKID: '442', DIGITALLINKURL: 'https://www.computerhope.com/issues/ch000829.htm' },
    { TOPICID: '183', DIGITALLINKID: '443', DIGITALLINKURL: 'https://www.youtube.com/watch?v=TTKpNk3uHos&t=7s' },
    { TOPICID: '183', DIGITALLINKID: '444', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Cdp27UIcf1I&t=6s' },
    { TOPICID: '184', DIGITALLINKID: '445', DIGITALLINKURL: 'https://www.youtube.com/watch?v=B2ULhWSDExE' },
    { TOPICID: '184', DIGITALLINKID: '446', DIGITALLINKURL: 'https://www.youtube.com/watch?v=6sTlVB2HLZ4' },
    { TOPICID: '185', DIGITALLINKID: '447', DIGITALLINKURL: 'https://www.youtube.com/watch?v=wUotChm6AAA' },
    {
        TOPICID: '186',
        DIGITALLINKID: '448',
        DIGITALLINKURL: 'https://www.edrawsoft.com/template-daily-life-flowchart.htmI',
    },
    { TOPICID: '187', DIGITALLINKID: '449', DIGITALLINKURL: 'https://www.youtube.com/watch?v=dcB5VU93AII' },
    { TOPICID: '187', DIGITALLINKID: '450', DIGITALLINKURL: 'https://www.youtube.com/watch?v=o_0c0-5RFXg' },
    { TOPICID: '187', DIGITALLINKID: '451', DIGITALLINKURL: 'https://www.youtube.com/watch?v=njJ8FQXZmcE' },
    { TOPICID: '188', DIGITALLINKID: '452', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Fza_ykzypGU' },
    { TOPICID: '188', DIGITALLINKID: '453', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Mcupbk6mobQ' },
    { TOPICID: '189', DIGITALLINKID: '454', DIGITALLINKURL: 'https://www.youtube.com/watch?v=cYWHqha2wfk' },
    { TOPICID: '189', DIGITALLINKID: '455', DIGITALLINKURL: 'https://www.youtube.com/watch?v=rIJUFUk38Z0' },
    { TOPICID: '190', DIGITALLINKID: '456', DIGITALLINKURL: 'https://www.youtube.com/watch?v=aXMRE2CjGZc' },
    { TOPICID: '191', DIGITALLINKID: '457', DIGITALLINKURL: 'https://www.youtube.com/watch?v=xmabFJUKMdg' },
    { TOPICID: '191', DIGITALLINKID: '458', DIGITALLINKURL: 'https://www.youtube.com/watch?v=co4rLn9N8OU' },
    { TOPICID: '191', DIGITALLINKID: '459', DIGITALLINKURL: 'https://www.youtube.com/watch?v=37R6RSJgRn8' },
    { TOPICID: '192', DIGITALLINKID: '460', DIGITALLINKURL: 'https://www.youtube.com/watch?v=yLDrvvcZB5k' },
    { TOPICID: '192', DIGITALLINKID: '461', DIGITALLINKURL: 'https://www.youtube.com/watch?v=WKCS5oX9Ab4' },
    { TOPICID: '193', DIGITALLINKID: '462', DIGITALLINKURL: 'https://www.youtube.com/watch?v=XY9gERBaQjU' },
    { TOPICID: '193', DIGITALLINKID: '463', DIGITALLINKURL: 'https://www.youtube.com/watch?v=QSjmOGKguJ8' },
    {
        TOPICID: '194',
        DIGITALLINKID: '464',
        DIGITALLINKURL: 'https://www.youtube.com/watch?v=0Fi83BHQsMA&feature=share',
    },
    { TOPICID: '194', DIGITALLINKID: '465', DIGITALLINKURL: 'https://www.youtube.com/watch?v=63X4AJO-KDM' },
    { TOPICID: '195', DIGITALLINKID: '466', DIGITALLINKURL: 'https://www.youtube.com/watch?v=dAgfnK528RA' },
    { TOPICID: '195', DIGITALLINKID: '467', DIGITALLINKURL: 'https://www.youtube.com/watch?v=bLj1dB7gcvM' },
    { TOPICID: '195', DIGITALLINKID: '468', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ClYdw4d4OmA' },
    { TOPICID: '196', DIGITALLINKID: '469', DIGITALLINKURL: 'https://www.youtube.com/watch?v=AVYfyTvc9KY' },
    { TOPICID: '196', DIGITALLINKID: '470', DIGITALLINKURL: 'https://www.youtube.com/watch?v=B1ed-pfqdZg' },
    { TOPICID: '196', DIGITALLINKID: '471', DIGITALLINKURL: 'https://www.youtube.com/watch?v=vPFh7RhWkgQ' },
    {
        TOPICID: '197',
        DIGITALLINKID: '472',
        DIGITALLINKURL: 'https://www.instructables.com/id/How-to-Create-a-PowerPoint-Presentation/',
    },
    {
        TOPICID: '198',
        DIGITALLINKID: '473',
        DIGITALLINKURL: 'https://blog.wps.com/introduction-on-view-modes-in-wps-presentation/',
    },
    { TOPICID: '199', DIGITALLINKID: '474', DIGITALLINKURL: 'https://www.youtube.com/watch?v=QtV2wgSX0K0' },
    { TOPICID: '199', DIGITALLINKID: '475', DIGITALLINKURL: 'https://www.youtube.com/watch?v=PzHtZW5Z6XA' },
    { TOPICID: '199', DIGITALLINKID: '476', DIGITALLINKURL: 'https://www.youtube.com/watch?v=yh95yYeW1Vw' },
    { TOPICID: '200', DIGITALLINKID: '477', DIGITALLINKURL: 'https://www.youtube.com/watch?v=iFO3EiQbNJ8' },
    {
        TOPICID: '200',
        DIGITALLINKID: '478',
        DIGITALLINKURL: 'https://www.cablestogo.com/learning/connector-guides/digital-video#!hdmi',
    },
    {
        TOPICID: '200',
        DIGITALLINKID: '479',
        DIGITALLINKURL: 'https://hardsoft-support.kayako.com/article/66-computer-cable-types-and-descriptions',
    },
    { TOPICID: '201', DIGITALLINKID: '480', DIGITALLINKURL: 'https://www.youtube.com/watch?v=cVEemOmHw9Y' },
    { TOPICID: '201', DIGITALLINKID: '481', DIGITALLINKURL: 'https://www.youtube.com/watch?v=hs1HoLs4SD0' },
    { TOPICID: '201', DIGITALLINKID: '482', DIGITALLINKURL: 'https://www.youtube.com/watch?v=I8EvI1-DsKo' },
    { TOPICID: '202', DIGITALLINKID: '483', DIGITALLINKURL: 'https://www.youtube.com/watch?v=QNxaSct3UHs' },
    { TOPICID: '202', DIGITALLINKID: '484', DIGITALLINKURL: 'https://www.youtube.com/watch?v=aHNaGghGMJw' },
    { TOPICID: '203', DIGITALLINKID: '485', DIGITALLINKURL: 'https://www.youtube.com/watch?v=TMubSggUOVE' },
    { TOPICID: '203', DIGITALLINKID: '486', DIGITALLINKURL: 'https://www.youtube.com/watch?v=luydhVDno4I' },
    {
        TOPICID: '203',
        DIGITALLINKID: '487',
        DIGITALLINKURL: 'https://www.splashlearn.com/math-vocabulary/addition/arithmetic',
    },
    { TOPICID: '204', DIGITALLINKID: '488', DIGITALLINKURL: 'https://www.mathsisfun.com/operation-order-pemdas.html' },
    { TOPICID: '204', DIGITALLINKID: '489', DIGITALLINKURL: 'https://www.purplemath.com/modules/orderops.htm' },
    { TOPICID: '204', DIGITALLINKID: '490', DIGITALLINKURL: 'https://www.youtube.com/watch?v=saTBf8AO4Ok' },
    { TOPICID: '205', DIGITALLINKID: '491', DIGITALLINKURL: 'https://www.youtube.com/watch?v=DBccIAl0Axs' },
    { TOPICID: '205', DIGITALLINKID: '492', DIGITALLINKURL: 'https://www.youtube.com/watch?v=xc14gFFyiTw' },
    { TOPICID: '205', DIGITALLINKID: '493', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Hqoj-M4I6_k' },
    { TOPICID: '206', DIGITALLINKID: '494', DIGITALLINKURL: 'https://www.youtube.com/watch?v=J-PaQymHkhg' },
    { TOPICID: '206', DIGITALLINKID: '495', DIGITALLINKURL: 'https://www.youtube.com/watch?v=3PwVWX28dEE' },
    { TOPICID: '206', DIGITALLINKID: '496', DIGITALLINKURL: 'https://www.youtube.com/watch?v=3o11OlLgYDo' },
    { TOPICID: '207', DIGITALLINKID: '497', DIGITALLINKURL: 'https://www.youtube.com/watch?v=X7O6WQl62Ks' },
    { TOPICID: '207', DIGITALLINKID: '498', DIGITALLINKURL: 'https://www.youtube.com/watch?v=_Wu7jYTr1Pk' },
    { TOPICID: '207', DIGITALLINKID: '499', DIGITALLINKURL: 'https://www.youtube.com/watch?v=TfkNkrKMF5c' },
    { TOPICID: '208', DIGITALLINKID: '500', DIGITALLINKURL: 'https://www.youtube.com/watch?v=nuNwAu74xwY' },
    { TOPICID: '208', DIGITALLINKID: '501', DIGITALLINKURL: 'https://www.youtube.com/watch?v=dcWitKdnBiA' },
    { TOPICID: '208', DIGITALLINKID: '502', DIGITALLINKURL: 'https://www.youtube.com/watch?v=FVRJU--8YMY' },
    { TOPICID: '209', DIGITALLINKID: '503', DIGITALLINKURL: 'https://www.youtube.com/watch?v=9Q3UFjpWUU4' },
    { TOPICID: '209', DIGITALLINKID: '504', DIGITALLINKURL: 'https://www.youtube.com/watch?v=3OOry37K7bk' },
    { TOPICID: '209', DIGITALLINKID: '505', DIGITALLINKURL: 'https://www.youtube.com/watch?v=1UR3gQ4iDzI' },
    { TOPICID: '210', DIGITALLINKID: '506', DIGITALLINKURL: 'https://www.youtube.com/watch?v=D8JV3w4TOVw' },
    { TOPICID: '210', DIGITALLINKID: '507', DIGITALLINKURL: 'https://www.youtube.com/watch?v=-1ReFeoScjI' },
    { TOPICID: '210', DIGITALLINKID: '508', DIGITALLINKURL: 'https://www.youtube.com/watch?v=urgsWOcFlYA' },
    { TOPICID: '211', DIGITALLINKID: '509', DIGITALLINKURL: 'https://www.youtube.com/watch?v=SvWuiw3vfw4' },
    { TOPICID: '211', DIGITALLINKID: '510', DIGITALLINKURL: 'https://www.youtube.com/watch?v=489O4snfHg8' },
    { TOPICID: '211', DIGITALLINKID: '511', DIGITALLINKURL: 'https://www.youtube.com/watch?v=PupzbUKDtEo' },
    { TOPICID: '212', DIGITALLINKID: '512', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Qyc-as1mx7s' },
    { TOPICID: '212', DIGITALLINKID: '513', DIGITALLINKURL: 'https://www.youtube.com/watch?v=zGqZZQablfY' },
    { TOPICID: '212', DIGITALLINKID: '514', DIGITALLINKURL: 'https://www.youtube.com/watch?v=82LMqudXE9k' },
    { TOPICID: '213', DIGITALLINKID: '515', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Jb2stN7kH28' },
    { TOPICID: '213', DIGITALLINKID: '516', DIGITALLINKURL: 'https://www.youtube.com/watch?v=WOKOiZo5zAQ' },
    { TOPICID: '213', DIGITALLINKID: '517', DIGITALLINKURL: 'https://www.youtube.com/watch?v=9q_Kp0yknrg' },
    { TOPICID: '214', DIGITALLINKID: '518', DIGITALLINKURL: 'https://www.youtube.com/watch?v=fP_IZKfc4vo' },
    { TOPICID: '214', DIGITALLINKID: '519', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Z1Yd7upQsXY' },
    { TOPICID: '214', DIGITALLINKID: '520', DIGITALLINKURL: 'https://www.youtube.com/watch?v=vLnPwxZdW4Y' },
    { TOPICID: '215', DIGITALLINKID: '521', DIGITALLINKURL: 'https://www.youtube.com/watch?v=7mFGeA5YDzw' },
    { TOPICID: '215', DIGITALLINKID: '522', DIGITALLINKURL: 'https://www.youtube.com/watch?v=NhNdz68xqXo' },
    { TOPICID: '215', DIGITALLINKID: '523', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Yv0e96nfx4g' },
    {
        TOPICID: '216',
        DIGITALLINKID: '524',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/workspace-basics.html',
    },
    {
        TOPICID: '216',
        DIGITALLINKID: '525',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/photoshopbasics/getting-to-know-the-photoshop-interface/1/',
    },
    {
        TOPICID: '216',
        DIGITALLINKID: '526',
        DIGITALLINKURL: 'https://www.photoshopessentials.com/basics/photoshop-workspaces/',
    },
    {
        TOPICID: '217',
        DIGITALLINKID: '527',
        DIGITALLINKURL: 'https://vanseodesign.com/web-design/hue-saturation-and-lightness/',
    },
    {
        TOPICID: '217',
        DIGITALLINKID: '528',
        DIGITALLINKURL:
            'https://helpx.adobe.com/photoshop/using/adjust-shadow-highlight-detail.html#:~:text=Choose%20Image%20%3E%20Adjustments%20%3E%20Shadow%2F,Shadows%20or%20Highlights%20percentage%20box.',
    },
    {
        TOPICID: '217',
        DIGITALLINKID: '529',
        DIGITALLINKURL: 'https://edu.gcfglobal.org/en/photoshopbasics/levels-curves-and-color/1/',
    },
    { TOPICID: '218', DIGITALLINKID: '530', DIGITALLINKURL: 'https://www.youtube.com/watch?v=NFTexcG4Wpo' },
    {
        TOPICID: '218',
        DIGITALLINKID: '531',
        DIGITALLINKURL: 'https://www.quora.com/How-do-you-insert-an-image-in-Photoshop',
    },
    { TOPICID: '218', DIGITALLINKID: '532', DIGITALLINKURL: 'https://www.wikihow.com/Insert-an-Image-in-Photoshop' },
    { TOPICID: '219', DIGITALLINKID: '533', DIGITALLINKURL: 'https://www.youtube.com/watch?v=fPQP-ZyxXUM' },
    { TOPICID: '219', DIGITALLINKID: '534', DIGITALLINKURL: 'https://www.youtube.com/watch?v=U1UJn4MD4_c' },
    {
        TOPICID: '219',
        DIGITALLINKID: '535',
        DIGITALLINKURL:
            'https://www.dummies.com/photography/photo-software/adobe-photoshop-elements/how-to-create-images-from-scratch-in-photoshop-elements-12/',
    },
    {
        TOPICID: '220',
        DIGITALLINKID: '536',
        DIGITALLINKURL:
            'https://design.tutsplus.com/tutorials/10-techniques-that-are-essential-for-successful-photo-manipulation-artwork--psd-242',
    },
    { TOPICID: '220', DIGITALLINKID: '537', DIGITALLINKURL: 'https://www.youtube.com/watch?v=wyOM_N-IaeU' },
    {
        TOPICID: '220',
        DIGITALLINKID: '538',
        DIGITALLINKURL: 'https://cloudinary.com/visualweb/display/IMMC/Image+Manipulation+Techniques',
    },
    {
        TOPICID: '221',
        DIGITALLINKID: '539',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/making-selections.html',
    },
    {
        TOPICID: '221',
        DIGITALLINKID: '540',
        DIGITALLINKURL: 'https://expertphotography.com/photoshop-selection-tools/',
    },
    { TOPICID: '221', DIGITALLINKID: '541', DIGITALLINKURL: 'https://www.youtube.com/watch?v=fhl7f92wkYY' },
    { TOPICID: '222', DIGITALLINKID: '542', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Hw0-SehGcgg' },
    {
        TOPICID: '222',
        DIGITALLINKID: '543',
        DIGITALLINKURL: 'https://astutegraphics.com/learn/tutorial/how-to-crop-and-trim-a-photo-in-adobe-illustrator',
    },
    {
        TOPICID: '222',
        DIGITALLINKID: '544',
        DIGITALLINKURL: 'https://blog.ludus.one/crop-trim-mask-clip-cut-truncate-your-pictures-in-ludus-c732f5e1a1cb',
    },
    { TOPICID: '223', DIGITALLINKID: '545', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Hw0-SehGcgg' },
    { TOPICID: '223', DIGITALLINKID: '546', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Unv3AEQ-C1I' },
    {
        TOPICID: '223',
        DIGITALLINKID: '547',
        DIGITALLINKURL: 'https://www.agitraining.com/adobe/photoshop/tutorials/using-copy-and-paste-in-photoshop-cs6',
    },
    {
        TOPICID: '224',
        DIGITALLINKID: '548',
        DIGITALLINKURL: 'https://www.igi-global.com/dictionary/smartphone-application/47827',
    },
    {
        TOPICID: '224',
        DIGITALLINKID: '549',
        DIGITALLINKURL: 'https://www.techopedia.com/definition/2953/mobile-application-mobile-app',
    },
    { TOPICID: '224', DIGITALLINKID: '550', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Mobile_app' },
    { TOPICID: '225', DIGITALLINKID: '551', DIGITALLINKURL: 'https://support.google.com/android/answer/9457058?hl=en' },
    { TOPICID: '225', DIGITALLINKID: '552', DIGITALLINKURL: 'https://www.wikihow.com/Download-Apps-on-Android' },
    { TOPICID: '225', DIGITALLINKID: '553', DIGITALLINKURL: 'https://www.youtube.com/watch?v=8s1hWaV3pr8' },
    {
        TOPICID: '226',
        DIGITALLINKID: '554',
        DIGITALLINKURL: 'https://www.pcmag.com/news/the-best-mobile-photo-editing-apps',
    },
    {
        TOPICID: '226',
        DIGITALLINKID: '555',
        DIGITALLINKURL: 'https://guide.servify.in/edit-images-like-pro-smartphones/',
    },
    { TOPICID: '226', DIGITALLINKID: '556', DIGITALLINKURL: 'https://www.youtube.com/watch?v=JJrvefazaNw' },
    { TOPICID: '227', DIGITALLINKID: '557', DIGITALLINKURL: 'https://www.pinterest.ph/pin/195132596340500849/' },
    { TOPICID: '227', DIGITALLINKID: '558', DIGITALLINKURL: 'https://pixabay.com/images/search/edit/' },
    { TOPICID: '227', DIGITALLINKID: '559', DIGITALLINKURL: 'https://unsplash.com/s/photos/free-to-edit' },
    {
        TOPICID: '228',
        DIGITALLINKID: '560',
        DIGITALLINKURL: 'https://www.hongkiat.com/blog/ways-improve-smartphone-photography/',
    },
    {
        TOPICID: '228',
        DIGITALLINKID: '561',
        DIGITALLINKURL: 'https://forums.tomsguide.com/faq/how-to-combine-multiple-photos-on-a-smartphone.23430/',
    },
    { TOPICID: '229', DIGITALLINKID: '562', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Pixel' },
    { TOPICID: '229', DIGITALLINKID: '563', DIGITALLINKURL: 'https://www.techopedia.com/definition/24012/pixel' },
    { TOPICID: '229', DIGITALLINKID: '564', DIGITALLINKURL: 'https://www.youtube.com/watch?v=m8c1CAT2zEI' },
    {
        TOPICID: '230',
        DIGITALLINKID: '565',
        DIGITALLINKURL: 'https://microscope-microscope.org/microscope-info/image-resolution/',
    },
    {
        TOPICID: '230',
        DIGITALLINKID: '566',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/image-size-resolution.html',
    },
    {
        TOPICID: '231',
        DIGITALLINKID: '567',
        DIGITALLINKURL: 'https://www.photoworkout.com/best-software-organize-photos-windows/',
    },
    { TOPICID: '231', DIGITALLINKID: '568', DIGITALLINKURL: 'https://www.canto.com/blog/organize-digital-photos/' },
    {
        TOPICID: '231',
        DIGITALLINKID: '569',
        DIGITALLINKURL:
            'https://www.washingtonpost.com/lifestyle/home/how-to-get-your-photo-library-under-control/2019/10/22/6b7eeac0-f025-11e9-b648-76bcf86eb67e_story.html',
    },
    { TOPICID: '232', DIGITALLINKID: '570', DIGITALLINKURL: 'https://www.youtube.com/watch?v=I8S4k_s97HE' },
    {
        TOPICID: '232',
        DIGITALLINKID: '571',
        DIGITALLINKURL: 'https://www.adobe.com/creativecloud/photography/discover/combine-images.html',
    },
    { TOPICID: '232', DIGITALLINKID: '572', DIGITALLINKURL: 'https://www.youtube.com/watch?v=US3NZc_pmSI' },
    { TOPICID: '233', DIGITALLINKID: '573', DIGITALLINKURL: 'https://www.youtube.com/watch?v=QqfbD3TN9qc' },
    {
        TOPICID: '233',
        DIGITALLINKID: '574',
        DIGITALLINKURL: 'https://www.gamasutra.com/view/feature/3102/four_tricks_for_fast_blurring_in_.php?print=1',
    },
    {
        TOPICID: '233',
        DIGITALLINKID: '575',
        DIGITALLINKURL:
            'https://datacarpentry.org/image-processing/06-blurring/#:~:text=Blurring%20is%20an%20example%20of,tasks%20such%20as%20edge%20detection.',
    },
    {
        TOPICID: '234',
        DIGITALLINKID: '576',
        DIGITALLINKURL: 'https://www.picturecorrect.com/tips/8-photo-editing-tips-for-stunning-photographs/',
    },
    { TOPICID: '234', DIGITALLINKID: '577', DIGITALLINKURL: 'https://www.realsimple.com/work-life/how-to-edit-photos' },
    { TOPICID: '234', DIGITALLINKID: '578', DIGITALLINKURL: 'https://www.youtube.com/watch?v=GEIDtjouwiI' },
    {
        TOPICID: '235',
        DIGITALLINKID: '579',
        DIGITALLINKURL: 'https://digitalnz.org/make-it-digital/creating-digital-content/creating-digital-audio',
    },
    {
        TOPICID: '235',
        DIGITALLINKID: '580',
        DIGITALLINKURL: 'http://artsites.ucsc.edu/EMS/music/tech_background/TE-16/teces_16.html',
    },
    { TOPICID: '235', DIGITALLINKID: '581', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Audio_file_format' },
    {
        TOPICID: '236',
        DIGITALLINKID: '582',
        DIGITALLINKURL: 'https://www.businessinsider.com/how-to-record-audio-on-android',
    },
    {
        TOPICID: '236',
        DIGITALLINKID: '583',
        DIGITALLINKURL: 'https://www.businessinsider.com/how-to-record-audio-on-android',
    },
    {
        TOPICID: '237',
        DIGITALLINKID: '584',
        DIGITALLINKURL: 'https://docs.oracle.com/cd/E19455-01/806-1360/6jalch352/index.html',
    },
    {
        TOPICID: '237',
        DIGITALLINKID: '585',
        DIGITALLINKURL: 'https://docs.oracle.com/cd/E19504-01/802-5817/audio-19696/index.html',
    },
    { TOPICID: '237', DIGITALLINKID: '586', DIGITALLINKURL: 'https://kb.plu.edu/page.php?id=86093' },
    { TOPICID: '238', DIGITALLINKID: '587', DIGITALLINKURL: 'https://www.youtube.com/watch?v=KvNZdxlicsM' },
    {
        TOPICID: '238',
        DIGITALLINKID: '588',
        DIGITALLINKURL: 'https://www.nde-ed.org/EducationResources/HighSchool/Sound/frequencypitch.htm',
    },
    {
        TOPICID: '238',
        DIGITALLINKID: '589',
        DIGITALLINKURL: 'https://manual.audacityteam.org/man/tutorial_editing_an_existing_file.html',
    },
    { TOPICID: '239', DIGITALLINKID: '590', DIGITALLINKURL: 'https://www.youtube.com/watch?v=3A3HU0nwQZE' },
    { TOPICID: '239', DIGITALLINKID: '591', DIGITALLINKURL: 'https://www.youtube.com/watch?v=azg7WJR-5Jw' },
    {
        TOPICID: '239',
        DIGITALLINKID: '592',
        DIGITALLINKURL: 'https://manual.audacityteam.org/man/copy_and_paste_a_section_of_audio.html',
    },
    {
        TOPICID: '240',
        DIGITALLINKID: '593',
        DIGITALLINKURL: 'https://ds106.us/handbook/tools/layering-sounds-in-audacity/',
    },
    {
        TOPICID: '240',
        DIGITALLINKID: '594',
        DIGITALLINKURL: 'http://help.nchsoftware.com/help/en/photostage/win/soundclipsoverlap.html',
    },
    { TOPICID: '241', DIGITALLINKID: '595', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Fade_(audio_engineering)' },
    { TOPICID: '241', DIGITALLINKID: '596', DIGITALLINKURL: 'https://www.youtube.com/watch?v=mwqhBA2CoRA' },
    { TOPICID: '241', DIGITALLINKID: '597', DIGITALLINKURL: 'https://www.youtube.com/watch?v=0CU8rqnuZJU' },
    {
        TOPICID: '242',
        DIGITALLINKID: '598',
        DIGITALLINKURL:
            'https://www.khanacademy.org/science/ap-physics-1/ap-mechanical-waves-and-sound/introduction-to-sound-waves-ap/v/sound-properties-amplitude-period-frequency-wavelength',
    },
    {
        TOPICID: '242',
        DIGITALLINKID: '599',
        DIGITALLINKURL:
            'https://www.guidingtech.com/get-louder-better-sound-windows-10/#:~:text=Enhancing%20System%20Audio&text=To%20access%20the%20sound%20settings,the%20option%20for%20Loudness%20Equalization.',
    },
    {
        TOPICID: '243',
        DIGITALLINKID: '600',
        DIGITALLINKURL:
            'https://www.softwaretestinghelp.com/flowchart-software/#:~:text=Flowchart%20maker%20software%20is%20an,to%20collaborate%20on%20the%20drawings.',
    },
    { TOPICID: '243', DIGITALLINKID: '601', DIGITALLINKURL: 'https://www.youtube.com/watch?v=iJmcgQRk048' },
    { TOPICID: '243', DIGITALLINKID: '602', DIGITALLINKURL: 'https://creately.com/lp/flowchart-software/' },
    {
        TOPICID: '244',
        DIGITALLINKID: '603',
        DIGITALLINKURL:
            'https://www.breezetree.com/articles/excel-flowchart-shapes/#:~:text=Flowchart%3A%20In%20flowcharts%2C%20this%20symbol,other%20shapes%20and%20flow%20lines',
    },
    {
        TOPICID: '244',
        DIGITALLINKID: '604',
        DIGITALLINKURL: 'https://www.lucidchart.com/pages/flowchart-symbols-meaning-explained.',
    },
    { TOPICID: '244', DIGITALLINKID: '605', DIGITALLINKURL: 'https://www.edrawsoft.com/flowchart-symbols.html' },
    {
        TOPICID: '245',
        DIGITALLINKID: '606',
        DIGITALLINKURL: 'https://www.visual-paradigm.com/tutorials/flowchart-tutorial/',
    },
    { TOPICID: '245', DIGITALLINKID: '607', DIGITALLINKURL: 'https://www.lucidchart.com/pages/templates/flowchart' },
    { TOPICID: '245', DIGITALLINKID: '608', DIGITALLINKURL: 'https://www.rff.com/flowchart_samples.php' },
    {
        TOPICID: '246',
        DIGITALLINKID: '609',
        DIGITALLINKURL: 'https://www.lucidchart.com/pages/examples/flowchart_software',
    },
    { TOPICID: '246', DIGITALLINKID: '610', DIGITALLINKURL: 'https://www.smartdraw.com/flowchart/flowchart-maker.htm' },
    { TOPICID: '247', DIGITALLINKID: '611', DIGITALLINKURL: 'https://www.smartdraw.com/flowchart/flowchart-tips.htm' },
    {
        TOPICID: '247',
        DIGITALLINKID: '612',
        DIGITALLINKURL: 'https://creately.com/diagram-type/article/6-useful-tips-drawing-flowcharts',
    },
    { TOPICID: '248', DIGITALLINKID: '613', DIGITALLINKURL: 'https://www.youtube.com/watch?v=FCMxA3m_Imc' },
    {
        TOPICID: '248',
        DIGITALLINKID: '614',
        DIGITALLINKURL: 'https://www.futurelearn.com/courses/programming-101/0/steps/43783',
    },
    {
        TOPICID: '248',
        DIGITALLINKID: '615',
        DIGITALLINKURL:
            'https://www.snhu.edu/about-us/newsroom/2017/01/what-do-programmers-do#:~:text=What%20Do%20Programmers%20Do%2C%20Anyway,programming%20websites%2C%20and%20much%20more.',
    },
    { TOPICID: '249', DIGITALLINKID: '616', DIGITALLINKURL: 'https://www.youtube.com/watch?v=imFrkT4rJP4' },
    {
        TOPICID: '249',
        DIGITALLINKID: '617',
        DIGITALLINKURL:
            'https://www.pocket-lint.com/apps/news/google/141558-google-blocks-is-a-new-tool-anyone-can-use-to-make-3d-models-in-vr',
    },
    {
        TOPICID: '249',
        DIGITALLINKID: '618',
        DIGITALLINKURL: 'https://www.theverge.com/2017/7/6/15929312/google-blocks-vr-modeling-app-oculus-rift-htc-vive',
    },
    { TOPICID: '250', DIGITALLINKID: '619', DIGITALLINKURL: 'https://www.youtube.com/watch?v=__JP2R48S6k' },
    { TOPICID: '251', DIGITALLINKID: '620', DIGITALLINKURL: 'https://www.youtube.com/watch?v=wDJua9hgyZM' },
    { TOPICID: '251', DIGITALLINKID: '621', DIGITALLINKURL: 'https://www.youtube.com/watch?v=XpfN_rN9gZM' },
    { TOPICID: '252', DIGITALLINKID: '622', DIGITALLINKURL: 'https://www.youtube.com/watch?v=pFyOznL9UvA' },
    {
        TOPICID: '252',
        DIGITALLINKID: '623',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/how-to/ps-basics-fundamentals.html',
    },
    { TOPICID: '252', DIGITALLINKID: '624', DIGITALLINKURL: 'https://www.instructables.com/id/Basics-of-Photoshop/' },
    {
        TOPICID: '253',
        DIGITALLINKID: '625',
        DIGITALLINKURL: 'https://www.photoshopessentials.com/basics/photoshop-tools-toolbar-overview/',
    },
    { TOPICID: '253', DIGITALLINKID: '626', DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/tools.html' },
    {
        TOPICID: '253',
        DIGITALLINKID: '627',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop-elements/using/tools.html',
    },
    { TOPICID: '254', DIGITALLINKID: '628', DIGITALLINKURL: 'https://phlearn.com/tutorial/photoshop-301/' },
    { TOPICID: '254', DIGITALLINKID: '629', DIGITALLINKURL: 'https://www.youtube.com/watch?v=2cQT1ZgvgGI' },
    { TOPICID: '254', DIGITALLINKID: '630', DIGITALLINKURL: 'https://www.youtube.com/watch?v=zyNL_HnM-XA' },
    { TOPICID: '255', DIGITALLINKID: '631', DIGITALLINKURL: 'https://www.youtube.com/watch?v=_aZKduKdofU' },
    {
        TOPICID: '255',
        DIGITALLINKID: '632',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/how-to/photo-enhancement-basics.html',
    },
    {
        TOPICID: '255',
        DIGITALLINKID: '633',
        DIGITALLINKURL: 'https://www.amu.apus.edu/course-schedule/details.html?c=DSIN141',
    },
    {
        TOPICID: '256',
        DIGITALLINKID: '634',
        DIGITALLINKURL:
            'https://www.dummies.com/computers/operating-systems/windows-7/windows-live-movie-maker-create-edit-and-view-movies/#:~:text=To%20summon%20Movie%20Maker%20Live,to%20include%20in%20your%20video.',
    },
    { TOPICID: '256', DIGITALLINKID: '635', DIGITALLINKURL: 'https://www.youtube.com/watch?v=5brlIUTRuL8' },
    { TOPICID: '256', DIGITALLINKID: '636', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ZGQN3nkGBa4' },
    {
        TOPICID: '257',
        DIGITALLINKID: '637',
        DIGITALLINKURL:
            'http://www.underwaterphotography.com/Video-Editing/Windows-Movie-Maker/Software/understandingthewindowsmoviemakerinterface.asp#:~:text=The%20Windows%20Movie%20Maker%20user,and%20the%20storyboard%20and%20timeline.',
    },
    {
        TOPICID: '257',
        DIGITALLINKID: '638',
        DIGITALLINKURL: 'https://www.techwalla.com/articles/parts-of-windows-movie-maker',
    },
    { TOPICID: '257', DIGITALLINKID: '639', DIGITALLINKURL: 'https://www.youtube.com/watch?v=JaVk4IRK7ZQ' },
    { TOPICID: '258', DIGITALLINKID: '640', DIGITALLINKURL: 'https://www.youtube.com/watch?v=SvWuiw3vfw4' },
    { TOPICID: '258', DIGITALLINKID: '641', DIGITALLINKURL: 'https://www.youtube.com/watch?v=D4G15pwobDU&vl=en' },
    {
        TOPICID: '258',
        DIGITALLINKID: '642',
        DIGITALLINKURL:
            'https://www.cyberlink.com/learning/powerdirector-video-editing-software/209/introduction-to-new-powerdirector-14',
    },
    { TOPICID: '259', DIGITALLINKID: '643', DIGITALLINKURL: 'https://www.youtube.com/watch?v=CT4hb6gtW9w' },
    { TOPICID: '259', DIGITALLINKID: '644', DIGITALLINKURL: 'https://www.youtube.com/watch?v=dFgxMwe5lRk' },
    {
        TOPICID: '259',
        DIGITALLINKID: '645',
        DIGITALLINKURL:
            'http://www.vip-video-converter.com/articles/how-to-export-cyberlink-powerdirector-video-to-mp4-avi-flv-youtube/#:~:text=In%20PowerDirector%2C%20navigate%20to%20the,and%20install%20VIP%20Video%20Converter.',
    },
    {
        TOPICID: '260',
        DIGITALLINKID: '646',
        DIGITALLINKURL:
            'https://helpx.adobe.com/photoshop/how-to/ps-basics-fundamentals.html?playlist=/services/playlist.helpx/products:SG_PHOTOSHOP_1_1/learn-path:get-started/set-header:ccx-designer/playlist:ccl-get-started-1/en_us.json&ref=helpx.adobe.com',
    },
    {
        TOPICID: '261',
        DIGITALLINKID: '647',
        DIGITALLINKURL:
            'https://helpx.adobe.com/photoshop/how-to/ps-layers-basics.html?playlist=/services/playlist.helpx/products:SG_PHOTOSHOP_1_1/learn-path:get-started/set-header:ccx-designer/playlist:ccl-get-started-1/en_us.json&ref=helpx.adobe.com',
    },
    { TOPICID: '262', DIGITALLINKID: '648', DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/tutorials.html' },
    {
        TOPICID: '263',
        DIGITALLINKID: '649',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/drawing-pen-tools.html',
    },
    {
        TOPICID: '264',
        DIGITALLINKID: '650',
        DIGITALLINKURL: 'https://www.techwalla.com/articles/how-to-draw-a-colored-line-in-photoshop',
    },
    {
        TOPICID: '265',
        DIGITALLINKID: '651',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/drawing-shapes.html',
    },
    {
        TOPICID: '266',
        DIGITALLINKID: '652',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop-elements/using/add-text.html',
    },
    {
        TOPICID: '267',
        DIGITALLINKID: '653',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/how-to/get-great-fonts-design.html',
    },
    {
        TOPICID: '268',
        DIGITALLINKID: '654',
        DIGITALLINKURL: 'https://design.tutsplus.com/articles/100-best-text-effect-tutorials--cms-28650',
    },
    {
        TOPICID: '269',
        DIGITALLINKID: '655',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/formatting-characters.html',
    },
    {
        TOPICID: '270',
        DIGITALLINKID: '656',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/how-to/color-management-basics.html',
    },
    {
        TOPICID: '271',
        DIGITALLINKID: '657',
        DIGITALLINKURL: 'https://www.creativelive.com/blog/how-to-replace-colors-in-photoshop/',
    },
    {
        TOPICID: '272',
        DIGITALLINKID: '658',
        DIGITALLINKURL: 'https://www.photoshopessentials.com/basics/custom-swatches/',
    },
    {
        TOPICID: '273',
        DIGITALLINKID: '659',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/how-to/adjust-contrast-brightness.html',
    },
    {
        TOPICID: '274',
        DIGITALLINKID: '660',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/using/adjusting-hue-saturation.html',
    },
    {
        TOPICID: '275',
        DIGITALLINKID: '661',
        DIGITALLINKURL:
            'https://www.photoshopessentials.com/basics/free-transform-in-photoshop-cc-2019-new-features-and-changes/',
    },
    {
        TOPICID: '276',
        DIGITALLINKID: '662',
        DIGITALLINKURL: 'https://helpx.adobe.com/photoshop/how-to/align-objects-guides.html',
    },
    {
        TOPICID: '277',
        DIGITALLINKID: '663',
        DIGITALLINKURL: 'https://www.webfx.com/blog/web-design/22-professional-photoshop-image-enhancing-tutorials/',
    },
    {
        TOPICID: '278',
        DIGITALLINKID: '664',
        DIGITALLINKURL: 'https://onextrapixel.com/45-excellent-photo-enhancement-tutorials-for-photoshop/',
    },
    { TOPICID: '279', DIGITALLINKID: '665', DIGITALLINKURL: 'https://en.wikipedia.org/wiki/Microsoft_Visio' },
    {
        TOPICID: '279',
        DIGITALLINKID: '666',
        DIGITALLINKURL: 'https://www.lucidchart.com/pages/what-is-microsoft-visio',
    },
    { TOPICID: '279', DIGITALLINKID: '667', DIGITALLINKURL: 'https://www.computerhope.com/jargon/v/visio.htm' },
    { TOPICID: '280', DIGITALLINKID: '668', DIGITALLINKURL: 'https://www.youtube.com/watch?v=ruRcHcTITuI' },
    {
        TOPICID: '280',
        DIGITALLINKID: '669',
        DIGITALLINKURL: 'https://www.microsoft.com/en-ww/microsoft-365/visio/flowchart-software',
    },
    {
        TOPICID: '280',
        DIGITALLINKID: '670',
        DIGITALLINKURL: 'https://www.edrawsoft.com/flowchart/how-to-create-visio-flowchart.html',
    },
    {
        TOPICID: '281',
        DIGITALLINKID: '671',
        DIGITALLINKURL:
            'https://www.programiz.com/cpp-programming#:~:text=C%2B%2B%20is%20a%20powerful%20general,powerful%20as%20well%20as%20flexible.',
    },
    { TOPICID: '281', DIGITALLINKID: '672', DIGITALLINKURL: 'https://www.youtube.com/watch?v=XWE3hA4PAoI' },
    {
        TOPICID: '281',
        DIGITALLINKID: '673',
        DIGITALLINKURL: 'https://www.tutorialspoint.com/cplusplus/cpp_basic_syntax.htm',
    },
    {
        TOPICID: '282',
        DIGITALLINKID: '674',
        DIGITALLINKURL:
            'https://docs.microsoft.com/en-us/visualstudio/vsto/how-to-programmatically-add-shapes-to-a-visio-document?view=vs-2019',
    },
    {
        TOPICID: '282',
        DIGITALLINKID: '675',
        DIGITALLINKURL:
            'https://en.wikipedia.org/wiki/Block_(programming)#:~:text=In%20computer%20programming%2C%20a%20block,code%20which%20is%20grouped%20together.&text=A%20programming%20language%20that%20permits,a%20block%2Dstructured%20programming%20language.',
    },
    {
        TOPICID: '282',
        DIGITALLINKID: '676',
        DIGITALLINKURL: 'https://visualsignals.typepad.co.uk/vislog/2012/03/building-visio-shapes-with-code.html',
    },
    { TOPICID: '283', DIGITALLINKID: '677', DIGITALLINKURL: 'https://www.youtube.com/watch?v=nV5TbrMM2PI' },
    {
        TOPICID: '283',
        DIGITALLINKID: '678',
        DIGITALLINKURL: 'https://www.nextacademy.com/blog/7-tips-to-learn-programming-from-scratch',
    },
    { TOPICID: '283', DIGITALLINKID: '679', DIGITALLINKURL: 'https://www.youtube.com/watch?v=K0T7zuxEIgw' },
    {
        TOPICID: '284',
        DIGITALLINKID: '680',
        DIGITALLINKURL: 'https://www.sciencedirect.com/science/article/pii/0743106687900069',
    },
    { TOPICID: '284', DIGITALLINKID: '681', DIGITALLINKURL: 'https://www.teachontario.ca/docs/DOC-10733' },
    { TOPICID: '284', DIGITALLINKID: '682', DIGITALLINKURL: 'https://www.youtube.com/watch?v=Ejav4UVFgAY' },
    {
        TOPICID: '285',
        DIGITALLINKID: '683',
        DIGITALLINKURL:
            'https://en.wikipedia.org/wiki/Conditional_(computer_programming)#:~:text=In%20computer%20science%2C%20conditional%20statements,evaluates%20to%20true%20or%20false.',
    },
    { TOPICID: '285', DIGITALLINKID: '684', DIGITALLINKURL: 'https://www.computerhope.com/jargon/c/contstat.htm' },
    {
        TOPICID: '285',
        DIGITALLINKID: '685',
        DIGITALLINKURL:
            'https://www.mathgoodies.com/lessons/vol9/conditional#:~:text=Definition%3A%20A%20conditional%20statement%2C%20symbolized,leads%20to%20a%20false%20conclusion.',
    },
    { TOPICID: '286', DIGITALLINKID: '686', DIGITALLINKURL: 'https://www.softwaretestinghelp.com/cpp-applications/' },
    { TOPICID: '286', DIGITALLINKID: '687', DIGITALLINKURL: 'https://www.educba.com/uses-of-c-plus-plus/' },
    {
        TOPICID: '286',
        DIGITALLINKID: '688',
        DIGITALLINKURL: 'https://www.tutorialspoint.com/What-are-the-applications-of-Cplusplus-programming',
    },
];
