export const MODULES = [
    {
        GRADEID: '1',
        CHAPTERID: '1',
        module_link: 'https://drive.google.com/file/d/10mMX6eKje393xQxpncsrstun09xQForo/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '1',
        CHAPTERID: '2',
        module_link: 'https://drive.google.com/file/d/1FDojp95b10Iks5P5p2ANOxQQrLJo4XDm/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '1',
        CHAPTERID: '3',
        module_link: 'https://drive.google.com/file/d/1X5amhpIORGfQ2SeIAlbss4pJZS6-FSR4/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '1',
        CHAPTERID: '4',
        module_link: 'https://drive.google.com/file/d/1Q-DW_s0QhhNH9PgRc8vM8fJWHeqBSr0h/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '5',
        module_link: 'https://drive.google.com/file/d/1rzVtEF0VwDGXPmq_8mnNLjy5t5Qfiowm/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '6',
        module_link: 'https://drive.google.com/file/d/1QN2GIUy4UmZyWgSw4rG66L3eEtophsdE/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '7',
        module_link: 'https://drive.google.com/file/d/1IB5M-HwQ9P1LHuj3DEX9cOZ-VHKBmhHQ/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '2',
        CHAPTERID: '8',
        module_link: 'https://drive.google.com/file/d/1jX7dLhjhOGN4x-Gu3iqUTxygbMaBOPwA/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '9',
        module_link: 'https://drive.google.com/file/d/14B7AZ45CFB3eN_-ITnyLODAVJbV9C-O0/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '10',
        module_link: 'https://drive.google.com/file/d/1clmeT_J46XuKfsScJNTpMKByGlPNhdQ1/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '11',
        module_link: 'https://drive.google.com/file/d/1d4ieg5uLuvZDwR-4iqQrDrb5OM6f8YLA/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '3',
        CHAPTERID: '12',
        module_link: 'https://drive.google.com/file/d/1pKZ7woK_LUAXV_v4-ryfUlg-4niFEE8F/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '13',
        module_link: 'https://drive.google.com/file/d/1UeHuIOejmH9YPeQ8Bskb5l02_LJCy6O-/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '14',
        module_link: 'https://drive.google.com/file/d/10urFj1eOzuUbZ5qNPf7YIFTGwWoWZYof/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '15',
        module_link: 'https://drive.google.com/file/d/1-Sva0MAom1FwBmLf2NGdu-DsHXmcFALe/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '4',
        CHAPTERID: '16',
        module_link: 'https://drive.google.com/file/d/15XWBjHhCQNr1bTeQjFdpZ-_alckBDImq/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '17',
        module_link: 'https://drive.google.com/file/d/1w1bL3pFGj-syb-840jq90FN-mLgwnjXt/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '18',
        module_link: 'https://drive.google.com/file/d/1qrdeNT4VQscu7hZ_kZnqdVycoVGSs193/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '19',
        module_link: 'https://drive.google.com/file/d/1Umh-kjvK4mRHXicCCNbyhrYZJQ-_I7JO/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '5',
        CHAPTERID: '20',
        module_link: 'https://drive.google.com/file/d/1Qyk0Xy4vt9pdYh7GTiZivp3ne-diZ6bB/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '21',
        module_link: 'https://drive.google.com/file/d/1oKD1rD5dzNyfo6Vx2PK7ozOb_8OnWAnS/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '22',
        module_link: 'https://drive.google.com/file/d/1DLZWpLkpGXgFqtPQ1HinRS0LkxAPDcQn/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '23',
        module_link: 'https://drive.google.com/file/d/10nPN_xB35wUGGwkT3DytX7D_9MrvN5Hm/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '6',
        CHAPTERID: '24',
        module_link: 'https://drive.google.com/file/d/1zMgtoQ6dP3dykcd5dqRczVUuYBRgzjja/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '25',
        module_link: 'https://drive.google.com/file/d/1tsei0XouFhcPTmRzOxmMLTwbZEdVVHPJ/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '26',
        module_link: 'https://drive.google.com/file/d/1vXt06wDOZUQRPQfs_SmW3p7MJmFz-GJL/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '27',
        module_link: 'https://drive.google.com/file/d/1uyTp2A3WSe6aSqhATxMt7LazjFU_hjXX/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '7',
        CHAPTERID: '28',
        module_link: 'https://drive.google.com/file/d/1ziTrs8d1U7O2VhekZBagr_5BNbL4hqnx/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '29',
        module_link: 'https://drive.google.com/file/d/17amtOduuZvbWeGXbEMXZMXXZnrHTlws-/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '30',
        module_link: 'https://drive.google.com/file/d/1tzSzUJOE1BavHH0fhUu4vwf7UeG_zd1T/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '31',
        module_link: 'https://drive.google.com/file/d/1dJInxfDNLbxjOwJK5auaBAwYhBHM-THh/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '8',
        CHAPTERID: '32',
        module_link: 'https://drive.google.com/file/d/1RoR2W-LARyZjdqtI91BnV_h4XqsBIV2k/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '33',
        module_link: 'https://drive.google.com/file/d/1n4GGHL8gqBw4XauP5Gy-FTgPkRgrWlQ6/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '34',
        module_link: 'https://drive.google.com/file/d/1en5MEL6fIzVBMR-v9V5BLmEDx6wQ4_pl/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '35',
        module_link: 'https://drive.google.com/file/d/1nzw-3E3mOLSnoqV9Cn_vvCT-zxeb_1du/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '9',
        CHAPTERID: '36',
        module_link: 'https://drive.google.com/file/d/1n-2j_TAeRh1q8BntYPN2ebIvIzV3WgYi/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '37',
        module_link: 'https://drive.google.com/file/d/1qmiyzISo2IbDK2TACUJmLk4rtj2pyAho/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '38',
        module_link: 'https://drive.google.com/file/d/1NXKrkXlJuZwznAR9ms3MqNiQvoCRRCe6/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '39',
        module_link: 'https://drive.google.com/file/d/1zknVzzvGuQXQ0mEcsRHv08E2QoAabNYN/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '10',
        CHAPTERID: '40',
        module_link: 'https://drive.google.com/file/d/1pA6hHrR6tSbWZullfkUlJBlWJNeTx02D/view?usp=sharing',
        module_title: '',
    },
    {
        GRADEID: '12',
        CHAPTERID: '41',
        module_link: 'https://drive.google.com/file/d/1Xe0Oplvo_LHzFtZqq-aO6hfDkboUAm3J/view?usp=sharing',
        module_title: 'Maximizing the web for Creating Survey Forms And Other Research Documents',
    },
    {
        GRADEID: '12',
        CHAPTERID: '42',
        module_link: 'https://drive.google.com/file/d/1Pv3zNiG5vSPg9dpzowNRLFvFOKY5Zpq9/view?usp=sharing',
        module_title: 'Using Microsoft Excel for Data Analysis',
    },
    {
        GRADEID: '12',
        CHAPTERID: '41',
        module_link: 'https://drive.google.com/file/d/1Txj4WiQ4EW7zFVc_ipJU7Q-rzgZx2p7X/view?usp=sharing',
        module_title: 'Creating Research Poster Based On International Standards',
    },
    {
        GRADEID: '12',
        CHAPTERID: '42',
        module_link: 'https://drive.google.com/file/d/1ouSkqvm34agHrFIjwLgVwKgVniaRgbuT/view?usp=sharing',
        module_title: 'Research on the Web',
    },
    {
        GRADEID: '12',
        CHAPTERID: '43',
        module_link: 'https://drive.google.com/file/d/1c60q1AYQ8HR6As2sp1AxEl_VSNWSNDdF/view?usp=sharing',
        module_title: 'SketchUp',
    },
    {
        GRADEID: '12',
        CHAPTERID: '44',
        module_link: 'https://drive.google.com/file/d/1cBULgazxKbGQ1o_ioa-wpXemN8xiv0nH/view?usp=sharing',
        module_title: 'SketchUp',
    },
];
