// import NCAP from '../asset/images/logo/ncap.png';
// import DYCI from '../asset/images/logo/dyci.png';
import LMA from '../asset/images/logo/lma.png';
import CDR from '../asset/images/logo/cdr.png';

export const SCHOOL = [
    // {
    //     id: 1,
    //     schoolCode: 'NCAP',
    //     schoolName: 'Nuestra Senora De Guia Academy',
    //     schoolLogo: NCAP,
    // },
    // {
    //     id: 2,
    //     schoolCode: 'DYCI',
    //     schoolName: "Dr. Yanga's Colleges Inc.",
    //     schoolLogo: DYCI,
    // },
    {
        id: 3,
        schoolCode: 'LMA',
        schoolName: 'Lux Mundi Academy',
        schoolLogo: LMA,
    },
    {
        id: 4,
        schoolCode: 'CDR',
        schoolName: 'Colegio De Roma',
        schoolLogo: CDR,
    },
];
