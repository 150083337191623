import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Landing from './page/Landing';
import Module from './page/Module';
import DCLALogo from './asset/images/dcla-logo.png';

function Homepage() {
    return (
        <div style={{ height: '100vh', width: '100vw', background: '#deffd6' }}>
            <div className="module-header-wrapper">
                <img src={DCLALogo} alt="DCLA" title="DCLA Logo" className="module-header-logo"></img>
                <div className="module-title-wrapper">
                    <h1 className="module-header-title">DCLA PHILIPPINES</h1>
                    <h2 className="module-header-sub-title">YOUR PERFECT LEARNING MIX</h2>
                </div>
            </div>
        </div>
    );
}

function App() {
    return (
        <div className="body">
            <Router>
                {/* <Header /> */}
                <Switch>
                    <Route path="/" exact component={Homepage} />
                    <Route path="/aded" exact component={Landing} />
                    <Route path="/module/:school" exact component={Module} />
                </Switch>
                {/* <Footer /> */}
            </Router>
        </div>
    );
}

export default App;
