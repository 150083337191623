import React, { Fragment } from 'react';
import '../asset/css/template/modulelesson.css';

import Title from '../component/Title';
import Button from '../component/Button';

function ModuleLesson({ data }) {
    return (
        <div className="lesson-content-container">
            <Title text={'LESSON ' + data.LESSONLABEL + ' - ' + data.LESSONTITLE} design="lesson-title" />

            {data.topics && data.topics.length > 0 ? (
                <Fragment>
                    <span className="reference-links-title">Reference Links</span>

                    {data.topics.map((topic, index) => {
                        return (
                            <div className="topic-container" key={index}>
                                <Title text={topic.TOPICLABEL + '. ' + topic.TOPICTITLE} design="topic-title" />

                                {topic.digitalLinks &&
                                    topic.digitalLinks.map((digitalLink, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <Button
                                                    text={digitalLink.DIGITALLINKURL}
                                                    design="reference-link"
                                                    link={digitalLink.DIGITALLINKURL}
                                                    linkTo="_blank"
                                                />
                                            </Fragment>
                                        );
                                    })}
                            </div>
                        );
                    })}
                </Fragment>
            ) : null}
        </div>
    );
}

export default ModuleLesson;
