import React from 'react';
import '../asset/css/component/button.css';

function Button({ text, design, link, linkTo }) {
    return (
        <a href={link} style={{ textDecoration: 'none' }} target={linkTo}>
            <div className={design}>
                <span className="button-text">{text}</span>
            </div>
        </a>
    );
}

export default Button;
