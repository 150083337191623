export const LESSONS = [
    { CHAPTERID: '1', LESSONID: '1', LESSONLABEL: '1', LESSONTITLE: 'Basic Parts of a Computer' },
    { CHAPTERID: '1', LESSONID: '2', LESSONLABEL: '2', LESSONTITLE: 'Minimize and Maximize' },
    { CHAPTERID: '2', LESSONID: '3', LESSONLABEL: '1', LESSONTITLE: 'Hover and Single Click' },
    { CHAPTERID: '2', LESSONID: '4', LESSONLABEL: '2', LESSONTITLE: 'Double Click' },
    { CHAPTERID: '2', LESSONID: '5', LESSONLABEL: '3', LESSONTITLE: 'Drag and Drop' },
    { CHAPTERID: '3', LESSONID: '6', LESSONLABEL: '1', LESSONTITLE: 'Letters' },
    { CHAPTERID: '3', LESSONID: '7', LESSONLABEL: '2', LESSONTITLE: 'Numbers' },
    { CHAPTERID: '4', LESSONID: '8', LESSONLABEL: '1', LESSONTITLE: 'Opening and Closing Applications' },
    { CHAPTERID: '4', LESSONID: '9', LESSONLABEL: '2', LESSONTITLE: 'Minimize and Maximize' },
    { CHAPTERID: '5', LESSONID: '10', LESSONLABEL: '1', LESSONTITLE: 'Basic Left Button Operations' },
    { CHAPTERID: '5', LESSONID: '11', LESSONLABEL: '2', LESSONTITLE: 'Basic Right Button Operations' },
    { CHAPTERID: '6', LESSONID: '12', LESSONLABEL: '1', LESSONTITLE: 'Proper Typing' },
    { CHAPTERID: '6', LESSONID: '13', LESSONLABEL: '2', LESSONTITLE: 'Advanced Keyboard Keys' },
    { CHAPTERID: '7', LESSONID: '14', LESSONLABEL: '1', LESSONTITLE: 'Keyboard Navigation' },
    { CHAPTERID: '7', LESSONID: '15', LESSONLABEL: '2', LESSONTITLE: 'Internal Locations in a Computer' },
    { CHAPTERID: '7', LESSONID: '16', LESSONLABEL: '3', LESSONTITLE: 'External Locations and Ports in a Computer' },
    { CHAPTERID: '8', LESSONID: '17', LESSONLABEL: '1', LESSONTITLE: 'Practice Faster Typing and Navigation' },
    { CHAPTERID: '8', LESSONID: '18', LESSONLABEL: '2', LESSONTITLE: 'Combining Mouse and Keyboard Knowledge' },
    { CHAPTERID: '9', LESSONID: '19', LESSONLABEL: '1', LESSONTITLE: 'Internal Parts of a Computer' },
    { CHAPTERID: '9', LESSONID: '20', LESSONLABEL: '2', LESSONTITLE: 'File Paths and Folders' },
    { CHAPTERID: '10', LESSONID: '21', LESSONLABEL: '1', LESSONTITLE: 'Folder Operations' },
    { CHAPTERID: '10', LESSONID: '22', LESSONLABEL: '2', LESSONTITLE: 'Basic Computer File Operations' },
    {
        CHAPTERID: '11',
        LESSONID: '23',
        LESSONLABEL: '1',
        LESSONTITLE: 'Practicing Mouse and Keyboard Operations in Creatives',
    },
    {
        CHAPTERID: '11',
        LESSONID: '24',
        LESSONLABEL: '2',
        LESSONTITLE: 'Practicing Mouse and Keyboard Operations in Documentation',
    },
    { CHAPTERID: '12', LESSONID: '25', LESSONLABEL: '1', LESSONTITLE: 'Basic Software Parts' },
    { CHAPTERID: '12', LESSONID: '26', LESSONLABEL: '2', LESSONTITLE: 'Basic Software Tools' },
    { CHAPTERID: '13', LESSONID: '27', LESSONLABEL: '1', LESSONTITLE: 'Youtube on PC or on Mobile devices' },
    { CHAPTERID: '13', LESSONID: '28', LESSONLABEL: '2', LESSONTITLE: 'Basic Tools of Documentation' },
    { CHAPTERID: '14', LESSONID: '29', LESSONLABEL: '1', LESSONTITLE: 'Basic Tools in Word Apps' },
    { CHAPTERID: '14', LESSONID: '30', LESSONLABEL: '2', LESSONTITLE: 'Basic Tools in Calculation Apps' },
    { CHAPTERID: '15', LESSONID: '31', LESSONLABEL: '1', LESSONTITLE: 'Basic Tools in Presentation Apps' },
    { CHAPTERID: '15', LESSONID: '32', LESSONLABEL: '2', LESSONTITLE: 'Basic Tools in Publishing Apps' },
    { CHAPTERID: '16', LESSONID: '33', LESSONLABEL: '1', LESSONTITLE: 'Using Basic Tools on Basic Creative Apps' },
    { CHAPTERID: '16', LESSONID: '34', LESSONLABEL: '2', LESSONTITLE: 'Using Basic Tools on Basic Flow Charting Apps' },
    { CHAPTERID: '17', LESSONID: '35', LESSONLABEL: '1', LESSONTITLE: 'Advanced Tools: Page Layout' },
    { CHAPTERID: '17', LESSONID: '36', LESSONLABEL: '2', LESSONTITLE: 'Advanced Tools: Review and View' },
    {
        CHAPTERID: '18',
        LESSONID: '37',
        LESSONLABEL: '1',
        LESSONTITLE: 'Advance Tools in Word Documentation Software Applications',
    },
    {
        CHAPTERID: '18',
        LESSONID: '38',
        LESSONLABEL: '2',
        LESSONTITLE: 'Advance Tools in Calculation Software Applications',
    },
    {
        CHAPTERID: '19',
        LESSONID: '39',
        LESSONLABEL: '1',
        LESSONTITLE: 'Advance Tools in Presentation Software Applications',
    },
    {
        CHAPTERID: '19',
        LESSONID: '40',
        LESSONLABEL: '2',
        LESSONTITLE: 'Advance Tools in Publishing Software Applications',
    },
    {
        CHAPTERID: '20',
        LESSONID: '41',
        LESSONLABEL: '1',
        LESSONTITLE: 'Using Advanced Tools on Advanced Creative Apps',
    },
    {
        CHAPTERID: '20',
        LESSONID: '42',
        LESSONLABEL: '2',
        LESSONTITLE: 'Meeting Tools in Basic Video Editing Software Applications',
    },
    {
        CHAPTERID: '21',
        LESSONID: '43',
        LESSONLABEL: '1',
        LESSONTITLE: 'Applying Documentation to Different Subject Matters',
    },
    { CHAPTERID: '21', LESSONID: '44', LESSONLABEL: '2', LESSONTITLE: 'Applying Documentation to Processes' },
    { CHAPTERID: '22', LESSONID: '45', LESSONLABEL: '1', LESSONTITLE: 'Applying Presentation Techniques' },
    { CHAPTERID: '22', LESSONID: '46', LESSONLABEL: '2', LESSONTITLE: 'Demonstration of Presentation Documents' },
    { CHAPTERID: '23', LESSONID: '47', LESSONLABEL: '1', LESSONTITLE: 'Applying Basic Mathematical Formulas' },
    { CHAPTERID: '23', LESSONID: '48', LESSONLABEL: '2', LESSONTITLE: 'Applying Visuals to Mathematical Data' },
    { CHAPTERID: '24', LESSONID: '49', LESSONLABEL: '1', LESSONTITLE: 'Introduction to Multimedia' },
    { CHAPTERID: '24', LESSONID: '50', LESSONLABEL: '2', LESSONTITLE: 'Combination of Media Types' },
    { CHAPTERID: '25', LESSONID: '51', LESSONLABEL: '1', LESSONTITLE: 'Advanced Picture Editing' },
    { CHAPTERID: '25', LESSONID: '52', LESSONLABEL: '2', LESSONTITLE: 'Advanced Picture Trimming' },
    {
        CHAPTERID: '26',
        LESSONID: '53',
        LESSONLABEL: '1',
        LESSONTITLE: 'Introduction to Smartphone App Picture Editors',
    },
    { CHAPTERID: '26', LESSONID: '54', LESSONLABEL: '2', LESSONTITLE: 'Combining Pictures for Multiple Editing' },
    { CHAPTERID: '27', LESSONID: '55', LESSONLABEL: '1', LESSONTITLE: 'Introduction to Sound Editing' },
    { CHAPTERID: '27', LESSONID: '56', LESSONLABEL: '2', LESSONTITLE: 'New Sound Creation' },
    {
        CHAPTERID: '28',
        LESSONID: '57',
        LESSONLABEL: '1',
        LESSONTITLE: 'Introduction to Flowcharting or Process Documentation',
    },
    { CHAPTERID: '28', LESSONID: '58', LESSONLABEL: '2', LESSONTITLE: 'Introduction to Programming' },
    { CHAPTERID: '29', LESSONID: '59', LESSONLABEL: '1', LESSONTITLE: 'Basic of Photoshop' },
    { CHAPTERID: '29', LESSONID: '60', LESSONLABEL: '2', LESSONTITLE: 'Tools Outside Tools' },
    { CHAPTERID: '30', LESSONID: '61', LESSONLABEL: '1', LESSONTITLE: 'Advance Photoshop tools' },
    { CHAPTERID: '30', LESSONID: '62', LESSONLABEL: '2', LESSONTITLE: 'Image Enhancement' },
    { CHAPTERID: '31', LESSONID: '63', LESSONLABEL: '1', LESSONTITLE: 'Starting With Windows Live Movie Maker' },
    { CHAPTERID: '31', LESSONID: '64', LESSONLABEL: '2', LESSONTITLE: 'Managing Each Video Component' },
    { CHAPTERID: '32', LESSONID: '65', LESSONLABEL: '1', LESSONTITLE: 'Introduction to Cyberlink Power Director' },
    {
        CHAPTERID: '32',
        LESSONID: '66',
        LESSONLABEL: '2',
        LESSONTITLE: 'Exporting Projects With Complete Video Components',
    },
    { CHAPTERID: '33', LESSONID: '67', LESSONLABEL: '1', LESSONTITLE: 'Creating your First Design' },
    { CHAPTERID: '33', LESSONID: '68', LESSONLABEL: '2', LESSONTITLE: 'Lines and Shapes' },
    { CHAPTERID: '34', LESSONID: '69', LESSONLABEL: '1', LESSONTITLE: 'Use of Typography' },
    { CHAPTERID: '34', LESSONID: '70', LESSONLABEL: '2', LESSONTITLE: 'Balancing Colors' },
    { CHAPTERID: '35', LESSONID: '71', LESSONLABEL: '1', LESSONTITLE: 'Balance and Contrast' },
    { CHAPTERID: '35', LESSONID: '72', LESSONLABEL: '2', LESSONTITLE: 'Scale and Alignment' },
    { CHAPTERID: '36', LESSONID: '73', LESSONLABEL: '1', LESSONTITLE: 'Digital Image Enhancement' },
    { CHAPTERID: '37', LESSONID: '74', LESSONLABEL: '1', LESSONTITLE: 'Visio for different fields of expertise' },
    { CHAPTERID: '37', LESSONID: '75', LESSONLABEL: '2', LESSONTITLE: 'Visio as s flowcharting tool' },
    { CHAPTERID: '38', LESSONID: '76', LESSONLABEL: '1', LESSONTITLE: 'Understanding C++' },
    { CHAPTERID: '38', LESSONID: '77', LESSONLABEL: '2', LESSONTITLE: 'Code equivalent of blocks and shapes' },
    { CHAPTERID: '39', LESSONID: '78', LESSONLABEL: '1', LESSONTITLE: 'Coding from scratch' },
    { CHAPTERID: '39', LESSONID: '79', LESSONLABEL: '2', LESSONTITLE: 'Equations in programming' },
    { CHAPTERID: '40', LESSONID: '80', LESSONLABEL: '1', LESSONTITLE: 'Conditional statement' },
    { CHAPTERID: '40', LESSONID: '81', LESSONLABEL: '2', LESSONTITLE: 'Applications of C++' },
    { CHAPTERID: '41', LESSONID: '82', LESSONLABEL: '1', LESSONTITLE: 'Creating Online Survey and documents' },
    {
        CHAPTERID: '41',
        LESSONID: '83',
        LESSONLABEL: '2',
        LESSONTITLE: 'Standards to Follow for an Effective Survey Form',
    },
    { CHAPTERID: '41', LESSONID: '84', LESSONLABEL: '3', LESSONTITLE: 'Google Sheets utilization' },
    { CHAPTERID: '42', LESSONID: '85', LESSONLABEL: '1', LESSONTITLE: 'Revising the basic tools of Microsoft Excel' },
    { CHAPTERID: '42', LESSONID: '86', LESSONLABEL: '2', LESSONTITLE: 'Basic and advance commands of Microsoft Excel' },
    {
        CHAPTERID: '42',
        LESSONID: '87',
        LESSONLABEL: '3',
        LESSONTITLE: 'Performing Basic Calculations using Microsoft Excel',
    },
    {
        CHAPTERID: '42',
        LESSONID: '88',
        LESSONLABEL: '4',
        LESSONTITLE: 'Appliying Microsoft Excel on Research Data Analysis',
    },
    {
        CHAPTERID: '41',
        LESSONID: '89',
        LESSONLABEL: '1',
        LESSONTITLE: 'Basic Research Poster Background Using Adobe Photoshop',
    },
    {
        CHAPTERID: '41',
        LESSONID: '90',
        LESSONLABEL: '2',
        LESSONTITLE: 'Basic Research Poster Background Using Adobe Photoshop II',
    },
    {
        CHAPTERID: '41',
        LESSONID: '91',
        LESSONLABEL: '3',
        LESSONTITLE: 'Color Schemes: Making Your Poster EYe - Appealing',
    },
    {
        CHAPTERID: '41',
        LESSONID: '92',
        LESSONLABEL: '4',
        LESSONTITLE: 'Complementing Text with the Research Background',
    },
    { CHAPTERID: '42', LESSONID: '93', LESSONLABEL: '1', LESSONTITLE: 'Making use of website creators' },
    { CHAPTERID: '42', LESSONID: '94', LESSONLABEL: '2', LESSONTITLE: 'More on Wix Editor Capabilities' },
    { CHAPTERID: '42', LESSONID: '95', LESSONLABEL: '3', LESSONTITLE: 'Promoting Business through Web Development' },
];
