export const SCHOOLS = {
    passcodeList: [
        { GRADE: '0', PASSCODE: 'NCAP-cu2X', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-DyxS', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-wLc4', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-7JTU', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-fQHw', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-IRPc', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-2cP1', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-t0Wr', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-qSWE', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-BKpj', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-DnxY', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-5si9', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-Y1RN', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-Df9r', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-848o', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-9y41', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-puwN', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-BiA7', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-G9K3', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '0', PASSCODE: 'NCAP-mEMY', NAME: 'UN-AVAILABLE LINK', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-KRBs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-NZ9A', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-69mp', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-x6ej', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-O5O2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-ZjQg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-aoqB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-Mdot', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-CTom', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-3C18', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-gTbI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-tKO0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-gOES', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-asD1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-MHmr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-L6Gw', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-88ut', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-L53p', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-t1GJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-ZmXl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-uhhp', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-dkic', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-hte4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-6Bq0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-UB02', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-5nLQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-sU3l', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-TOQQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-4gC9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-9STB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-Rtmc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-32oc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-HYJz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-56oX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-tlZ1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-4RwB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-XX9I', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-SCBA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-9rMP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-iOzC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-WxFW', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-2brL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-yTLz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-LAgR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-poQj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-QRex', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-zqF9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-ejdI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-sp0k', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'NCAP-AjOm', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Dhon', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-gTHi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-299Q', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-BgKu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-OC4i', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-bU8Y', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Dp9X', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-pqGg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-mx2J', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-0IWw', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-2cKs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-dKDe', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-1KUj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-aUAs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-YEi7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-IUB9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-suP2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-TwRa', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-K3aM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-XTXU', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-sHas', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-oZNx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-tt28', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-sHsf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-M5IL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Pql1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-f6hl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-4Zu8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-7mXx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-MJmq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-lxlT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Ys7G', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-rR11', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-gPBa', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-ZD6m', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Ww2e', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-kzRr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-KS6Q', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-NzUy', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-U3TA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Mi3I', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-Kd3l', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-8yU6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-51QZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-4Jm7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-ku1r', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-wmyJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-9jPO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-tB4G', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'NCAP-wuSN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-7nns', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-WjYg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-m1b9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-OtAC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-IdqX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-1dnZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-bJ8B', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-kXJN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-Cihu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-WCtj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-5Bry', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-RjyC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-8ibC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-zr2g', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-5uUt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-2xQu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-PQ8y', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-Ncp0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-ydHe', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-Slpb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-GGlM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-0OHd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-XCbf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-wZGj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-eXWI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-UShn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-rlhq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-LsbR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-kUtF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-Ychq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-H73w', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-bTI3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-n6cR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-6ws4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-A9cM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-4afb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-6Mlf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-06Q7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-2w3r', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-ozoP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-LrRf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-5FFg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-8kk8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-HEbx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-Ule9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-oSqi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-EsTk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-7LhE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-tUSc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'NCAP-MfAJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-ZnUb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-QNBP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-whxu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-XuSI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-n5Ca', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-ztY2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-EwsD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-2spd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-xof8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-Z7sb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-fUCO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-Pe34', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-bajJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-1O9X', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-9i6m', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-iLWY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-AL7t', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-7YYZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-R3Wp', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-iaXJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-BnJf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-qCFA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-WiQq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-MjQY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-dIpK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-hPbM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-TueB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-jrCM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-ejnc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-B0wa', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-zkdD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-jPsM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-23nP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-4521', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-nWeN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-w2HQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-m11k', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-YnGO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-o1rF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-Bttb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-ObX3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-9J4B', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-meRb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-JyOq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-oRX3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-JSzx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-zEDM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-aPt0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-cTQG', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-u0Lp', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-uT2A', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-A1xy', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-HWy7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-4F97', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-a0pM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-H6lR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-WQwM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-CGsT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-zN4K', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-JkdM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-zFIi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-nlNL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-noJt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-jeL4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-qaI1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-p1yr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-LKol', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-TLmI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-4Mny', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-wjxP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-UezC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-xBZj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-Mwgf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-ncCB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-zpGm', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-9etf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-bPOq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-Y5iR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-wzt8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-3lH0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-FZSK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-IIB4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-PYFY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-AA0Y', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-BRyk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-FKse', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-1s8w', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-YrrL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-sWMD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-bb9J', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-h7Jh', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-6jGY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-YbSC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Gi1J', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-MRWC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-zg6A', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-nETn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Zi7d', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-9hcJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-sdBD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-5Izz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-HkgB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-yYfF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-N4co', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-wXUT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-ruB4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-QQo2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-JHSF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-s9XL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-XuiT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-8xHZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-DWKk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-xuki', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-F0hu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Hm4b', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Zn5W', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-ZuP3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-NEAn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-I0xD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-FSLY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-3sUT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-jexQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-lKSZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-e0Jm', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-8aBE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Yx2u', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-XgqL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-FpNM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-KrCF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-ELHE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-8aXd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Rwmq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Z4EZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-TyhC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-wbAA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Lcg7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-8H5u', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-BmpP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-R2Uh', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-uedZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-khKI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-e5TB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-R68y', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-hqwI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-TUcS', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-xAPB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-dAf2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-ZuXi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-GW7f', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-nmlG', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-do0C', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-mNfT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-bOeB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-S12i', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-hS2p', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-1E6w', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-t6zC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-Isjr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-FHCb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-J6Ge', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-QnBi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-13G9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-5TqA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-e8mu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-uczr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-HEMQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-1Fmk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-YpLD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-HrCI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-EKOt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-ZQy4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-OoYx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-U4is', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-6Nb3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-mqhx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-kqJz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-Gfoy', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-yx6D', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-7B4C', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-KQnA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-yxNq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-Hrce', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-lBUi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-zXJf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-x45h', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-4hnK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-dG2Y', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-QhD9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-byFG', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-9ZXx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-0Jdr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-D6jr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-WbdQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-gfT8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-PRCs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-nbfz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-3LNs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-i9Dh', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-iBHd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-mCN9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-WcPr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-fbBK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-qsEz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-EMNX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-y5UO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-Mlzr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-etcF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-bRQ3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-Z3C9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-iQXn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-f5pu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-pwm0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-7s38', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-gN2u', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-H9gN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-UqD1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-u0Jz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-ecB7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-i8Nr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-Ch0T', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-4rt6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-IMO3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-Z3Qk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-jFHT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-mD3r', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-Emqs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-AbZC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-6CgX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-SFUS', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-bISI', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-jNY8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-TKFo', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-baeC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-pyT8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-TZDt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-5zmw', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-AbAA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-oGEw', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-4BAn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-zrRl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-oelr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-9fYt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-jU5W', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-1pTg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-1AjN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-biC9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-s8ab', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-SBNK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-ilm7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-26e0', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-qgR7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-qDBY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-tcEy', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-JuKQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-zAWX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-2BYj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-Y7RB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-dltG', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-FjNB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-oTYQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-N9jG', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-ZkWt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-UDXa', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-R5zc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-yqln', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-h0kx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-aKQ9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-zBo6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-1npf', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-jwRR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-g8mu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-15gP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-EJrb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-n0B3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-aBs9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-6gqY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-FGUq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-PF0b', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-A67w', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-LPo2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-6iY1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-hrTp', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-djqE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-cnqi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-Rf1r', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-CGEO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-n22j', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-hOIu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-Buh9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-zL5a', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-0Jtz', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-k6TN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-QIP4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-5gxc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-yyRi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-rf8G', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-5Fay', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-5M3a', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-EgwO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-1JsY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-apUr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-R99C', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-2Hpu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-Eehu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-kNlN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-yhFj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-14fN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-DjuR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-MuTn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-ZF0r', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-SHMl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-9nhD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-pmSM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-79QM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-TIeJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-4hcG', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-zrqB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-kI10', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-WRGe', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-pHfy', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-u9B7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-sESA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-rbtX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-5dRu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-HBoQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-qUbN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-eTBT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-dI52', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-t43s', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-1Af6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-r0ZQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-1s9l', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-FjWx', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-mAQU', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-GwXq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-znaP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-2ZQy', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-8gz6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-CZ0z', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-GmEp', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-j645', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-w1gM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-4xD3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-kals', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-KORw', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-G2Lc', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-D0ab', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-5I5z', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-ESkN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-0Lp5', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-JXjM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-dDcE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-EFdJ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-w6UD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-U4ZR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-FPfO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-naCW', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-Tu3a', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-uDys', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-mmZ1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'demo1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'demo2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'demo3', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'demo4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'demo5', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'demo6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'demo7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'demo8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'demo9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'demo10', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'demo12', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'ncap-g1', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '2', PASSCODE: 'ncap-g2', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '3', PASSCODE: 'ncap-g3', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '4', PASSCODE: 'ncap-g4', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '5', PASSCODE: 'ncap-g5', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '6', PASSCODE: 'ncap-g6', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '7', PASSCODE: 'ncap-g7', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '8', PASSCODE: 'ncap-g8', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '9', PASSCODE: 'ncap-g9', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '10', PASSCODE: 'ncap-g10', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '12', PASSCODE: 'ncap-g12', NAME: '', SCHOOL: 'NCAP', account_type: 'teacher' },
        { GRADE: '1', PASSCODE: 'NCAP-ZwIC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-TLUn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-M6yX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-iG3I', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-4pkb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-iC4c', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-sQSj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-ZzYS', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-j0qk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-bNLX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-IHHR', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-RIgN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-09Jd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-UcFZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-Rre4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-41tt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-TaAd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-udKX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-dnhm', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'NCAP-cgcg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-1oGr', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-bNU1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-dH5i', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-ajfl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-qm0g', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-kz3Y', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-wPYF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-XRSB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-nbBu', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-PyPS', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-EoCA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-4Vqi', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-Bc83', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-5QKC', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-vr4L', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-yOeL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-z459', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-hvW2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-Xhb6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'NCAP-w2eb', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Ze0m', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-vbMF', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-F6sa', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-3wF6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-curD', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Ic7D', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-t3MQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-9FLj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-ulV9', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-wuJT', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-HIK2', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-HbSl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-bgVl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-XBM7', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-gw32', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-O5GA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-uIqS', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-bFJs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-kY59', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-SF6R', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-klyd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-tjLt', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-YYcA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-1REM', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-CJH8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-vAJW', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-WnB8', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-FNob', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-zlRn', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-HmRL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-9kax', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-QKXO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-aeCj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-Bv0O', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'NCAP-WPtO', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-rBPm', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-J9pa', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-oB6E', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-FqRZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-kovk', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-gXjh', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-IrAE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-zTlH', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-2SMd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-NVDd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-aWNL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-7HfZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-pQ9p', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-fHLK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-J5qA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-2DQN', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-piPP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-8UyE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-O8OK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-KXkB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'NCAP-LlYj', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-T5mv', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-HBUS', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-pClW', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-FAgY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-1PaV', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-b5bg', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-3AAw', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-VpzQ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-oq3t', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'NCAP-6eI6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-AgZL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-X1FK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-0LJ6', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-KDIY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-eF1G', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-Bh7A', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-NUrZ', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-GwbX', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-GeV4', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'NCAP-gPJB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-505J', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-f2Fl', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-ifP1', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-nC4c', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-jZ7C', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-JqDE', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-twzq', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-sN2O', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-5zLL', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'NCAP-mxeU', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-5MYV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-SWan', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-bbHC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-OrPx', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-rSh1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-GOCP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-jNLp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-il6e', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-31FG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-lAMO', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-hEN4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-WhRM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-Vyrt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-HXpq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-KJwE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-LuF0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'JN', PASSCODE: 'DYCI-fmJW', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-lJLq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-GSUB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-AH2B', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-69Tu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-3NXg', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-LY5c', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-OHDw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-9XZt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-Y4kM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-mBny', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-DoqC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-QuHM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-bU6S', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-OmKM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-Ntc8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-PhBn', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-sJnB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-YHI2', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-kjdh', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-s2Ld', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-91Vo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-bHev', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-uX82', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-jojf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-mCUH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-HGxU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-MDsp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-JndU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-h88Y', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-MWMk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-o1RA', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-IPT5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-A74p', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-iEW3', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-DAcX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-ZzmH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-3nVk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-pKTQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: 'SN', PASSCODE: 'DYCI-0ucQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-sqj1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-kIM5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-x0w5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-lia9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-XuG6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-jp9d', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-lz92', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-dr0s', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-ezU9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-MWZs', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-z5Zr', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-pezs', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-2ghV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-kPwC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-rFQv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-JUbF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-9Dbv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-NaxB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-VXHo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-PSyG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-9RA6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-JeHP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-DRSG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Fojf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-dTrl', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-DEeb', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-tfAM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-HHwZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-RJv1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Te5e', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-5Lr6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Ir5r', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-ymK5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-kMD5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-xiNo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-e4H5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-e2y6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-2rL9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-YMp1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-WhTB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-jTYf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-70yE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-G0Dv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-QRAB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-EvbP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-29yG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-bLhY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-hLiP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-PLEK', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-gm8n', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Et0C', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-oZsG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Moxk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-FDJk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-eRDv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Tah5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-RyfE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Nzec', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-xyLI', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-JwcB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-cbM3', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-Y7JM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-CepP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-iMUo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-M4uz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-4n3W', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-JUxR', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-2Abs', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-yCS1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-NogU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-oTBs', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-JO4k', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-dh6O', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-CmHz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-hztD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-K1UC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-D7bf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-4dah', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-YaLk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-lKM5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-aNyU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-DMpL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-5dTY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-5dKw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-99pL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-xYqA', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-GY0l', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-bgNX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-nxm6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-D990', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-SPka', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-JCm4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-QZkm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-eAHC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-hyOo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-WDuY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-Q6uH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-0NsA', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-9z3i', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-xP1E', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-w5VB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-6k2m', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-qkZP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-uUeO', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-eMKM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-RfEC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-qknp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-GiOR', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-6J2a', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-sscq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-EHaQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-xdPo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-UpDk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-FowR', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-1SVV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-7MgH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-VHSk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-cDfw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-ueIJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-ExON', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-U8Ua', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-foMS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-XkjZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-XGt1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-lS4q', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-KpMR', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-JR0l', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-gnMD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-EHB8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-1Fmr', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-5AN1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-eGYo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-vQhv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-3iDp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-wDQN', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-xkvu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-YfVQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-EuAg', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-qNuJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-bUwD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-2M0P', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-FbKf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-EVMx', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-yY3U', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-Odd9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-vIjA', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-QBEp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-y3Ob', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-RNVl', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-ufo6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'DYCI-U0Lt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-hMsS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-TZ57', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-1xRu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-nz9E', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-WeqL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-Hvgk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-DRks', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-yho5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-60cD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-TwVZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-8Qkd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-6k84', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-gkj0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-JAlw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-pHOC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-yMJT', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-IWxw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-W5vk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-IRN1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-KCoc', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-TpMO', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-7yw6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-OqYL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-hTkv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-GQVj', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-wLhk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-z9r1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-xtlm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-dwsI', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-A5WU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-j1iq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-hjCz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-rbv4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-rEpH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-HmY7', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-G4If', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-Evsv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-lbvL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-3AA5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-n1CP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-62KV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-8r5Y', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-GkBY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-gYOp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-sClw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-1RNP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-qjAr', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-EueQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-jIBO', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-twhX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-zTpA', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-aXof', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-wUNl', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-PPYp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-o4pY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-qNUW', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-GuyY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-X955', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-fO6h', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-mNzT', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-4f5t', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-h3AU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-uWjz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-HIqm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-9S3p', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-jQHw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-YRGB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-S0Ca', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-FWdL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-SkzN', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-udnB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-ZGyW', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-iUiT', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-UJuo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-XIUP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-w9B3', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-TJtu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-pfTY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-dp5F', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-mGWS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-dtc8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-RtG5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-mzxJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-Pjau', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-cusQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'DYCI-WDrC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-AKzo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-OoBQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-wCwC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-gDmj', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-DxIz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-rKJ4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-OKn2', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-YG5Z', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Ca7H', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-3br7', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-ygXM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-wXOY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-HINi', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-51V0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-A5ZD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-BI2p', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-GXAG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Geql', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-GKBK', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-x6H1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-6OvQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-3WdL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-EHG7', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Bo6q', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-orGZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-XHRn', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-clcv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-0KCE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-yiGw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Xmwt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-3zB9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Xskc', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-OUgG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-0rdz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-7HxR', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-4sU4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-ECJd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-hJSC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-4Wfi', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-GSPt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-B9gf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-o6NG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-FRzP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-yezL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-eG4V', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-iH5E', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-CdMe', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-M4so', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-2DQ4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-LyQI', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-yCXP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-TDNZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-gUD9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Y4L4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-EcdK', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-tt4K', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-iLXu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-O49q', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-0vgf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-tpu8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Qw71', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-0tP7', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-2Wzt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-VGYE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-INqI', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-pdKm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-UXIm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-XpKZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-re47', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-wCTz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-5Z22', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-EQUu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-XbXf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-8mSw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-lVhd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-knjN', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-LaC0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-LbSF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-31NQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-lShD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-CYP6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-RpaF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-LHVb', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-f0OS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-zoqo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Oei5', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-JRlG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-YCLT', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-xdaM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'DYCI-Xn5g', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-96oE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-G0kD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-n5b6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-m3zi', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-0Ux9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-DUqJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-5CnB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-aC4M', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-tKoR', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-zdzS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-ridd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-lPq4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-o1WD', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-z5cp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-QfJE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Ninl', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-APNb', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-OhsW', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-efSe', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-0Nip', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-VtXi', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-p0Wx', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-r9BU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-UuFI', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-52cV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-COpG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-I9iU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-n8yC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-7dtd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-LE5J', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-2ZuP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-gYCu', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-aJj8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-uMnG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-DRZq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-SBKt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-K8X0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Bl60', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-6Sdm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-eaju', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-7y4d', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-YzSv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-zma4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-oP11', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-PAEh', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-BLsz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-MtCb', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-tBln', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-rpse', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-HqVV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-qlBY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-LCLr', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-blWd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-VCtw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-FEQo', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-x0By', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-BwM9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-gXti', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-pt1Z', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-JUnK', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-hUAH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-oHRp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-66YC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-aqh0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-kNXY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-zLKE', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-2xxm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-LTmv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-bFHd', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-04J3', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-fhDk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-8qI9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-MAz6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-6PUV', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-J5O0', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Upem', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-TmlU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-jEAp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Dd3S', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-IjFL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-pyZK', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-epyM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-vvT4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-pSjz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-DbRW', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-FxaI', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-AJ2d', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-VCNj', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-0DLf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-PUIn', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-yZjx', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-vFAv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-4DOr', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-StG1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-XRJU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-wV0s', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-htDm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-HDjt', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Tniq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-OmW4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-JHiS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-xgRH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-6TH8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Kx1c', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-i0QB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-CXo1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-dfXp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Lanv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-4OLp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-xi5A', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-c6px', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-5lif', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-vFHY', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-WU2v', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-dryS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-Il6g', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-pWD6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-lLpA', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-5PjF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-s051', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-623F', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-SPZj', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'DYCI-VZMF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-tm11', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-AIHi', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-im3k', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-aGdG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-vxDm', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-v6WQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-r0Na', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-7dkM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-9jQS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-XsFj', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-sWTT', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-8TpL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-vSyi', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-WjUw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-PlJJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-4rlF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Crk1', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-0KsC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-rd4Z', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-nDSX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-LBop', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-oCza', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-PdDw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-i5oB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-vNRQ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-tyVl', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-jw02', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-o5JM', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Tw83', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-C6a9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-1JlW', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-G6dv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-y3nZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-wBnX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-qDF2', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-M6vU', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-U1cw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-7v8z', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-wRZk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-M9em', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-SEEv', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-RtZS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-JkN9', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-0ix4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-XRBP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-dryC', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-4xmf', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-lDLq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-icjH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-2ACk', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-bKnX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-i99O', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-deM4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-7PIl', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-QB1d', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-G4qF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-ZgbX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-9qCX', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-OaxT', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Ebe4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-yYBH', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-0QtS', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-T710', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Iieh', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-eh7C', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-ilOx', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-czpg', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-czhz', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-h7pr', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-2jPJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-xIDB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-yLOq', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-6Qzg', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-dbWN', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-KOpB', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-kM0e', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-aCTL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-3wo4', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-RyPZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-hN8Q', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-k9kL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-AAVF', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-lbho', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-4Oik', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Sjlw', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-1DwJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-m4Pp', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-M6p6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-PYak', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-moda', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'DYCI-g1', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '2', PASSCODE: 'DYCI-g2', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '3', PASSCODE: 'DYCI-g3', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '4', PASSCODE: 'DYCI-g4', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '5', PASSCODE: 'DYCI-g5', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '6', PASSCODE: 'DYCI-g6', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '1', PASSCODE: 'dyci-demo1', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '2', PASSCODE: 'dyci-demo2', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '3', PASSCODE: 'dyci-demo3', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '4', PASSCODE: 'dyci-demo4', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '5', PASSCODE: 'dyci-demo5', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '6', PASSCODE: 'dyci-demo6', NAME: '', SCHOOL: 'DYCI', account_type: 'teacher' },
        { GRADE: '1', PASSCODE: 'slma1', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '2', PASSCODE: 'slma2', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '3', PASSCODE: 'slma3', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '4', PASSCODE: 'slma4', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '5', PASSCODE: 'slma5', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'slma6', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '7', PASSCODE: 'slma7', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '8', PASSCODE: 'slma8', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '9', PASSCODE: 'slma9', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'slma10', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '11', PASSCODE: 'slma11', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '12', PASSCODE: 'slma12', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'slma6', NAME: '', SCHOOL: 'LMA', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'tlma1', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '2', PASSCODE: 'tlma2', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '3', PASSCODE: 'tlma3', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '4', PASSCODE: 'tlma4', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '5', PASSCODE: 'tlma5', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '6', PASSCODE: 'tlma6', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '7', PASSCODE: 'tlma7', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '8', PASSCODE: 'tlma8', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '9', PASSCODE: 'tlma9', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '10', PASSCODE: 'tlma10', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '11', PASSCODE: 'tlma11', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '12', PASSCODE: 'tlma12', NAME: '', SCHOOL: 'LMA', account_type: 'teacher' },
        { GRADE: '10', PASSCODE: 'NCAP-9KXs', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-0wLY', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-unEA', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-uEPP', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-L8co', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-ZuYB', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-Sdcd', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-lwqK', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '10', PASSCODE: 'NCAP-MH4k', NAME: '', SCHOOL: 'NCAP', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Sjs8', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-3cuL', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-RpmZ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Td8W', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-HvnJ', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-FrgP', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-Lu2B', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-U269', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-A6mG', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '6', PASSCODE: 'DYCI-N2e6', NAME: '', SCHOOL: 'DYCI', account_type: 'student' },
        { GRADE: '1', PASSCODE: 'tcdr1', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '2', PASSCODE: 'tcdr2', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '3', PASSCODE: 'tcdr3', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '4', PASSCODE: 'tcdr4', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '5', PASSCODE: 'tcdr5', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '6', PASSCODE: 'tcdr6', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '7', PASSCODE: 'tcdr7', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '8', PASSCODE: 'tcdr8', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '9', PASSCODE: 'tcdr9', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '10', PASSCODE: 'tcdr10', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '11', PASSCODE: 'tcdr11', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },
        { GRADE: '12', PASSCODE: 'tcdr12', NAME: '', SCHOOL: 'CDR', account_type: 'teacher' },

    ],
    schoolList: [
        { ID: '1', SCHOOLNAME: 'NCAP', showDigitalLinksToStudentsFlag: 'n' },
        { ID: '2', SCHOOLNAME: 'DYCI', showDigitalLinksToStudentsFlag: 'y' },
        { ID: '3', SCHOOLNAME: 'LMA', showDigitalLinksToStudentsFlag: 'y' },
        { ID: '4', SCHOOLNAME: 'CDR', showDigitalLinksToStudentsFlag: 'y' },
    ],
    gradeList: [
        { SCHOOLID: '1', GRADEID: '1', GRADELABEL: '1' },
        { SCHOOLID: '1', GRADEID: '2', GRADELABEL: '2' },
        { SCHOOLID: '1', GRADEID: '3', GRADELABEL: '3' },
        { SCHOOLID: '1', GRADEID: '4', GRADELABEL: '4' },
        { SCHOOLID: '1', GRADEID: '5', GRADELABEL: '5' },
        { SCHOOLID: '1', GRADEID: '6', GRADELABEL: '6' },
        { SCHOOLID: '1', GRADEID: '7', GRADELABEL: '7' },
        { SCHOOLID: '1', GRADEID: '8', GRADELABEL: '8' },
        { SCHOOLID: '1', GRADEID: '9', GRADELABEL: '9' },
        { SCHOOLID: '1', GRADEID: '10', GRADELABEL: '10' },
        { SCHOOLID: '1', GRADEID: '12', GRADELABEL: '12' },
        { SCHOOLID: '2', GRADEID: '1', GRADELABEL: '1' },
        { SCHOOLID: '2', GRADEID: '2', GRADELABEL: '2' },
        { SCHOOLID: '2', GRADEID: '3', GRADELABEL: '3' },
        { SCHOOLID: '2', GRADEID: '4', GRADELABEL: '4' },
        { SCHOOLID: '2', GRADEID: '5', GRADELABEL: '5' },
        { SCHOOLID: '2', GRADEID: '6', GRADELABEL: '6' },
        { SCHOOLID: '3', GRADEID: '1', GRADELABEL: '1' },
        { SCHOOLID: '3', GRADEID: '2', GRADELABEL: '2' },
        { SCHOOLID: '3', GRADEID: '3', GRADELABEL: '3' },
        { SCHOOLID: '3', GRADEID: '4', GRADELABEL: '4' },
        { SCHOOLID: '3', GRADEID: '5', GRADELABEL: '5' },
        { SCHOOLID: '3', GRADEID: '6', GRADELABEL: '6' },
        { SCHOOLID: '3', GRADEID: '7', GRADELABEL: '7' },
        { SCHOOLID: '3', GRADEID: '8', GRADELABEL: '8' },
        { SCHOOLID: '3', GRADEID: '9', GRADELABEL: '9' },
        { SCHOOLID: '3', GRADEID: '10', GRADELABEL: '10' },
        { SCHOOLID: '3', GRADEID: '12', GRADELABEL: '12' },
        { SCHOOLID: '4', GRADEID: '1', GRADELABEL: '1' },
        { SCHOOLID: '4', GRADEID: '2', GRADELABEL: '2' },
        { SCHOOLID: '4', GRADEID: '3', GRADELABEL: '3' },
        { SCHOOLID: '4', GRADEID: '4', GRADELABEL: '4' },
        { SCHOOLID: '4', GRADEID: '5', GRADELABEL: '5' },
        { SCHOOLID: '4', GRADEID: '6', GRADELABEL: '6' },
        { SCHOOLID: '4', GRADEID: '7', GRADELABEL: '7' },
        { SCHOOLID: '4', GRADEID: '8', GRADELABEL: '8' },
        { SCHOOLID: '4', GRADEID: '9', GRADELABEL: '9' },
        { SCHOOLID: '4', GRADEID: '10', GRADELABEL: '10' },
        { SCHOOLID: '4', GRADEID: '12', GRADELABEL: '12' },
    ],
};
