import React from "react";
import "../asset/css/template/softwarelink.css";

import Button from "../component/Button";
import Text from "../component/Text";

function SoftwareLink({ data }) {
  return (
    <div className="software-link-wrapper">
      <Button
        text="DOWNLOAD"
        design="download-button-2"
        link={data.SOFTWARELINK}
        linkTo="_blank"
      />
      <Text text={data.SOFTWARENAME} design="software-links-title-text" />
    </div>
  );
}

export default SoftwareLink;
