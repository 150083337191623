import React, { useState } from 'react';
import '../asset/css/template/moduleheader.css';

import DCLALogo from '../asset/images/dcla-logo.png';
import LogOut from '../component/LogOutButton';

function ModuleHeader() {
    const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin'));
    return (
        <div className="module-header-conatiner">
            <div className="module-header-wrapper">
                <img src={DCLALogo} alt="DCLA" title="DCLA Logo" className="module-header-logo"></img>
                <div className="module-title-wrapper">
                    <h1 className="module-header-title">DCLA PHILIPPINES</h1>
                    <h2 className="module-header-sub-title">YOUR PERFECT LEARNING MIX</h2>
                </div>
            </div>

            {!isLogin ? null : (
                <div className="logout-button-container">
                    <LogOut
                        setIsLogin={() => {
                            setIsLogin(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default ModuleHeader;
